export const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const sort = (items: string[]) => {
  return [...items].sort((a, b) =>
    a.toLowerCase().localeCompare(b.toLowerCase())
  );
};

export const formatDollarAmount = (amountStr: string): string | null => {
  const amount = parseFloat(amountStr);
  if (isNaN(amount)) {
    console.log("Invalid input format. Please provide a valid numeric value.");
    return null;
  }

  const formattedAmount = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  }).format(amount);

  return formattedAmount;
};

export const getLastName = (name: string) => {
  const nameArray = name.split(" ");
  return nameArray[nameArray.length - 1];
};

import InPlaceError from "components/in-place-error/InPlaceError";
import React, { useState, MouseEventHandler, useEffect } from "react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import "./ssn.scss";

export const Label = ({ label }: { label: string }) => {
  return (
    <div className="ssn-label__wrapper">
      <label htmlFor="label" className="ssn-label">
        {label}
      </label>
    </div>
  );
};

export const Icon = ({
  icon,
  isIconPointer,
  onIconClick,
}: {
  icon: IconDefinition;
  isIconPointer?: boolean;
  onIconClick?: MouseEventHandler<HTMLSpanElement>;
}) => {
  return (
    <span
      className={`ssn--icon ${isIconPointer ? "ssn--icon--pointer" : ""}`}
      onClick={onIconClick}
    >
      <FontAwesomeIcon icon={icon} />
    </span>
  );
};

export const eyeIcon = (isSSNVisible: boolean) =>
  isSSNVisible ? regular("eye") : regular("eye-slash");

export interface SSNProps {
  label?: string;
  placeholder?: string;
  errorMessage?: string;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  initialValue?: string;
  onChange?: (e: string) => void;
  onBlur?(e: React.FormEvent<HTMLInputElement>): void;
}

const regex = /^[a-zA-Z!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/;
const ssnCharacterLimitWithDashes = 11;

export const format = (rawSSN: string, maskSSNWithX: boolean) => {
  let formattedSSN = rawSSN
    .slice(0, ssnCharacterLimitWithDashes)
    .replace(/-/g, "");

  if (formattedSSN.length > 3 && formattedSSN.length <= 5) {
    formattedSSN = `${formattedSSN.slice(0, 3)}-${formattedSSN.slice(3)}`;
  } else if (formattedSSN.length > 5) {
    formattedSSN = `${formattedSSN.slice(0, 3)}-${formattedSSN.slice(
      3,
      5
    )}-${formattedSSN.slice(5)}`;
  }

  const firstElevenCharacters = formattedSSN.slice(
    0,
    ssnCharacterLimitWithDashes
  );
  return maskSSNWithX
    ? firstElevenCharacters.replace(/\d/g, "x")
    : firstElevenCharacters;
};

const Ssn = ({
  label,
  errorMessage,
  placeholder,
  isReadOnly,
  isDisabled,
  initialValue,
  onChange,
  onBlur,
}: SSNProps) => {
  const [ssn, setSSN] = useState("");
  const [isSSNVisible, setSSNVisible] = useState(false);

  useEffect(() => {
    if (initialValue) {
      setSSN(initialValue);
    }
  }, []);

  const handleKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (regex.test(e.key) && e.key != "Backspace") {
      e.preventDefault();
    }
    if (
      ssn.length < ssnCharacterLimitWithDashes &&
      (e.key as unknown as number) >= 0 &&
      (e.key as unknown as number) <= 9
    ) {
      setSSN(format(ssn + e.key, false));
    } else if (ssn.length > 0 && e.key === "Backspace") {
      setSSN(format(ssn.slice(0, ssn.length - 1), false));
    }
  };

  const handleChange = () => {
    onChange?.(ssn);
  };

  return (
    <div className="ssn-container">
      {label && <Label label={label} />}
      <div className="ssn__wrapper">
        <input
          className={errorMessage ? "ssn ssn--error" : "ssn"}
          type="text"
          id="ssn"
          name="ssn"
          value={isSSNVisible ? ssn : format(ssn, true)}
          placeholder={placeholder}
          autoComplete="off"
          onKeyDown={handleKey}
          onChange={handleChange}
          disabled={isDisabled}
          readOnly={isReadOnly}
          onBlur={onBlur}
          spellCheck={false}
          aria-label={label}
        />
        <Icon
          icon={eyeIcon(isSSNVisible)}
          isIconPointer
          onIconClick={() => setSSNVisible(!isSSNVisible)}
        />
        {errorMessage && <InPlaceError errorPhrase={errorMessage} />}
      </div>
    </div>
  );
};

export default Ssn;

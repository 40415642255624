import propertyDashboardImage from "assets/images/property_dashboard.png";
import style from "./no-loan-info.module.scss";
import { NoLoanInfoText } from "utilities/Constant";

export default function NoLoanInfo() {
    return (
        <div className={style.noLoanInfo}>
            <div className={style.noLoanContainer}>
                <div className={style.noLoanContent}>
                    <div className={style.verbiage}>
                        {NoLoanInfoText}
                    </div>
                    <div className={style.propertyImage}>
                        <img src={propertyDashboardImage} alt="house" />
                    </div>
                </div>
            </div>
        </div>
    )
}
import React from "react";
import "./advertisement.scss";
import useDesktopChecker from "hooks/useDesktopChecker";

export interface AdvertisementProps {
  image?: string;
  logo?: string;
  imageText?: React.ReactNode;
  text?: React.ReactNode;
  subtext?: React.ReactNode;
  footer?: React.ReactNode;
}

export default function Advertisement({
  image,
  text,
  logo,
  imageText,
  subtext,
  footer,
}: AdvertisementProps) {
  const isDesktop = useDesktopChecker();

  return (
    <>
      {isDesktop ? (
        <div className="advertisement">
          <div className="advertisement-image">
            <img className="dashboard-insurance__img" src={image} alt="Logo" />
            <img
              className="advertisement-image-logo dashboard-insurance__logo"
              src={logo}
              alt="logo"
            />
            <div className="advertisement-image-img-text-block">
              <p className="advertisement-image-img-text">{imageText}</p>
            </div>
          </div>
          <div className="advertisement-container">
            <div className="advertisement-container__text">{text}</div>
            <div className="advertisement-container__subtext">{subtext}</div>
          </div>
          <div className="advertisement-footer">{footer}</div>
        </div>
      ) : (
        <div className="advertisement">
          <div className="advertisement-image">
            <img className="dashboard-insurance__img" src={image} alt="Logo" />
            <img
              className="advertisement-image-logo dashboard-insurance__logo"
              src={logo}
              alt="logo"
            />
            <div className="advertisement-image-img-text-block">
              <p className="advertisement-image-img-text">{imageText}</p>
            </div>
          </div>
          <div className="advertisement-text">{text}</div>
          <div className="advertisement-footer">{footer}</div>
          <div className="advertisement-subtext">{subtext}</div>
        </div>
      )}
    </>
  );
}

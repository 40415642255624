export interface RadioOption {
  label: string;
  value: string;
}

interface RadioButtonProps {
  option: RadioOption;
  selectedValue: string;
  handleOptionChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioButton = ({
  option,
  selectedValue,
  handleOptionChange,
}: RadioButtonProps) => (
  <div key={option.value} className="buttonLabelWrapper">
    <input
      type="radio"
      id={option.value}
      name="radioGroup"
      value={option.value}
      checked={selectedValue === option.value}
      onChange={handleOptionChange}
    />
    <label htmlFor={option.value}>{option.label}</label>
  </div>
);

export default RadioButton;


import Form from "components/ui/form/Form";
import { Button } from "components/ui/button/Button";
import { Controller, useForm } from "react-hook-form";
import "./multiple-selection.scss";
import "./multiple-choice.scss";
import {
  SubmittedInputInfoRequest,
  useSetApplicationStepAnswerMutation,
  InputResponse,
} from "pages/application/applicationService";
import { useSelector } from "react-redux";
import { getStepData } from "components/question/questionSlice";
import { FormInput } from "components/ui/form/FormInput";
import Checkbox from "components/ui/checkbox/Checkbox";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { getCurrentUserApplicationId } from "features/authSlice";
import { SkipButton } from "pages/application/question/Question";
import { MAX_RACE_SELECTIONS } from "utilities/Constant";

type IFormData = Record<string, boolean | string>;

const MultipleSelection = () => {
  const stepData = useSelector(getStepData);
  const applicationId = useSelector(getCurrentUserApplicationId);
  const [updateApplication] = useSetApplicationStepAnswerMutation({
    fixedCacheKey: "updateApplication",
  });
  const { handleSubmit, register, control, setValue } = useForm<IFormData>();
  const { inputs, submitAction, skipAction, stepCode } = {
    inputs: stepData.inputs,
    skipAction: stepData.skipAction,
    submitAction: stepData.nextStepActions.find(
      ({ actionType }) => actionType === "next"
    )!,
    stepCode: stepData.stepCodeText,
  };

  const [selectedInputs, setSelectedInputs] = useState<string[]>([]);
  const [isError, setIsError] = useState<boolean>(false);

  const setInitialValue = (input: InputResponse) => {
    if (input.dataType === "multiSelect" && input.initialValue === "selected") {
      setSelectedInputs((prevSelected) => [...prevSelected, input.name]);
      setValue(input.name, true);
    } else if (input.dataType === "rawString" && input.initialValue !== "") {
      setValue(input.name, input.initialValue);
    }

    input.childInputs.forEach((childInput) => {
      setInitialValue(childInput);
    });
  };

  useEffect(() => {
    inputs.forEach((input) => {
      setInitialValue(input);
    });
  }, []);

  useEffect(() => {
    setSelectedInputs((prevSelected) => {
      const uniqueSelected = Array.from(new Set(prevSelected));
      if (uniqueSelected.length !== prevSelected.length) {
        return uniqueSelected;
      }
      return prevSelected;
    });
  }, [selectedInputs]);  
  const I_DO_NOT_WISH_TO_ANSWER =
    inputs.find((input) => input.label.startsWith("I do not wish to"))?.name ??
    "";

  const isRaceStep = stepCode === "J4";
  const isEthnicityStep = stepCode === "J3";
  const isGenderStep = stepCode === "J8";
  const isMandatoryCheckboxStep = isRaceStep || isEthnicityStep || isGenderStep;

  const clearIDoNotWishToAnswer = () => {
    setSelectedInputs((prevSelected) =>
      prevSelected.filter(
        (selectedName) => selectedName !== I_DO_NOT_WISH_TO_ANSWER
      )
    );
    setValue(I_DO_NOT_WISH_TO_ANSWER, false);
  };

  const clearChildInputs = ({ childInputs }: InputResponse) => {
    childInputs.forEach((childInput) => {
      if (childInput.dataType === "multiSelect") {
        setSelectedInputs((prevSelected) =>
          prevSelected.filter(
            (selectedName) => selectedName !== childInput.name
          )
        );
        setValue(childInput.name, false);
        clearChildInputs(childInput);
      } else {
        setValue(childInput.name, "");
      }
    });
  };

  const handleInputCheck = (name: string, parentName: string) => {
    if (isRaceStep && selectedInputs.length >= MAX_RACE_SELECTIONS) return;
    if (name === I_DO_NOT_WISH_TO_ANSWER) {
      setSelectedInputs([name]);
      inputs.forEach((input: InputResponse) => {
        if (input.name !== I_DO_NOT_WISH_TO_ANSWER) {
          setValue(input.name, false);
          input.childInputs.length != 0 && clearChildInputs(input);
        }
      });
    } else {
      const isChildInput = parentName && !selectedInputs.includes(parentName);
      if (isChildInput) {
        setSelectedInputs((prevSelected) => [
          ...prevSelected,
          name,
          parentName,
        ]);
        setValue(parentName, true);
      } else {
        setSelectedInputs((prevSelected) => [...prevSelected, name]);
      }
      selectedInputs.includes(I_DO_NOT_WISH_TO_ANSWER) &&
        clearIDoNotWishToAnswer();
    }
  };

  const handleInputUncheck = (name: string, parentName: string) => {
    setSelectedInputs((prevSelected) =>
      prevSelected.filter((selectedName) => selectedName !== name)
    );
    const currentInput = parentName
      ? inputs
        .find((input) => input.name === parentName)!
        .childInputs.find((childInput) => childInput.name === name)!
      : inputs.find((input) => input.name === name)!;
    currentInput.childInputs.length != 0 && clearChildInputs(currentInput);
  };

  const handleSelection = (
    name: string,
    checked: boolean,
    parentName?: string
  ) => {
    checked
      ? handleInputCheck(name, parentName!)
      : handleInputUncheck(name, parentName!);
  };

  const renderGrandChildInputs = (
    grandChildInputs: InputResponse[],
    parentName: string
  ) => {
    const isChecked = selectedInputs.includes(parentName);
    return (
      <div className="grandchild-inputs">
        {grandChildInputs.map(
          ({ inputId, name, placeholder, description, dataType }) => (
            <div className="grandchild-inputs__input-container" key={inputId}>
              <FormInput
                formHandle={register(name)}
                inputProps={{
                  name,
                  placeholder: placeholder,
                  subText: description,
                  type: dataType,
                  isDisabled: !isChecked,
                }}
              />
            </div>
          )
        )}
      </div>
    );
  };

  const renderChildInputs = (
    childInputs: InputResponse[],
    parentName: string
  ) => {
    const isChecked = selectedInputs.includes(parentName);
    return (
      <div className="child-inputs">
        {childInputs.map(({ inputId, label, name, dataType, ...rest }) => {
          if (dataType === "multiSelect") {
            return (
              <div key={inputId} className="child-inputs__checkbox-container">
                <Controller
                  name={name}
                  control={control}
                  render={({ field }) => {
                    return (
                      <Checkbox
                        label={label}
                        onChange={(value) => {
                          field.onChange(value);
                          handleSelection(name, Boolean(value), parentName);
                        }}
                        checkboxState={selectedInputs.includes(name)}
                      />
                    );
                  }}
                />
                {renderGrandChildInputs(rest.childInputs, name)}
              </div>
            );
          } else {
            return (
              <div key={inputId} className="child-inputs__input-container">
                <FormInput
                  formHandle={register(name)}
                  inputProps={{
                    name,
                    placeholder: rest.placeholder,
                    subText: rest.description,
                    type: dataType,
                    isDisabled: !isChecked,
                  }}
                />
              </div>
            );
          }
        })}
      </div>
    );
  };

  const onSubmit = async (formData: IFormData) => {
    if (selectedInputs.length === 0 && isMandatoryCheckboxStep) {
      setIsError(true);
      return;
    }
    const submittedInputs: SubmittedInputInfoRequest[] = [];
    const processInput = ({
      inputId,
      name,
      dataType,
      childInputs,
    }: InputResponse) => {
      let submittedValue = "";
      if (formData[name]) {
        if (dataType === "multiSelect") {
          if(selectedInputs.includes(name))
          {
            submittedValue = "selected";
          }
        } else {
          submittedValue = String(formData[name]);
        }
      }
      submittedInputs.push({
        inputId,
        name,
        submittedValue,
      });

      if (childInputs.length > 0) {
        childInputs.forEach((childInput) => {
          processInput(childInput);
        });
      }
    };

    inputs.forEach((input) => {
      processInput(input);
    });

    await updateApplication({
      id: applicationId,
      stepActionId: submitAction.stepActionId,
      answeredInputs: submittedInputs,
    });
  };

  const handleSkip = async () => {
    const submittedInputs: SubmittedInputInfoRequest[] = [];

    const resetInput = ({ inputId, name, childInputs }: InputResponse) => {
      const submittedValue = "";
      submittedInputs.push({
        inputId,
        name,
        submittedValue,
      });

      if (childInputs.length > 0) {
        childInputs.forEach((childInput) => {
          resetInput(childInput);
        });
      }
    };

    inputs.forEach((input: InputResponse) => {
      setValue(input.name, false);
      input.childInputs.length != 0 && clearChildInputs(input);
      resetInput(input);
    });

    await updateApplication({
      id: applicationId,
      stepActionId: skipAction?.stepActionId,
      answeredInputs: submittedInputs,
    });
  };

  return (
    <Form formClassName="multiple-choice col">
      <div className="multiple-select">
        {isError && (
          <div className="multiple-select__error">
            <FontAwesomeIcon icon={solid("exclamation-circle")} />
            <p>You must make a selection.</p>
          </div>
        )}
        {inputs.map(({ inputId, name, label, childInputs }) => {
          const isChecked = selectedInputs.includes(name);
          return (
            <div className="multiple-select__input-container" key={inputId}>
              <Controller
                name={name}
                control={control}
                render={({ field }) => {
                  return (
                    <Checkbox
                      label={label}
                      onChange={(value) => {
                        field.onChange(value);
                        handleSelection(name, Boolean(value));
                      }}
                      checkboxState={isChecked}
                      disabled={
                        name === I_DO_NOT_WISH_TO_ANSWER &&
                        selectedInputs.length > 0 &&
                        !isChecked
                      }
                    />
                  );
                }}
              />
              {renderChildInputs(childInputs, name)}
            </div>
          );
        })}

        <Button
          title={submitAction.label}
          className="multiple-select-button"
          onClick={handleSubmit(onSubmit)}
        />
        {skipAction && (
          <SkipButton skipAction={skipAction} skipActionHandler={handleSkip} />
        )}
      </div>
    </Form>
  );
};

export default MultipleSelection;

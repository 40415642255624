import { useState } from "react";
import "./register-external-user.scss";
import Form from "components/ui/form/Form";
import { FormInput } from "components/ui/form/FormInput";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { emailAddress, phone, password_input } from "utilities/formSchemas";
import { Button } from "components/ui/button/Button";
import { z } from "zod";
import {
  RegisterData,
  usePostRegistrationMutation,
} from "../registrationService";
import { ErrorType } from "utilities/error";
import { useNavigate } from "react-router-dom";
import PasswordCheckerVersion2 from "components/password-and-confirm-password/passwordcheckerversion2/PasswordCheckerVersion2";
import useDesktopChecker from "hooks/useDesktopChecker";
import * as Constant from "utilities/Constant";

const formSchema = z.object({
  email: emailAddress,
  phone,
  password_input,
});
export default function RegisterExternalUser() {
  const {
    handleSubmit,
    control,
    register,
    formState: { errors, dirtyFields },
    setValue,
  } = useForm({
    resolver: zodResolver(formSchema),
  });
  const [hasPwdError, setHasPwdError] = useState(false);
  const [error, setError] = useState(0);
  const navigate = useNavigate();
  const [isCreatePasswordEmpty] = useState(true);
  const isDesktop = useDesktopChecker();
  const [sendRequest] = usePostRegistrationMutation();
  const onSubmit = (data: RegisterData) => {
    if (!hasErrors && !hasPwdError) {
      sendRequest(data)
        .unwrap()
        .catch((error: ErrorType) => {
          if (error.status == 409) {
            navigate("/", { replace: true });
          } else {
            setError(500);
          }
        });
    }
  };
  const ispasswordFieldModified =
    (dirtyFields.password_input as boolean) || false;
  const hasErrors = !!Object.keys(errors).length;
  const isPasswordFieldEmpty = errors.password_input?.message as string;
  return (
    <div className="external-file-registration">
      <div className="external-file-registration__header">
        <span className="external-file-registration__header__text1">
          {isDesktop ? "Welcome to NAF Hub!" : "Welcome to NAFHub!"}
        </span>
        <span className="external-file-registration__header__text2">
          {isDesktop
            ? Constant.registerExternalUserDesktopViewContent
            : Constant.registerExternalUserMobileViewContent}
        </span>
      </div>
      <Form
        onSubmit={handleSubmit((data) => {
          onSubmit({
            emailaddress: data.email as string,
            password: data.password_input as string,
            firstname: data.firstname as string,
            lastname: data.lastname as string,
            loanPurpose: data.loanpurpose as string,
            phoneNumber: data.phone as string,
            referralSource: "",
            referredBy: "someone",
          });
        })}
      >
        <div className="row--item">
          <FormInput
            formHandle={register("email")}
            inputProps={{
              name: "email",
              placeholder: "Enter Your Email",
              label: "Email",
              errorMessage: errors.email?.message as string,
              isReadOnly: true,
            }}
          />
        </div>
        <div className="row--item">
          <FormInput
            formHandle={register("phone")}
            inputProps={{
              name: "phone",
              placeholder: "Enter Your Mobile Number",
              label: "Mobile Number",
              errorMessage: errors.phone?.message as string,
              isReadOnly: true,
            }}
          />
        </div>
        <Controller
          name="password_input"
          control={control}
          render={({ field: { onChange } }) => (
            <PasswordCheckerVersion2
              onChange={onChange}
              checkErrors={ispasswordFieldModified}
              createLabel="Create Password"
              setHasPasswordError={setHasPwdError}
              inputBorderHighlightForError={
                isPasswordFieldEmpty
                  ? isCreatePasswordEmpty
                  : !isCreatePasswordEmpty
              }
              setValue={setValue}
              createPasswordPlaceholderName="Enter Password"
              showConfirmPassword={false}
            />
          )}
        />
        <Button title="Continue" className="continue-btn" />
      </Form>
    </div>
  );
}

import { createSlice, createSelector } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

interface ApplicationState {
  activeQuestion: Question;
  loading: boolean;
  isFirstQuestion: boolean;
  questionHistory: Question[];
}

interface SubmitAction {
  text: string;
  stepId: string;
}

export type VisibleStepMetadataType = "text" | "checkbox";

export interface VisibleStepMetadata {
  dataLabel: string;
  dataValue: string;
  type: VisibleStepMetadataType;
}

interface Question {
  progress: Progress;
  section: Section;
  stepId: string;
  stepCode: string;
  stepLabel: string;
  stepLabelSubText?: string;
  stepType: string;
  visibleStepMetadata?: VisibleStepMetadata;
  skipActionId: string | null;
  hasPrevious: boolean;
  stepDirection?: boolean;
  stepActions: StepAction[];
  submitAction?: SubmitAction;
}

interface Progress {
  sectionCompletePercentage: number;
  applicationCompletePercentage: number;
}

interface Section {
  sectionId: string;
  sectionCode: string;
  sectionLabel: string;
}

export interface AdditionalButton {
  additionalButtonLabel: string;
  ButtonVariant?:
    | "primary"
    | "outline-primary"
    | "secondary"
    | "outline-secondary"
    | "white"
    | "transparent"
    | "success"
    | "warning"
    | "danger";
}

export interface StepActionOptions {
  label: string;
  value: string | number;
}

export interface StepAction {
  stepActionId: string;
  stepActionLabel: string;
  stepActionButtonLabel?: string;
  additionalButtons?: AdditionalButton[];
  stepActionType?:
    | "date"
    | "number"
    | "text"
    | "fullAddress"
    | "select"
    | "state"
    | "currency"
    | "month"
    | "year"
    | "monthandyear"
    | "textArea"
    | "multi-choice"
    | "multi-choicewithInput"
    | "single-choicewithInput"
    | "single-choice"
    | "otherButton"
    | "otherText"
    | "ssn";
  schemaType?: string;
  isCheckBoxRequired?: boolean;
  stepInputSubText?: string;
  checkboxLabel?: string;
  placeHolder?: string;
  initialValue?: string;
  stepActionMaxChar?: number;
  stepActionOption?: StepActionOptions[];
  stepActionOptions?: StepActionOptions[];
  stepActionOptionsDirection?: "row" | "column";
  stepOptions?: [];
  hasPreviouslySelected?: boolean;
}

const singleInput: Question = {
  progress: {
    sectionCompletePercentage: 25,
    applicationCompletePercentage: 1,
  },
  section: {
    sectionId: "0f8fad5b-d9cb-469f-a165-70867728950e",
    sectionCode: "C",
    sectionLabel: "Employment & Income",
  },
  stepId: "0f8fad5b-d9cb-469f-a165-70867728950e",
  stepCode: "C1",
  stepLabel: "What is your gross annual income?",
  stepType: "singleInput",
  skipActionId: "0f8fad5b-d9cb-469f-a165-70867728950c",
  hasPrevious: false,
  stepActions: [
    {
      stepActionId: "0f8fad5b-d9cb-469f-a165-70867728951e",
      stepActionLabel: "Gross Annual Income",
      stepActionType: "number",
    },
  ],
};

const radioButtonSmartInput: Question = {
  progress: {
    sectionCompletePercentage: 25,
    applicationCompletePercentage: 5,
  },
  section: {
    sectionId: "0f8fad5b-d9cb-469f-a165-70867728950e",
    sectionCode: "C",
    sectionLabel: "Employment & Income",
  },
  stepId: "0f8fad5b-d9cb-469f-a165-70867728951e",
  stepCode: "C2",
  stepLabel:
    "Please indicate the type of relationship and the state in which the relationship was formed",
  stepType: "singleChoice",
  skipActionId: "0f8fad5b-d9cb-469f-a165-70867728950c",
  hasPrevious: true,
  stepActions: [
    {
      stepActionId: "0f8fad5b-d9cb-469f-a165-70867728950b",
      stepActionLabel: "Civil Union",
    },
    {
      stepActionId: "0f8fad5b-d9cb-469f-a165-70867728950b",
      stepActionLabel: "Domestic Partnership",
    },
    {
      stepActionId: "0f8fad5b-d9cb-469f-a165-70867728950b",
      stepActionLabel: "Registered Reciprocal Beneficiary Relationship",
    },
    {
      stepActionId: "0f8fad5b-d9cb-469f-a165-70867728950b",
      stepActionLabel: "Other",
      stepActionType: "otherButton",
    },
    {
      stepActionId: "0f8fad5b-d9cb-469f-a165-70867728950b",
      stepActionLabel: "Please explain type of relationship",
      stepActionType: "otherText",
    },
  ],
};

const singleInput2: Question = {
  progress: {
    sectionCompletePercentage: 25,
    applicationCompletePercentage: 15,
  },
  section: {
    sectionId: "0f8fad5b-d9cb-469f-a165-70867728950b",
    sectionCode: "D",
    sectionLabel: "Property",
  },
  stepId: "0f8fad5b-d9cb-469f-a165-70867728950b",
  stepCode: "B1",
  stepLabel: "What is the address?",
  stepType: "singleInput",
  skipActionId: "0f8fad5b-d9cb-469f-a165-70867728950c",
  hasPrevious: true,
  stepActions: [
    {
      stepActionId: "0f8fad5b-d9cb-469f-a165-70867728951b",
      stepActionLabel: "Address",
      stepActionType: "fullAddress",
    },
  ],
};

const multipleInput: Question = {
  progress: {
    sectionCompletePercentage: 25,
    applicationCompletePercentage: 30,
  },
  section: {
    sectionId: "0f8fad5b-d9cb-469f-a165-70867728950b",
    sectionCode: "F",
    sectionLabel: "Credit",
  },
  stepId: "0f8fad5b-d9cb-469f-a165-70867728951b",
  stepCode: "B1",
  stepLabel: "Please provide the following information for your Co-Borrower",
  stepType: "multipleInput",
  skipActionId: "0f8fad5b-d9cb-469f-a165-70867728950c",
  hasPrevious: true,
  stepActions: [
    {
      stepActionId: "0f8fad5b-d9cb-469f-a165-70867728951f",
      stepActionLabel: "First Name",
      stepActionType: "text",
    },
    {
      stepActionId: "0f8fad5b-d9cb-469f-a165-70867728952f",
      stepActionLabel: "Last Name",
      stepActionType: "text",
    },
    {
      stepActionId: "0f8fad5b-d9cb-469f-a165-70867728953f",
      stepActionLabel: "Email Address",
      stepActionType: "text",
      schemaType: "email",
    },
    {
      stepActionId: "0f8fad5b-d9cb-469f-a165-70867728954f",
      stepActionLabel: "Cell Phone Number",
      stepActionType: "text",
      schemaType: "phone",
    },
  ],
  submitAction: {
    text: "Send Invitation To Apply",
    stepId: "0f8fad5b-d9cb-469f-a165-70867728950f",
  },
};

const singleChoice: Question = {
  progress: {
    sectionCompletePercentage: 25,
    applicationCompletePercentage: 45,
  },
  section: {
    sectionId: "0f8fad5b-d9cb-469f-a165-70867728950e",
    sectionCode: "G",
    sectionLabel: "Finances",
  },
  stepId: "0f8fad5b-d9cb-469f-a165-70867728950f",
  stepCode: "C14",
  stepLabel: "Do you have any dependents?",
  stepType: "singleChoice",
  skipActionId: null,
  hasPrevious: true,
  stepActions: [
    {
      stepActionId: "0f8fad5b-d9cb-469f-a165-70867728950e",
      stepActionLabel: "Yes",
    },
    {
      stepActionId: "0f8fad5b-d9cb-469f-a165-70867728950e",
      stepActionLabel: "No",
    },
  ],
};

const mockedQuestions = [
  singleInput,
  radioButtonSmartInput,
  singleChoice,
  singleInput2,
  multipleInput,
];

const initialState: ApplicationState = {
  activeQuestion: mockedQuestions[0],
  loading: true,
  isFirstQuestion: true,
  questionHistory: [],
};

/**
 * @deprecated The applicationSlice was created at a time when we did not have a backend. It should no longer be used, and all references to it should be migrated to use applicationService.ts
 */
export const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    getActiveQuestion: (state) => {
      state.loading = false;
    },
    setPreviousQuestionAsActive: (state) => {
      state.activeQuestion = state.questionHistory.pop()!;
    },
    setNextQuestionAsActive: (state, action: PayloadAction<string>) => {
      state.questionHistory.push(state.activeQuestion);
      state.activeQuestion = mockedQuestions.find(
        (question) => question.stepId === action.payload
      )!;
      state.isFirstQuestion = false;
    },
  },
});

const activeQuestion = (state: RootState) => state.application.activeQuestion;

export const selectActiveQuestion = createSelector(
  activeQuestion,
  (activeQuestion) => activeQuestion
);

export const selectSectionLabel = createSelector(
  activeQuestion,
  (activeQuestion) => activeQuestion.section.sectionLabel
);

export const selectSingleInputStepAction = createSelector(
  activeQuestion,
  (activeQuestion) => ({
    ...activeQuestion.stepActions[0],
    placeholder:
      activeQuestion.stepActions[0].placeHolder ??
      `Enter ${activeQuestion.stepActions[0].stepActionLabel}`,
  })
);

export const selectStepCodeAndStepLabel = createSelector(
  activeQuestion,
  (activeQuestion) => ({
    stepCode: activeQuestion.stepCode,
    stepLabel: activeQuestion.stepLabel,
    stepDirection: activeQuestion.stepDirection,
  })
);
export const selectInformationalStepAction = createSelector(
  activeQuestion,
  (activeQuestion) => ({
    ...activeQuestion.stepActions[0],
  })
);

export const selectMultipleInputStepAction = createSelector(
  activeQuestion,
  (activeQuestion) => ({
    stepActions: activeQuestion.stepActions.map((stepAction) => {
      const words = stepAction.stepActionLabel.split(" ");
      let name = words[0].toLowerCase();
      if (words.length > 1) {
        name += words.slice(1).join("");
      }
      const PlaceHolder = stepAction.placeHolder
        ? stepAction.placeHolder
        : `Enter ${stepAction.stepActionLabel}`;
      return {
        ...stepAction,
        name,
        placeholder: PlaceHolder,
      };
    }),
    submitAction: activeQuestion.submitAction,
  })
);

export const selectSingleChoiceStepActions = createSelector(
  activeQuestion,
  (activeQuestion) => activeQuestion.stepActions
);

export const selectMultipleChoiceStepActions = createSelector(
  activeQuestion,
  (activeQuestion) => ({
    stepActions: activeQuestion.stepActions.map((stepAction) => {
      return {
        ...stepAction,
      };
    }),
    submitAction: activeQuestion.submitAction,
  })
);

export const selectHasPrevious = createSelector(
  (state: RootState) => state.application.questionHistory,
  (questionHistory) => questionHistory.length > 0
);

export const selectStepTypeAndLabel = createSelector(
  activeQuestion,
  (activeQuestion) => ({
    stepType: activeQuestion.stepType,
    stepLabel: activeQuestion.stepLabel,
    stepLabelSubText: activeQuestion.stepLabelSubText,
  })
);

export const selectLoading = createSelector(
  (state: RootState) => state.application.loading,
  (loading: boolean) => loading
);

export const selectSectionCode = createSelector(
  activeQuestion,
  (activeQuestion) => activeQuestion.section.sectionCode
);

export const selectProgress = createSelector(
  activeQuestion,
  (activeQuestion) => activeQuestion.progress.applicationCompletePercentage
);

export const selectVisibleStepMetadata = createSelector(
  activeQuestion,
  (activeQuestion) => activeQuestion.visibleStepMetadata
);

export const selectSkipActionId = createSelector(
  activeQuestion,
  (activeQuestion) => activeQuestion.skipActionId
);

export const selectSingleChoiceWithData = createSelector(
  [
    selectSingleChoiceStepActions,
    selectVisibleStepMetadata,
    selectSkipActionId,
  ],
  (stepActions, visibleStepMetadata, skipActionId) => ({
    stepActions,
    visibleStepMetadata: visibleStepMetadata!,
    skipActionId,
  })
);

export const {
  getActiveQuestion,
  setPreviousQuestionAsActive,
  setNextQuestionAsActive,
} = applicationSlice.actions;

export default applicationSlice.reducer;

import { Button } from "../button/Button";
import "./Badge.scss";

export interface BadgeProps {
  title: string;
  color: string;
}

export const Badge = ({ title, color }: BadgeProps) => {
  return (
    <>
      <Button
        className={["modified-badge", `bg--${color}`].join(" ")}
        title={title.toUpperCase()}
        type="round"
        variant="secondary-two"
        size="small"
      />
    </>
  );
};

import { Button } from "components/ui/button/Button";

import {
  useSetApplicationStepAnswerMutation,
  VisibleStepMetadata,
} from "pages/application/applicationService";
import "./informational.scss";
import { getStepData } from "components/question/questionSlice";
import { useSelector } from "react-redux";
import { getCurrentUserApplicationId } from "features/authSlice";
import DisclaimerText from "components/ui/disclaimer-text/DisclaimerText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { SkipButton } from "pages/application/question/Question";

const Informational = () => {
  const stepData = useSelector(getStepData);
  const {
    nextStepActions: stepActions,
    texts: paragraph,
    disclaimer,
    skipAction,
  } = stepData;

  const stepActionId = stepActions[0].stepActionId;

  const [updateApplication] = useSetApplicationStepAnswerMutation({
    fixedCacheKey: "updateApplication",
  });
  const applicationId = useSelector(getCurrentUserApplicationId);
  const handleSubmit = async () => {
    await updateApplication({
      id: applicationId,
      stepActionId,
    });
  };

  return (
    <div className="information">
      {paragraph.length != 0 && (
        <div className="information__paragraph">
          {paragraph.map(
            ({ header, text, childItems, headerType }: VisibleStepMetadata) => (
              <div key={text}>
                {header && <h6>{header}</h6>}
                <div
                  className={
                    headerType == "timeToComplete"
                      ? "timer_paragraph-container"
                      : "paragraph-container"
                  }
                >
                  {headerType == "timeToComplete" && (
                    <FontAwesomeIcon
                      icon={solid("timer")}
                      className="timer-icon"
                    />
                  )}
                  <p className="timer_paragraph">{text}</p>
                </div>
                <ul>
                  {childItems?.map(({ text }: VisibleStepMetadata) => (
                    <li key={text}>{text}</li>
                  ))}
                </ul>
              </div>
            )
          )}
        </div>
      )}
      <Button
        title={stepActions[0].label}
        onClick={handleSubmit}
        className="informational-button"
      />
      {skipAction && <SkipButton skipAction={skipAction} />}
      {disclaimer && (
        <DisclaimerText
          Header={disclaimer.header}
          Text={disclaimer.text}
          hasIcon
        />
      )}
    </div>
  );
};

export default Informational;

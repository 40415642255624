import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { BASE_BFF_WEB_URL } from "utilities/Constant";

export interface ValidateOtpRequest {
  otp: string;
  flowId: string;
}

export interface ValidateOtpResponse {
  id: string;
  accessToken: string;
  name: string;
  applicationId: string;
  applicationStatus: string;
  status: string;
  hasLosFile: boolean;
  refreshToken: string;
  version: string;
  inquiryId: string;
  dealApplicationId: string;
  userRole: string;
}

export interface TokensResponse {
  refreshToken: string;
  accessToken: string;
  tokenExpireTime: Date;
}

export interface TokensRequest {
  accessToken: string;
  refreshToken: string;
}

export interface ValidateDeviceAuthRequest {
  deviceAuthId: string;
  otp: string;
  userId: string;
}

export interface DeviceAuthResponse {
  id: string;
  status: string;
}

export const authenticationApi = createApi({
  reducerPath: "authenticationApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_BFF_WEB_URL }),
  endpoints: (build) => ({
    postOtpValidation: build.mutation<ValidateOtpResponse, ValidateOtpRequest>({
      query: (body: ValidateOtpRequest) => ({
        url: "/Account/validateotp",
        method: "POST",
        body,
      }),
    }),
    getNewTokens: build.mutation<TokensResponse, TokensRequest>({
      query: (body: TokensRequest) => ({
        url: `/Account/refresh`,
        method: "POST",
        body,
      })
    }),
    postDeviceAuthentication: build.mutation<DeviceAuthResponse, string>({
      query: (deviceAuthId: string) => ({
        url: `/Account/phoneOtp/${deviceAuthId}`,
        method: "POST",
      })
    }),
    postValidateDeviceAuth: build.mutation<ValidateOtpResponse, ValidateDeviceAuthRequest>({
      query: (body: ValidateDeviceAuthRequest) => ({
        url: `/Account/validatePhoneOtp`,
        method: "POST",
        body
      }),
    })
  }),
});

export const { usePostOtpValidationMutation, useGetNewTokensMutation, usePostDeviceAuthenticationMutation, usePostValidateDeviceAuthMutation } = authenticationApi;

import "./thank-you.scss";

export default function ThankYou() {
  return (
    <div className="thankyou">
      <div className="thankyou-wrapper">
        <p className="thankyou-wrapper__header">Thank you!</p>
        <p className="thankyou-wrapper__sub-header">
          If you have a NAF Hub account you will receive an email with
          instructions on how to create a new password.
        </p>
      </div>
    </div>
  );
}
import Overlay from "components/ui/loading-spinners/Overlay/Overlay";
import { getRoutingData, setUserApplication } from "features/authSlice";
import { useGetAuthActionsQuery } from "pages/authentication/userService";
import { useDeleteFailedDocumentsMutation } from "pages/dashboard/home/dashboardService";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ApplicationRoleTypes,
  ApplicationStatusNames,
  LoanStatusNames,
} from "utilities/Constant";
import {
  UserFeature,
  UserVersion,
  userVersionFeatureMap,
} from "utilities/UserversionFeatures";

const getRouteForCompletedApplication = (version: string) => {
  const versionFeatures =
  userVersionFeatureMap[version.toUpperCase() as UserVersion];
  const destination = versionFeatures?.includes(UserFeature.SHOW_DASHBOARD)
  ? "/dashboard"
  : "/application";
return destination;
}

const getRouteForLOS = (version: string) => {
  const versionFeatures =
    userVersionFeatureMap[version.toUpperCase() as UserVersion];
  const destination = versionFeatures?.includes(UserFeature.SHOW_DASHBOARD)
    ? "/dashboard"
    : "/los-auth";
  return destination;
};

const getRouteForApplicationPath = (nhApplicationId?: string): string => {
  return nhApplicationId ? "/application" : "/path";
};

export default function AuthRouting() {
  const dispatch = useDispatch();

  const { userId, version } = useSelector(getRoutingData);
  const navigate = useNavigate();
  const {
    data: authRoutingData,
    isSuccess,
    isLoading,
  } = useGetAuthActionsQuery(userId);
  const [
    removeFailedDocuments,
    { isSuccess: isDeleteFailedDoucumentsSuccess },
  ] = useDeleteFailedDocumentsMutation();
  const performRouting = () => {
    if (authRoutingData) {
      dispatch(setUserApplication(authRoutingData));

      if (
        authRoutingData.applicationStatus.toLowerCase() ===
        ApplicationStatusNames.Completed.toLowerCase()
      ) {
        navigate(getRouteForCompletedApplication(version), { replace: true });
        return;
      }

      if (authRoutingData.hasLosLoanNumber) {
        const shouldNavigateForLOS =
          authRoutingData.applicationRoleType.toLowerCase() ===
          ApplicationRoleTypes.PrimaryBorrower.toLowerCase() ||
          (authRoutingData.applicationRoleType.toLowerCase() ===
            ApplicationRoleTypes.NonSpousalCoBorrower.toLowerCase() &&
            authRoutingData.loanStatus?.status !== LoanStatusNames.Unknown &&
            authRoutingData.loanStatus?.status !== LoanStatusNames.Started);

        if (shouldNavigateForLOS) {
          navigate(getRouteForLOS(version), { replace: true });
          return;
        } else {
          navigate(
            getRouteForApplicationPath(authRoutingData.nhApplicationId),
            { replace: true }
          );
          return;
        }
      }

      navigate(getRouteForApplicationPath(authRoutingData.nhApplicationId), {
        replace: true,
      });
    }
  };

  useEffect(() => {
    if (isSuccess && isDeleteFailedDoucumentsSuccess && !isLoading) {
      performRouting();
    }
  }, [isSuccess, isDeleteFailedDoucumentsSuccess]);
  useEffect(() => {
    void (async () => {
      await removeFailedDocuments(userId);
    })();
  }, []);

  return <Overlay />;
}

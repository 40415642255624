import "./welcome-popup.scss";
import WelcomeAnimate from "assets/images/WelcomeAnimate.gif"
import { Button } from "components/ui/button/Button";
import { showDocuments } from "utilities/UserversionFeatures";
import { CONTACT_US } from "utilities/Constant";

interface WelcomePopUpProps {
  readonly onClose?: () => void;
  readonly userName?: string;
}

export default function WelcomePopup({ onClose,userName }: WelcomePopUpProps) {
  return (
    <div className="welcome">
      <div className="welcome__logo">
          <img
            className="welcome__logo--content"
            src={WelcomeAnimate}
            alt="welcomeAnimation"
          />
      </div>
      <div className="welcome__content">
        <div className={showDocuments() ? "welcome__text--max_height" :"welcome__text" }>
          <p className={showDocuments() ? "welcome__text--p1" : "welcome__text--p1-center"}>Welcome to your loan dashboard, {userName}!</p>
          {showDocuments() ?
            (
              <>
                <p className="welcome__text--p2">
                  We're here to prioritize your unique journey and provide support
                  whenever you need it.
                </p>
                <p className="welcome__text--p3">
                  With your personal dashboard, you can easily track progress and 
                  securely upload documents.
                </p>
              </>
            ) : (
              <p className="welcome__text--p4">
                Here, you can track your loan progress. We’ve created a list of items that you can gather to help you get a head start when you speak with your loan officer. If you want to get connected now call us at: {CONTACT_US}
              </p>
            )}
        </div>
        <div className="welcome__button">
          <Button
            title="Let's get started!"
            type="round"
            variant="primary"
            size="large"
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  );
}

import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  LoanStatusType,
  LoanSubStatusType,
} from "pages/dashboard/home/dashboardService";

interface LoanStatusData {
  label: {
    title: string;
    description: string;
  };
  isActive?: boolean;
  icon: IconDefinition; 
  width?: number;
  subStatus?: Record<LoanSubStatusType, LoanStatusData>;
}

export const data: Record<LoanStatusType, LoanStatusData> = {
  Started: {
    label: {
      title: "Started",
      description:
        "We have received your information and will be in touch shortly!",
    },
    isActive: false,
    icon: solid("user-plus"),
    width: 100 / 6,
  },
  Processing: {
    label: {
      title: "Processing",
      description:
        "We are gathering the required items to submit your loan to underwriting.",
    },
    isActive: false,
    icon: regular("gear"),
    width: (2 * 100) / 6,
  },
  Underwriting: {
    label: {
      title: "Submitted to Underwriting",
      description:
        "Income, asset, credit, and property documents are under review for verification.",
    },
    isActive: false,
    icon: solid("paper-plane"),
    width: (3 * 100) / 6,
  },
  Approval: {
    label: {
      title: "Conditionally Approved",
      description:
        "We are actively underwriting the loan file and may need additional information.",
    },
    isActive: false,
    icon: solid("clipboard-check"),
    width: (4 * 100) / 6,
    subStatus: {
      UnderReview: {
        label: {
          title: "Under Review",
          description:
            "Loan file is under additional review. Please contact QA 01 at 800-450-2010 for more information.",
        },
        icon: solid("clipboard-check"),
      },
      ConditionallyApproved: {
        label: {
          title: "Conditionally Approved",
          description:
            "We are actively underwriting the loan file and may need additional information.",
        },
        icon: solid("clipboard-check"),
      },
    },
  },
  FinalReview: {
    label: {
      title: "Final Review",
      description:
        "We are actively reviewing the file and may need additional information.",
    },
    isActive: false,
    icon: solid("rotate"),
    width: (5 * 100) / 6,
  },
  Close: {
    label: {
      title: "Cleared to Close",
      description:
        "All conditions are cleared and your loan is approved for closing!",
    },
    isActive: false,
    icon: solid("thumbs-up"),
    width: 100,
  },
  Funded: {
    label: {
      title: "Funded",
      description: "Loan is closed and funded - Congratulations!",
    },
    isActive: false,
    icon: solid("piggy-bank"),
    width: 100,
  },
  Unknown: {
    label: {
      title: "",
      description: "",
    },
    isActive: false,
    icon: solid("user-plus"),
    width: 0,
  },
};

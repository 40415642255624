import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { BASE_BFF_WEB_URL } from "utilities/Constant";

export interface ForgotPasswordData {
  username: string;
}

export const forgotPasswordApi = createApi({
  reducerPath: "forgotPasswordApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_BFF_WEB_URL }),
  endpoints: (build) => ({
    postForgotPassword: build.mutation<boolean ,ForgotPasswordData>({
      query: (body: ForgotPasswordData) => ({
        url: "/Account/forgotpassword",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { usePostForgotPasswordMutation } = forgotPasswordApi;

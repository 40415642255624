import { useGetApplicationByIdQuery } from "pages/application/applicationService";
import {
  hasBlock,
  getBlockType,
} from "pages/application/templates/Summary/queries";
import { useSelector } from "react-redux";
import { getCurrentUserApplicationId } from "features/authSlice";

export const useGetPrimaryAddressQuery = (
  blockType: "propertyApplicant" | "propertyApplicantSpouse"
) => {
  const applicationId = useSelector(getCurrentUserApplicationId);
  return useGetApplicationByIdQuery(applicationId, {
    selectFromResult: ({ data }) => {
      if (!hasBlock(data, blockType)) {
        return {
          propertyOwnedLabel: "",
          addressLine1: "",
          addressLine2: "",
          primaryPropertyLabel: "",
        };
      }
      const { label: propertyOwnedLabel, dataItems } = getBlockType(
        data,
        blockType
      );

      const propertyApplicantAddress = dataItems.find(
        ({ dataItemType }) => dataItemType === "primaryProperty"
      );

      const street = propertyApplicantAddress?.fields[0]?.value ?? "";
      const city = propertyApplicantAddress?.fields[1]?.value ?? "";
      const state = propertyApplicantAddress?.fields[2]?.value ?? "";
      const zip = propertyApplicantAddress?.fields[3]?.value ?? "";

      return {
        propertyOwnedLabel,
        addressLine1: street,
        addressLine2: `${city}, ${state} ${zip}`,
        primaryPropertyLabel: propertyApplicantAddress?.label,
      };
    },
  });
};
export const useGetAdditionalPropertiesQuery = (
  blockType: "propertyApplicant" | "propertyApplicantSpouse"
) => {
  const applicationId = useSelector(getCurrentUserApplicationId);
  return useGetApplicationByIdQuery(applicationId, {
    selectFromResult: ({ data }) => {
      if (!hasBlock(data, blockType)) {
        return {
          additionalProperties: [],
          additionalPropertyLabel: "",
          addAdditionalPropertyAction: undefined,
        };
      }
      const { dataItems, actions } = getBlockType(data, blockType);

      const additionalProperties = dataItems
        .filter(({ dataItemType }) => dataItemType === "additionalProperty")
        .map(({ fields, actions }) => ({
          addressLine1: `${fields[0]?.value ?? ""}`,
          addressLine2: `${fields[1]?.value ?? ""}, ${fields[2]?.value ?? ""} ${
            fields[3]?.value ?? ""
          }`,
          editAction: actions.find(({ actionType }) => actionType === "edit")!,
          removeAction: actions.find(
            ({ actionType }) => actionType === "remove"
          )!,
        }));

      const addAdditionalPropertyAction = actions.find(
        ({ actionType }) => actionType === "add"
      );

      return {
        additionalProperties,
        additionalPropertyLabel: dataItems.find(
          ({ dataItemType }) => dataItemType === "additionalProperty"
        )?.label,
        addAdditionalPropertyAction,
      };
    },
  });
};

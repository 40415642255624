import "./declarations.scss";
import { useGetDeclarationsQuery } from "./queries";
import { ActionButton } from "pages/application/templates/Summary";
import { useState } from "react";
import { NextStepActionResponse } from "pages/application/applicationService";

const Declarations = ({
  hasSpouseCoApplicant,
}: {
  hasSpouseCoApplicant: boolean;
}) => {
  return (
    <div className="declarations">
      {hasSpouseCoApplicant ? (
        <CoBorrowersDeclarations />
      ) : (
        <SingleBorrowerDeclarations />
      )}
    </div>
  );
};

export const CoBorrowersDeclarations = () => {
  const {
    applicantName,
    spouseCoApplicantName,
    applicantDeclarations,
    spouseCoApplicantDeclarations,
  } = useGetDeclarationsQuery();

  const [declarations, setDeclarations] = useState(applicantDeclarations);
  const [isActive, setIsActive] = useState("applicant");

  return (
    <div className="co_borrowers-declarations">
      <div className="name-header">
        <p
          className={
            isActive === "applicant"
              ? "applicant-name is-active"
              : "applicant-name not-active"
          }
          onClick={() => {
            setDeclarations(applicantDeclarations);
            setIsActive("applicant");
          }}
        >
          {applicantName}
        </p>

        <p
          className={
            isActive === "co_applicant"
              ? "applicant-name is-active"
              : "applicant-name not-active"
          }
          onClick={() => {
            setDeclarations(spouseCoApplicantDeclarations);
            setIsActive("co_applicant");
          }}
        >
          {spouseCoApplicantName}
        </p>
      </div>
      <hr />
      {declarations ? (
        <div className="declarations-wrapper">
          {declarations.map(({ displayName, value, actions }) => (
            <Declaration
              key={displayName}
              declaration={displayName}
              value={value}
              editDetails={actions ? actions[0] : null}
            />
          ))}
        </div>
      ) : (
        <div className="no-declarations">Questions will be answered later</div>
      )}
    </div>
  );
};

export const SingleBorrowerDeclarations = () => {
  const { applicantDeclarations } = useGetDeclarationsQuery();
  return (
    <div className="single-borrower-declarations">
      {applicantDeclarations?.map(({ displayName, value, actions }) => (
        <Declaration
          key={displayName}
          declaration={displayName}
          value={value}
          editDetails={actions ? actions[0] : null}
        />
      ))}
    </div>
  );
};

export const Declaration = ({
  declaration,
  value,
  editDetails,
}: {
  declaration: string;
  value: string;
  editDetails: NextStepActionResponse | null;
}) => {
  return (
    <div className="declaration-wrapper">
      <div className="declaration">{declaration}</div>
      <div className="declaration-value">{value}</div>
      {editDetails && (
        <div className="declaration-edit">
          <ActionButton action="edit" {...editDetails} />
        </div>
      )}
    </div>
  );
};

export default Declarations;

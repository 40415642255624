import { MonthAndYearState } from "components/month-year-select/MonthYearSelect";
import { Select } from "components/ui/select/Select";
import { makeYearOptions } from "utilities/date";

interface IYearSelectProps {
  label?: string;
  className?: string;
  monthAndYear?: MonthAndYearState;
  errorMessage?: string;
  onChange: (...event: any[]) => void;
}

export function YearSelect({
  label,
  className = "",
  monthAndYear,
  errorMessage,
  onChange,
}: IYearSelectProps) {
  const handleOnChange = (e: any) => {
    onChange((previousValue: MonthAndYearState) => ({
      ...previousValue,
      year: e as string,
    }));
  };

  return (
    <Select
      options={makeYearOptions(Number(monthAndYear?.month))}
      placeholder="Year"
      label={label}
      className={className}
      onChange={handleOnChange}
      errorMessage={errorMessage}
    />
  );
}

import spinner from "assets/images/Spinner.png";
import "./loader.scss";

export interface LoaderProps {
  header?: React.ReactNode;
  message1?: string;
  message2?: string;
}

export const Loader = ({
  header,
  message1,
  message2,
}: LoaderProps) => {
  return (
    <div className="loader">
      <div className="loader__header">{header}</div>
      <img src={spinner} alt="loading" className="loader__spinner-icon" />
      <div className="loader__message1">{message1}</div>
      <div className="loader__message2">{message2}</div>
    </div>
  );
};

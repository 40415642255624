import { MonthAndYearState } from "components/month-year-select/MonthYearSelect";
import { Select } from "components/ui/select/Select";
import { makeMonthOptions } from "utilities/date";

interface IMonthSelectProps {
  label?: string;
  className?: string;
  monthAndYear?: MonthAndYearState;
  errorMessage?: string;
  onChange: (...event: any[]) => void;
}

export function MonthSelect({
  label,
  className = "",
  monthAndYear,
  errorMessage,
  onChange,
}: IMonthSelectProps) {
  const handleOnChange = (e: any) => {
    onChange((previousValue: MonthAndYearState) => ({
      ...previousValue,
      month: e as string,
    }));
  };
  return (
    <Select
      options={makeMonthOptions(Number(monthAndYear?.year))}
      placeholder="Month"
      label={label}
      className={className}
      onChange={handleOnChange}
      errorMessage={errorMessage}
    />
  );
}

import { Routes } from "components/routes/Routes";
import {
  AppInsightsContext,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { AI } from "app-insights/app-insights";
import "./App.scss";

function App() {
  {
    //tracking page view explicitly for single page application
    AI.appInsights.trackPageView();
  }
  return (
    <AppInsightsContext.Provider value={AI.reactPlugin}>
      <div className="App">
        <Routes />
      </div>
    </AppInsightsContext.Provider>
  );
}
withAITracking(AI.reactPlugin, App, "appcomponent");
export default App;

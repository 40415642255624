import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import * as Constant from "utilities/Constant";
import { Button } from "components/ui/button/Button";
import Form from "components/ui/form/Form";
import { FormInput } from "components/ui/form/FormInput";
import { emailAddress } from "utilities/formSchemas";
import "./forgot-password.scss";
import {
  ForgotPasswordData,
  usePostForgotPasswordMutation,
} from "./forgotPasswordService";
import { ErrorType } from "utilities/error";
import { useState } from "react";
import { LoadingOverlay } from "components/ui/loading-spinners";
import ThankYou from "pages/thank-you/ThankYou";

const formSchema = z.object({ email: emailAddress });

export default function ForgotPassword() {
  const [error, setError] = useState(0);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: zodResolver(formSchema) });
  const [sendRequest, { data, isLoading, isSuccess }] =
    usePostForgotPasswordMutation();
  const onSubmit = (data: ForgotPasswordData) => {
    return sendRequest(data)
      .unwrap()
      .catch((error: ErrorType) => {
        if (error.status == 500) {
          setError(500);
        }
      });
  };
  if (isLoading) return <LoadingOverlay text="Loading..." />;
  if (error != 500 && isSuccess && data) {
    return <ThankYou />;
  }
  return (
    <div className="forgot-password">
      <div className="forgot-password__wrapper">
        <p className="forgot-password__header">Reset Password</p>
        <p className="forgot-password__text">
          Please enter the email address you used at sign up. You will receive
          an email from support@naf.com with instructions to reset your
          password.
        </p>
      </div>
      {error == 500 && (
        <div className="error__container">
          {Constant.ForgotPasswordError.map((error) => {
            return (
              <p key={error} className="error__message">
                {error}
              </p>
            );
          })}
        </div>
      )}
      <Form
        onSubmit={handleSubmit(async (data) => {
          await onSubmit({
            username: data.email as string,
          });
        })}
      >
        <FormInput
          formHandle={register("email")}
          inputProps={{
            name: "email",
            placeholder: "Enter Your Email",
            label: "Email",
            errorMessage: errors.email?.message as string,
          }}
        />
        <Button title={"Reset Password"} />
      </Form>
    </div>
  );
}

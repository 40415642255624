import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import "./in-place-error.scss";

export interface IInPlaceErrorProps {
  readonly errorPhrase?: string;
  readonly hasErrorIcon?: boolean;
}

export default function InPlaceError({
  errorPhrase,
  hasErrorIcon = true,
}: IInPlaceErrorProps) {
  return (
    <div className="error-message__container">
      {hasErrorIcon && (
        <div className="error-message__icon">
          <FontAwesomeIcon icon={regular("exclamation-triangle")} />
        </div>
      )}
      <div className="error-message">{errorPhrase}</div>
    </div>
  );
}

import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryWithAuth } from "services/common/rtkQueryConfig";

export const applicationApi = createApi({
  baseQuery: baseQueryWithAuth,
  tagTypes: ["Application"],
  endpoints: (build) => ({
    getApplicationById: build.query<StepResponse, string>({
      query: (id) => `/application/${id}`,
    }),
    setApplicationStepAnswer: build.mutation<
      MutationResponse,
      Partial<StepAnswerRequest> & Pick<StepAnswerRequest, "id">
    >({
      query: ({ id, ...patch }) => ({
        url: `/application/${id}`,
        method: "PATCH",
        body: patch,
      }),
    }),
    setApplicationPreviousStep: build.mutation<
      StepResponse,
      Partial<StepAnswerRequest> & Pick<StepAnswerRequest, "id">
    >({
      query: ({ id, ...patch }) => ({
        url: `/application/${id}/previousStep`,
        method: "PATCH",
        body: patch,
      }),
    }),
    getLoanOfficerRatings: build.query<Ratings, string>({
      query: (id) => `/LoanOfficer/user-id/${id}/loanofficer-rating`,
    }),
    getAddressSuggestions: build.query<string[], string>({
      query: (inputData) => `/application/address/${inputData}`,
    }),
  }),
});

export const {
  useGetApplicationByIdQuery,
  useSetApplicationStepAnswerMutation,
  useSetApplicationPreviousStepMutation,
  useGetLoanOfficerRatingsQuery,
  useGetAddressSuggestionsQuery,
} = applicationApi;

type StepActionResponse =
  | "skip"
  | "regularAction"
  | "next"
  | "edit"
  | "remove"
  | "add"
  | "otherButton"
  | "otherText";

export interface NextStepActionResponse {
  actionType: StepActionResponse;
  stepActionId: string;
  label: string;
  presentationOrder: number;
  hasPreviouslySelected?: boolean;
}

export interface InputResponse {
  badDataRegex: string;
  dataType: string;
  goodDataRegex: string;
  hideDataOnEntry: boolean;
  initialValue: string;
  hiddenValue: string;
  isHiddenInput: boolean;
  inputId: string;
  isOptional: boolean;
  label: string;
  maxDecimals: number;
  maxDigits: number;
  maxStringLength: number;
  minDecimals: number;
  minDigits: number;
  minStringLength: number;
  name: string;
  placeholder: string;
  description: string;
  properties: {
    options?: string[];
    purchasePropertyPrice?: number;
  };
  childInputs: InputResponse[];
}

type ActiveSummarySection =
  | "profile"
  | "property"
  | "credit"
  | "finances"
  | "acknowledgements";

export type SectionType =
  | "Profile"
  | "Property"
  | "Credit"
  | "Finances"
  | "Acknowledgements"
  | "Unknown";

export interface Section {
  sectionCode: string;
  sectionLabel: string;
  complete: boolean;
  progressWeight: number;
}

export interface StepResponse {
  stepId: string;
  label: string;
  subLabel?: string;
  nextStepActions: NextStepActionResponse[];
  skipAction?: NextStepActionResponse;
  stepType: string;
  inputs: InputResponse[];
  hasPreviousStep: boolean;
  blocks?: BlockResponse[];
  activeSummarySection?: ActiveSummarySection;
  section: SectionType;
  sections: Section[];
  stepCodeText: string;
  progress: number;
  texts: VisibleStepMetadata[];
  disclaimer?: StepDisclaimer;
}

export interface StepDisclaimer {
  header: string;
  text: string;
}

export interface TableRowMetadata {
  columns: string[];
}

interface TableMetadata {
  rows: TableRowMetadata[];
}

export interface VisibleStepMetadata {
  header: string;
  text: string;
  table?: TableMetadata;
  headerType?: string;
  childItems?: VisibleStepMetadata[];
}

export interface MutationResponse {
  actionMessage?: string;
  validation: Validation;
  nextStep: StepResponse;
}

export interface Validation {
  success: boolean;
  message: string;
  inputErrors: InputError[];
}
interface InputError {
  inputId: string;
  name: string;
  errorMessage: string;
}

interface BlockResponse {
  label: string;
  description: string;
  dataItems: DataItemResponse[];
  blockType: BlockType;
  actions: NextStepActionResponse[];
}

export type BlockType =
  | "profileApplicant"
  | "profileSpouseCoApplicant"
  | "profileNonSpouseCoApplicant"
  | "creditApplicant"
  | "creditSpouseCoApplicant"
  | "propertyApplicant"
  | "propertyApplicantSpouse"
  | "financesApplicantIncome"
  | "financesSpouseCoApplicantIncome"
  | "financesApplicantAssets"
  | "financesSpouseCoApplicantAssets"
  | "financesAssets"
  | "acknowledgementsDemographics"
  | "acknowledgementsDeclarations";

interface DataItemResponse {
  label: string;
  fields: FieldResponse[];
  actions: NextStepActionResponse[];
  dataItemType: DataItemType;
}

type DataItemType =
  | "primaryAddress"
  | "mailingAddress"
  | "primaryBorrower"
  | "profileSpouse"
  | "coBorrower"
  | "primaryProperty"
  | "additionalProperty"
  | "electronicallyVerifiedAsset"
  | "borrowerDemographics"
  | "borrowerDeclarations"
  | "coBorrowerDemographics"
  | "coBorrowerDeclarations";

export interface FieldResponse {
  displayName: string;
  value: string;
  actions: NextStepActionResponse[];
}

export interface SubmittedInputInfoRequest {
  inputId: string;
  submittedValue: string;
  name: string;
}

interface StepAnswerRequest {
  id: string;
  stepActionId?: string;
  stepActionValue?: string;
  answeredInputs?: SubmittedInputInfoRequest[];
}

export interface LoanOfficerDetail {
  name: string;
  fullName: string;
  nmlsNumber: string;
  email: string;
  phoneNumber: string;
  phones: {
    contactID: number;
    type: string;
    number: string;
    extension: string;
  }[];
  photoImageUrl: string;
  hasRatingInfo: boolean;
  averageRating: number;
  reviewCount: number;
  readMoreReviewsLink: string;
  workEmail: string;
  nmlsLicenseNumber: string;
}

interface OverallRating {
  averageRating: number;
  reviewCount: number;
}

interface Ratings {
  loanOfficerDetail?: LoanOfficerDetail;
  overallRating?: OverallRating;
}

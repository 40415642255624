import { Controller, useForm } from "react-hook-form";
import { ZodError, z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "components/ui/button/Button";
import Form from "components/ui/form/Form";
import { FormInput } from "components/ui/form/FormInput";
import { Select } from "components/ui/select/Select";
import { schemas, Schema } from "utilities/formSchemas";
import "./multiple-input.scss";
import { MonthAndYearSelect } from "components/month-year-select/MonthYearSelect";
import Dob from "components/dob/Dob";
import { useEffect, useState } from "react";
import {
  useSetApplicationStepAnswerMutation,
  InputResponse,
} from "pages/application/applicationService";
import { AutoSuggestion } from "components/ui/auto-suggestion/AutoSuggestion";
import { useSelector } from "react-redux";
import { getStepData } from "components/question/questionSlice";
import { statesOfUSA } from "utilities/statesOfUSA";
import { getCurrentUserApplicationId } from "features/authSlice";
import Checkbox from "components/ui/checkbox/Checkbox";
import DateInput from "components/date-input/DateInput";
import Ssn from "components/ui/ssn/SSN";
import SsnForMobile from "components/ui/ssn/SSNForMobile";
import { SkipButton } from "pages/application/question/Question";
import useDesktopChecker from "hooks/useDesktopChecker";
import DisclaimerText from "components/ui/disclaimer-text/DisclaimerText";
import RadioButtonGroup from "components/ui/radiobutton/RadioButtonGroup";
import CashInput from "components/ui/cash-input/CashInput";
import Metadata from "components/metadata/Metadata";
import PhoneNumberInput from "components/ui/PhoneNumberInput/PhoneNumberInput";
import { sort } from "utilities/string";

const MultipleInput = () => {
  const stepData = useSelector(getStepData);
  const [updateApplication, { validation }] =
    useSetApplicationStepAnswerMutation({
      fixedCacheKey: "updateApplication",
      selectFromResult: (result) => ({
        validation: result.data?.validation,
      }),
    });

  const {
    inputs,
    nextStepActions: stepActions,
    skipAction,
    stepCodeText,
    disclaimer,
    texts: metadata
  } = stepData;

  const isSpouse = (name: string) => {
    return name.startsWith("Borrower_Spouse");
  };

  // temp logic to check if the current step is co-borrower
  // will change once keynames are updated
  const isCoBorrower = () => {
    return stepCodeText === "C6";
  };

  const schema = z.object(
    inputs.reduce<Schema>(
      (acc, { name, label, dataType, isOptional, initialValue }) => {
        const spouseErr = isSpouse(name) ? "spouse's " : "";
        const coBorrErr = isCoBorrower() ? "co-borrower's " : "";
        const refinedDataType = () => {
          if (isSpouse(name)) {
            return "spouse_" + dataType;
          }
          if (isCoBorrower()) {
            return "coBorrower_" + dataType;
          }
          return dataType;
        };
        if (dataType && Object.hasOwn(schemas, refinedDataType())) {
          acc[name] = schemas[refinedDataType()];
        } else if (isOptional) {
          if (dataType === "zipCode") {
            acc[name] = z.union([
              z.string().length(0, "Please enter valid zip code"),
              z.string().regex(/^(\d{5}([- /]?\d{4})?$)/),
            ]);
          } else {
            acc[name] = z.string().optional();
          }
        } else {
          acc[name] = z
            .string()
            .trim()
            .min(
              1,
              `Please enter your ${spouseErr}${coBorrErr}${label.toLowerCase()}`
            );
        }
        if (validation && !validation.success) {
          validation.inputErrors.forEach((inputError) => {
            if (inputError.name === name) {
              acc[name] = z
                .string()
                .refine((val) => val !== initialValue, {
                  message: inputError.errorMessage,
                })
                .pipe(acc[name]);
            }
          });
        }
        return acc;
      },
      {}
    )
  );
  const [sameAsMineCheckbox, setSameAsMineCheckbox] = useState(false);
  const isDesktop = useDesktopChecker();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    watch,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: inputs.reduce<Record<string, string>>(
      (acc, { name, initialValue }) => {
        acc[name] = initialValue;
        return acc;
      },
      {}
    ),
  });
  const setValidationErrors = () => {
    if (validation && !validation.success) {
      validation.inputErrors.forEach((inputError) => {
        setError(inputError.name, {
          type: "api",
          message: inputError.errorMessage,
        });
      });
    }
  };
  useEffect(() => {
    setValidationErrors();
  }, [validation]);

  const handleFieldChange = (name: string) => {
    if (
      validation?.inputErrors.some((inputError) => inputError.name === name)
    ) {
      clearErrors(name);
      const value = watch(name);
      try {
        schema.pick({ [name]: true }).parse({ [name]: value });
      } catch (error) {
        if (error instanceof ZodError) {
          const errorMessage = error.errors[0]?.message;
          setError(name, {
            type: "apiValidation",
            message: errorMessage,
          });
        }
      }
    }
  };

  const [buttonId, setButtonId] = useState(0);
  const applicationId = useSelector(getCurrentUserApplicationId);
  useEffect(() => {
    inputs.forEach(({ name, initialValue }) => {
      if (
        name.toLowerCase().includes("sameas") &&
        initialValue.toLowerCase() === "selected"
      ) {
        setSameAsMineCheckbox(true);
      }
    });
  }, []);
  const watchfields = watch();
  if (sameAsMineCheckbox) {
    inputs.forEach(({ name, hiddenValue }) => {
      if (
        !name.toLowerCase().includes("sameas") &&
        watchfields[name] !== hiddenValue
      ) {
        setSameAsMineCheckbox(false);
        return;
      }
    });
  }

  function getAutoSuggestedAddress(data: string) {
    const splittedData = data.split(",");
    const addressLen = splittedData.length;
    if (splittedData.length > 3) {
      inputs.forEach(({ name }) => {
        if (name.toLowerCase().includes("city")) {
          setValue(name, splittedData[addressLen - 3].trim(), {
            shouldValidate: true,
          });
        }
        if (
          name.toLowerCase().includes("state") &&
          statesOfUSA.includes(splittedData[addressLen - 2].trim())
        ) {
          setValue(name, splittedData[addressLen - 2].trim(), {
            shouldValidate: true,
          });
        }
      });
    }
  }
  const handleSelection = (name: string, value: boolean) => {
    inputs.forEach(({ name, hiddenValue }) => {
      let data: string;
      if (value) {
        if (name.toLowerCase().includes("sameas")) {
          data = "selected";
        } else {
          data = hiddenValue;
        }
        setValue(name, data, { shouldValidate: true });
      } else {
        data = "";
        setValue(name, data);
      }
    });
    setSameAsMineCheckbox(value);
  };

  const handleSkip = async () => {
    await updateApplication({
      id: applicationId,
      stepActionId: skipAction?.stepActionId,
      answeredInputs: inputs.map(({ inputId, name }) => ({
        inputId,
        submittedValue: "",
        name,
      })),
    });
  };

  const buttonFunctionality = inputs.some(
    (input) => input.dataType === "state"
  );
  const checkboxFunctionality = inputs.some(
    (input) => input.dataType === "hidden" || input.dataType === "state"
  );

  const isContainsSsnAndDob = inputs.some((input) => input.dataType === "ssn") && inputs.some((input) => input.dataType === "date");

  const getFormClassName = (
    inputs: InputResponse[],
    hasCheckboxFunctionality: boolean
  ) => {
    const defaultClassName = "multiple-input";

    if (isContainsSsnAndDob || (hasCheckboxFunctionality && inputs.length === 2)) {
      return `${defaultClassName} two-inputs`;
    } else if (hasCheckboxFunctionality) {
      return `${defaultClassName} checkbox`;
    } else {
      return defaultClassName;
    }
  };

  return (
    <div className={getFormClassName(inputs, checkboxFunctionality)}>
      {metadata.length > 0 && <Metadata metadata={metadata} />}
      <Form
        onSubmit={handleSubmit(async (e) => {
          await updateApplication({
            id: applicationId,
            stepActionId: stepActions[buttonId].stepActionId,
            answeredInputs: inputs.map(({ inputId, name }) => ({
              inputId,
              submittedValue: e[name].toString(),
              name,
            })),
          });
        })}
      >
        {inputs.map(
          ({ inputId, label, dataType, name, properties, isHiddenInput }) => {
            switch (dataType) {
              case "hidden":
                return (
                  <Controller
                    name={name}
                    control={control}
                    render={({ field }) => {
                      return (
                        <Checkbox
                          label={label}
                          hideCheckbox={isHiddenInput ? "hide" : "show"}
                          onChange={(value) => {
                            field.onChange(value);
                            handleSelection(name, Boolean(value));
                          }}
                          checkboxState={sameAsMineCheckbox}
                        />
                      );
                    }}
                  />
                );
              case "fullAddress":
                return (
                  <Controller
                    key={inputId}
                    name={name}
                    control={control}
                    render={({ field }) => {
                      return (
                        <AutoSuggestion
                          {...field}
                          label={label}
                          placeholder={`Enter ${label}`}
                          setOptionData={getAutoSuggestedAddress}
                          preFilledValue={getValues(name)}
                          isSameAsMineChecked={sameAsMineCheckbox}
                          errorMessage={errors[name]?.message}
                        />
                      );
                    }}
                  />
                );
              case "state":
                return (
                  <Controller
                    key={inputId}
                    name={name}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={sort(properties.options!)}
                        placeholder={`Select`}
                        errorMessage={errors[name]?.message}
                        label={label}
                        initialValue={getValues(name)}
                        className="state-select"
                      />
                    )}
                  />
                );
              case "ssn":
                if (isDesktop) {
                  return (
                    <Controller
                      key={inputId}
                      name={name}
                      control={control}
                      render={({ field: { onChange } }) => (
                        <Ssn
                          onChange={onChange}
                          errorMessage={errors[name]?.message}
                          label={label}
                          placeholder="xxx-xx-xxxx"
                          initialValue={getValues(name)}
                        />
                      )}
                    />
                  );
                } else {
                  return (
                    <Controller
                      key={inputId}
                      name={name}
                      control={control}
                      render={({ field: { onChange } }) => (
                        <SsnForMobile
                          onChange={onChange}
                          errorMessage={errors[name]?.message}
                          label={label}
                          placeholder="xxx-xx-xxxx"
                          initialValue={getValues(name)}
                        />
                      )}
                    />
                  );
                }
              case "select":
                if (properties.options) {
                  return (
                    <Controller
                      key={inputId}
                      name={name}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={properties.options!}
                          placeholder={`Select ${label}`}
                          errorMessage={errors[name]?.message}
                          label={label}
                        />
                      )}
                    />
                  );
                } else {
                  return (
                    <FormInput
                      key={inputId}
                      formHandle={register(name)}
                      inputProps={{
                        name,
                        placeholder: `Enter ${label}`,
                        label: label,
                        type: dataType,
                        errorMessage: errors[name]?.message,
                      }}
                    />
                  );
                }
              case "monthandyear":
                return (
                  <Controller
                    key={inputId}
                    name={name}
                    control={control}
                    render={({ field: { onChange } }) => (
                      <MonthAndYearSelect
                        onChange={onChange}
                        errors={errors.monthandyear}
                      />
                    )}
                  />
                );
              case "date":
                return (
                  <Controller
                    key={inputId}
                    name={name}
                    control={control}
                    render={({ field: { onChange } }) => (
                      <Dob
                        onChange={onChange}
                        errorMessage={errors[name]?.message}
                        label={label}
                        initialValue={getValues(name)}
                      />
                    )}
                  />
                );
              case "dateInput":
                return (
                  <Controller
                    name={name}
                    control={control}
                    render={({ field: { onChange } }) => (
                      <DateInput
                        onChange={onChange}
                        placeholder="MM/YYYY"
                        label={label}
                        initialValue={getValues(name)}
                        errorMessage={errors[name]?.message}
                      />
                    )}
                  />
                );
              case "currency":
                return (
                  <Controller
                    name={name}
                    control={control}
                    render={({ field: { onChange } }) => (
                      <CashInput
                        onChange={onChange}
                        label={label}
                        errorMessage={errors[name]?.message}
                        initialValue={getValues(name)}
                      />
                    )}
                  />
                );
              case "termSelectGroup":
                return (
                  <Controller
                    name={name}
                    control={control}
                    render={({ field: { onChange } }) => (
                      <RadioButtonGroup
                        onChange={onChange}
                        options={[
                          { label: "Monthly", value: "monthly" },
                          { label: "Annual", value: "annual" },
                        ]}
                        initialValue={getValues(name)}
                        errorMessage={errors[name]?.message}
                      />
                    )}
                  />
                );
              case "phone":
                return (
                  <Controller
                    name={name}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <PhoneNumberInput
                        onChange={(newValue : string) => {
                          onChange(newValue);
                          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                          setValue(name, schemas.phone.parse(newValue));
                        }}
                        value={value}
                        errorMessage={errors[name]?.message}
                        label={label}
                      />
                    )}
                  />
                );
              default:
                return (
                  <FormInput
                    dataType={dataType}
                    key={inputId}
                    formHandle={register(name)}
                    inputProps={{
                      name,
                      placeholder: `Enter ${label}`,
                      label: label,
                      type: dataType,
                      onChange: () => handleFieldChange(name),
                      errorMessage: errors[name]?.message,
                    }}
                  />
                );
            }
          }
        )}
        {disclaimer && <DisclaimerText Header={disclaimer.header} hasIcon />}
        <div className={buttonFunctionality ? "full-address" : ""}>
          {stepActions.map((action, index) => (
            <Button
              title={action.label}
              key={action.presentationOrder}
              onClick={() => setButtonId(index)}
              className="multiple-input-button"
            />
          ))}
          {skipAction && (
            <SkipButton
              skipAction={skipAction}
              skipActionHandler={handleSkip}
            />
          )}
        </div>
      </Form>
    </div>
  );
};

export default MultipleInput;

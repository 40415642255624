import React, { useState } from "react";
import InPlaceError from "components/in-place-error/InPlaceError";
import { createAutoCorrectedDatePipe } from "text-mask-addons";
import './dob.scss';
import MaskedInput from "react-text-mask";

interface InputProps {
    onChange: (formattedDate: string) => void;
    errorMessage?: string;
    label?: string;
    initialValue?: string;
}

export const Label = ({ label }: { label: string }) => {
    return (
        <div className="dateField-label-wrapper">
            <label htmlFor="label" className="dateField-label">
                {label}
            </label>
        </div>
    );
};

export const Dob = ({
    onChange,
    errorMessage,
    label,
    initialValue,
}: InputProps) => {
    const autoCorrectedDatePipe = createAutoCorrectedDatePipe(
        "mm/dd/yyyy"
    ) as undefined;

    const formatToMMDDYYYY = (dateString: string | null ): string => {
        if (!dateString) {
            return "";
        }    
        const parsedDate = new Date(dateString);
        if (!isNaN(parsedDate.getTime())) {
            return `${String(parsedDate.getMonth() + 1).padStart(2, "0")}/${String(parsedDate.getDate()).padStart(2, "0")}/${parsedDate.getFullYear()}`;
        }    
        return "";
    };

    const [inputValue, setInputValue] = useState<string>(formatToMMDDYYYY(initialValue ?? null));

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value: input } = event.target;
        setInputValue(input);
        const parsedDate = new Date(input);
        const formattedDate = `${parsedDate.getFullYear()}-${String(parsedDate.getMonth() + 1).padStart(2, "0")}-${String(parsedDate.getDate()).padStart(2, "0")}`;
        onChange(formattedDate);
    };

    return (
        <div className={"dob-container"} >
            {label && <Label label={label} />}
            <MaskedInput
                pipe={autoCorrectedDatePipe}
                mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                keepCharPositions
                guide
                aria-label={label}
                className={`dob ${errorMessage ? "dob--error" : ""}`}
                placeholder="mm/dd/yyyy"
                value={inputValue}
                onChange={handleInputChange}
            />
            {errorMessage && <InPlaceError errorPhrase={errorMessage} />}
        </div>
    );
};

export default Dob;

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { z } from "zod";
import { schemas, Schema } from "utilities/formSchemas";
import TextAreaWrapper from "components/ui/textarea/TextAreaWrapper";
import { getFSQuestionCustomEvent } from "utilities/full-story";
import { Button } from "components/ui/button/Button";
import Form from "components/ui/form/Form";
import { Select } from "components/ui/select/Select";
import { FormInput } from "components/ui/form/FormInput";
import { useState } from "react";
import "./single-input.scss";
import CashPercentage from "components/ui/cash-percentage/CashPercentage";
import { useSetApplicationStepAnswerMutation } from "pages/application/applicationService";
import Ssn from "components/ui/ssn/SSN";
import Metadata from "components/metadata/Metadata";
import { getStepData } from "components/question/questionSlice";
import DateInput from "components/date-input/DateInput";
import { getCurrentUserApplicationId } from "features/authSlice";
import CashInput from "components/ui/cash-input/CashInput";
import DisclaimerText from "components/ui/disclaimer-text/DisclaimerText";
import { capitalizeFirstLetter, sort } from "utilities/string";
import { SkipButton } from "pages/application/question/Question";

const actionName = "action";

interface FormData {
  action: string;
}

const SingleInput = () => {
  const stepData = useSelector(getStepData);
  const input = stepData.inputs[0];
  const {
    stepActionType,
    stepActionLabel,
    inputId,
    stepCode,
    name,
    stepActions,
    properties,
    stepActionMaxChar,
    inputs,
    metadata,
    disclaimer,
    skipAction,
    stepActionMaxDigits,
  } = {
    stepActionType: input.dataType,
    stepActionLabel: input.label,
    inputId: input.inputId,
    stepCode: stepData.stepCodeText,
    name: input.name,
    stepActions: stepData.nextStepActions,
    properties: input.properties,
    stepActionMaxChar: input.maxStringLength == 0 ? 200 : input.maxStringLength,
    stepActionMaxDigits: input.maxDigits,
    inputs: stepData.inputs,
    metadata: stepData.texts,
    disclaimer: stepData.disclaimer,
    skipAction: stepData.skipAction,
  };
  const [updateApplication] = useSetApplicationStepAnswerMutation({
    fixedCacheKey: "updateApplication",
  });

  const schema = z.object(
    inputs.reduce<Schema>((acc, { label, dataType }) => {
      if (dataType && Object.hasOwn(schemas, dataType)) {
        acc[actionName] = schemas[dataType];
      } else if (dataType === "currency") {
        acc[actionName] = schemas.currency;
      } else if (dataType === "cashPercentage") {
        acc[actionName] = z.preprocess(
          (a) => Number(a) || 0,
          z
            .number()
            .gt(0, "Please enter either a down payment amount or a percentage.")
            .lte(
              properties.purchasePropertyPrice!,
              "Down payment cannot be larger than Purchase Price."
            )
        );
      } else {
        acc[actionName] = z
          .string()
          .min(1, `Please enter your ${label.toLowerCase()}`);
      }
      return acc;
    }, {})
  );

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: inputs.reduce<Record<string, string>>(
      (acc, { initialValue }) => {
        acc[actionName] = initialValue;
        return acc;
      },
      {}
    ),
  });

  const getInput = (stepActionType?: string) => {
    switch (stepActionType) {
      case "cashPercentage":
        return (
          <Controller
            name={actionName}
            control={control}
            render={({ field: { onChange } }) => (
              <CashPercentage
                purchasePrice={properties.purchasePropertyPrice!}
                textLabel={stepActionLabel}
                onChange={onChange}
                errorMessage={errors.action?.message as string}
                initialValue={getValues(actionName)}
              />
            )}
          />
        );
      case "currency":
        return (
          <Controller
            name={actionName}
            control={control}
            render={({ field: { onChange } }) => (
              <CashInput
                onChange={onChange}
                label={stepActionLabel}
                errorMessage={errors.action?.message}
                initialValue={getValues(actionName)}
              />
            )}
          />
        );
      case "ssn":
        return (
          <Controller
            name={actionName}
            control={control}
            render={({ field: { onChange } }) => (
              <Ssn
                onChange={onChange}
                errorMessage={errors.action?.message as string}
                label={stepActionLabel}
                placeholder="xxx-xx-xxxx"
              />
            )}
          />
        );
      case "dateInput":
        return (
          <Controller
            name={actionName}
            control={control}
            render={({ field: { onChange } }) => (
              <DateInput
                onChange={onChange}
                placeholder="MM/YYYY"
                label={stepActionLabel}
                initialValue={getValues(actionName)}
                errorMessage={errors.action?.message as string}
              />
            )}
          />
        );
      case "rawInteger":
        return (
          <Controller
            name={actionName}
            control={control}
            render={({ field }) => (
              <FormInput
                {...field}
                dataType={stepActionType}
                formHandle={register(actionName)}
                inputProps={{
                  name: actionName,
                  placeholder: `Enter ${stepActionLabel}`,
                  label: stepActionLabel,
                  maxLength : stepActionMaxDigits,
                  type: "number",
                }}
              />
            )}
          />
        );
      case "textArea":
        return (
          <Controller
            name={actionName}
            control={control}
            render={({ field }) => {
              return (
                <TextAreaWrapper
                  {...field}
                  maxcharacter={stepActionMaxChar}
                  label={stepActionLabel}
                  errorMessage={errors.action?.message as string}
                />
              );
            }}
          />
        );
      case "state":
        return (
          <Controller
            name={actionName}
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={sort(properties.options!)}
                placeholder={`Select ${stepActionType}`}
                errorMessage={errors[actionName]?.message as string}
                label={capitalizeFirstLetter(stepActionType as string)}
                initialValue={getValues(actionName)}
              />
            )}
          />
        );

      case "select":
        if (properties.options) {
          return (
            <Controller
              name={actionName}
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={properties.options!}
                  placeholder={`Select ${stepActionLabel}`}
                  errorMessage={errors[actionName]?.message as string}
                  label={stepActionLabel}
                />
              )}
            />
          );
        } else {
          return (
            <FormInput
              formHandle={register(actionName)}
              inputProps={{
                name,
                placeholder: `Enter ${stepActionLabel}`,
                type: stepActionType,
                label: stepActionLabel,
                errorMessage: errors.action?.message as string,
              }}
            />
          );
        }
      default:
        return (
          <FormInput
            formHandle={register(actionName)}
            inputProps={{
              name: actionName,
              placeholder: `Enter ${stepActionLabel}`,
              type: stepActionType,
              label: stepActionLabel,
              errorMessage: errors.action?.message as string,
            }}
          />
        );
    }
  };

  const handleSkip = async () => {
    await updateApplication({
      id: applicationId,
      stepActionId: skipAction?.stepActionId,
      answeredInputs: [
        {
          inputId,
          name,
          submittedValue: "",
        },
      ],
    });
  };

  const [buttonId, setButtonId] = useState(0);
  const applicationId = useSelector(getCurrentUserApplicationId);
  return (
    <>
      {metadata.length > 0 && <Metadata metadata={metadata} />}
      <Form
        onSubmit={handleSubmit(async (values) => {
          await updateApplication({
            id: applicationId,
            stepActionId: stepActions[buttonId].stepActionId,
            answeredInputs: [
              {
                inputId,
                name,
                submittedValue: values.action.toString(),
              },
            ],
          });
          getFSQuestionCustomEvent(stepCode, stepActionLabel);
        })}
        formClassName="single-input"
      >
        {stepActions.length > 1 ? (
          <div>
            <div> {getInput(stepActionType)} </div>
            <div className="form__wrapper__additionalButton">
              {stepActions.map((action, index) => (
                <Button
                  key={action.presentationOrder}
                  title={action.label}
                  className={
                    errors.action
                      ? "single-input-button has-error"
                      : "single-input-button"
                  }
                  onClick={() => setButtonId(index)}
                />
              ))}
            </div>
          </div>
        ) : (
          <>
            {getInput(stepActionType)}
            <Button
              key={stepActions[0].presentationOrder}
              title={stepActions[0].label}
              className={
                errors.action
                  ? "single-input-button has-error"
                  : "single-input-button"
              }
              onClick={() => setButtonId(0)}
            />
          </>
        )}
        {skipAction && (
          <SkipButton skipAction={skipAction} skipActionHandler={handleSkip} />
        )}
      </Form>
      {disclaimer && (
        <DisclaimerText Header={disclaimer.header} Text={disclaimer.text} />
      )}
    </>
  );
};

export default SingleInput;

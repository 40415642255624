import Modal from "components/modal/Modal";
import { useEffect, useState } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import "./documents.scss";
import { Button } from "components/ui/button/Button";
import FileDetails, {
  FileUploadedBy,
} from "components/file-details/FilesDetails";
import Card from "components/card/Card";
import useDesktopChecker from "hooks/useDesktopChecker";
import FileUploader from "components/file-uploader/FileUploader";
import {
  RetrieveDocumentsResponseDto,
  dashboardApi,
  useGetDashboardItemsQuery,
  useRetrieveDocumentsQuery,
  useRetrieveDocumentsForDealApplicationQuery,
  useUpdateDocumentIdsMutation,
  useUpdateDocumentIdsForDealApplicationMutation,
} from "pages/dashboard/home/dashboardService";
import { format } from "date-fns";
import { useSelector, useDispatch } from "react-redux";
import {
  getCurrentUserName,
  getCurrentUserApplicationId,
  setLogout,
  getCurrentUserId,
  getDealApplicationId,
} from "features/authSlice";
import { useNavigate } from "react-router";
import Overlay from "components/ui/loading-spinners/Overlay/Overlay";
import { showDocuments } from "utilities/UserversionFeatures";
import { Funded } from "utilities/Constant";

export default function Documents() {
  const navigate = useNavigate();
  if (!showDocuments()) {
    navigate("/");
  }
  const userId = useSelector(getCurrentUserId);
  const { data } = useGetDashboardItemsQuery(userId);
  const loanStatus = data?.loanStatus?.status;
  const isDesktop = useDesktopChecker();
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [fileInfo, setFileInfo] = useState({ filePath: "", fileName: "" });
  const applicationId = useSelector(getCurrentUserApplicationId);
  const dealApplicationId = useSelector(getDealApplicationId);
  const [documents, setDocuments] = useState<RetrieveDocumentsResponseDto[]>(
    []
  );

  const {
    data: retrieveDocumentData,
    isLoading: isRetreiveDocumentLoading,
    isSuccess: isRetreiveDocumentSuccess,
  } = useRetrieveDocumentsQuery(applicationId, { skip: applicationId == "" });
  const {
    data: retrieveDocumentDealApplicationData,
    isLoading: isRetreiveDocumentDealAppLoading,
    isSuccess: isRetreiveDocumentDealAppSuccess,
  } = useRetrieveDocumentsForDealApplicationQuery(dealApplicationId, {
    skip: applicationId != "" || dealApplicationId == "",
  });

  useEffect(() => {
    if (
      applicationId &&
      !isRetreiveDocumentLoading &&
      isRetreiveDocumentSuccess
    ) {
      setDocuments(retrieveDocumentData);
    } else if (
      !applicationId &&
      dealApplicationId &&
      !isRetreiveDocumentDealAppLoading &&
      isRetreiveDocumentDealAppSuccess
    ) {
      setDocuments(retrieveDocumentDealApplicationData);
    }
  }, [
    applicationId,
    dealApplicationId,
    isRetreiveDocumentLoading,
    isRetreiveDocumentSuccess,
    isRetreiveDocumentDealAppLoading,
    isRetreiveDocumentDealAppSuccess,
    retrieveDocumentData,
    retrieveDocumentDealApplicationData,
  ]);

  const toggleModal = () => setShow(!show);
  const [
    invokeDocumentContent,
    { isLoading: isDocumentLoading, isFetching: isDocumentFetching },
  ] = dashboardApi.endpoints.retrieveDocumentContent.useLazyQuery();
  const handleDocumentViewer = (path: string, name: string) => {
    setFileInfo({ ...fileInfo, filePath: path, fileName: name });
    setShow(!show);
  };
  const [display, setDisplay] = useState(false);
  const userName = useSelector(getCurrentUserName);
  const dispatch = useDispatch();
  const [UpdateDocumentIdsWithApplication] = useUpdateDocumentIdsMutation();
  const [UpdateDocumentIdsForDealApplication] =
    useUpdateDocumentIdsForDealApplicationMutation();
  const handleHide = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.type === "click" || event.type === "mousedown") {
      setDisplay(!display);
    }
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      setDisplay(!display);
    }
  };

  const handleByAction = async () => {
    if (display) {
      await UpdateDocumentsIds();
      setDisplay(!display);
    }
    setShowModal(!showModal);
  };
  const UpdateDocumentsIds = async () => {
    try {
      if (applicationId) {
        await UpdateDocumentIdsWithApplication(applicationId);
      } else if (dealApplicationId) {
        await UpdateDocumentIdsForDealApplication(dealApplicationId);
      }
    } catch (error) {
      console.error(`Error updating document id's`, error);
    }
  };
  const handleClick = () => setDisplay(!display);
  const docs = [{ uri: fileInfo.filePath, fileName: fileInfo.fileName }];
  const [fileUrl, setFileUrl] = useState<string>("");

  const getBlob = async (document: RetrieveDocumentsResponseDto) => {
    try {
      const { data, isError, error } = await invokeDocumentContent(
        document.fileId
      );
      if (
        isError &&
        typeof error === "object" &&
        "status" in error &&
        error.status === 401
      ) {
        dispatch(setLogout());
        navigate("/", { replace: true, state: { path: location.pathname } });
        return "";
      } else {
        if (data) {
          const blob = new Blob(
            [
              Uint8Array.from(window.atob(data.fileContents), (c) =>
                c.charCodeAt(0)
              ),
            ],
            { type: data.contentType }
          );

          const url = window.URL.createObjectURL(blob);
          setFileUrl(url); // Store the URL in state variable
          return url;
        } else {
          return "";
        }
      }
    } catch (error) {
      return "";
    }
  };

  if (isRetreiveDocumentLoading || isRetreiveDocumentDealAppLoading) {
    return <Overlay />;
  }

  if (isDocumentLoading || isDocumentFetching) {
    return <Overlay />;
  }

  return (
    <div className="documents-container">
      {isDesktop ? (
        <>
          {documents && documents.length == 0 && (
            <div className="documents-container__content">
              <p className="documents-container__content--text2">
                You have not uploaded any items. Feel free to return to the main
                dashboard to upload required documentation or feel free to
                upload additional items here.
              </p>
            </div>
          )}
          <div className="documents-container__header">
            <p className="documents-container__title">Uploaded Documents</p>
            <div
              className="documents-container__file-upload"
              onClick={handleHide}
              onMouseDown={handleHide}
              onKeyDown={handleKeyDown}
            >
              {loanStatus !== Funded && (
                <Button
                  title="Upload your file"
                  icon={regular("cloud-arrow-up")}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <div>
          <div
            className="documents-container__file-upload"
            onClick={handleHide}
            onMouseDown={handleHide}
            onKeyDown={handleKeyDown}
          >
            {loanStatus !== Funded && (
              <Button
                title="Upload your file"
                icon={regular("cloud-arrow-up")}
              />
            )}
          </div>
          <p className="documents-container__title">Uploaded Documents</p>
        </div>
      )}

      {display && (
        <Modal
          customBody={
            <FileUploader
              handleByAction={handleByAction}
              onClick={handleClick}
              operationType="UploadDocuments"
              conditionId=""
            />
          }
          defaultHeader={false}
          defaultBody={false}
        />
      )}
      {showModal && <SuccessMessageModal handleByAction={handleByAction} />}
      <div>
        {documents && documents.length > 0 ? (
          <div className="documents-container__cards">
            {documents.map((document) => {
              return (
                <div
                  className="documents-container__card"
                  key={document.fileId}
                  onClick={async () => {
                    handleDocumentViewer(
                      await getBlob(document),
                      document.name
                    );
                  }}
                  onKeyDown={async (event) => {
                    if (event.key === "Enter" || event.key === " ") {
                      handleDocumentViewer(
                        await getBlob(document),
                        document.name
                      );
                    }
                  }}
                >
                  <Card
                    body={<FileDetails fileName={document.name} />}
                    footer={
                      <FileUploadedBy
                        uploadedby={userName}
                        createdDate={format(
                          new Date(document.dateCreated),
                          "MM/dd/yyyy  hh:mma"
                        )}
                      />
                    }
                  />
                </div>
              );
            })}
          </div>
        ) : (
          !isDesktop && (
            <div className="documents-container__content">
              <p className="documents-container__content--text2">
                You have not uploaded any items. Feel free to return to the main
                dashboard or upload additional items here.
              </p>
            </div>
          )
        )}
      </div>
      {show && (
        <Modal
          defaultHeader
          defaultHeaderIcon={solid("pen-nib")}
          defaultHeaderName={fileInfo.fileName}
          defaultHandleHide={toggleModal}
          defaultBody={false}
          footerContentPosition="right"
          customBody={
            <>
              {fileInfo.filePath ? (
                <DocViewer
                  documents={docs}
                  pluginRenderers={DocViewerRenderers}
                />
              ) : (
                <h1>Oops! There's a problem viewing this document.</h1>
              )}
            </>
          }
          footer={
            <>
              <Button
                title={"Close"}
                type={"round"}
                variant={"outline-primary"}
                onClick={toggleModal}
              />
              <a
                href={fileUrl}
                download={fileInfo.fileName}
                target="_blank"
                rel="noreferrer"
              >
                <Button title={"Download"} type={"round"} variant={"primary"} />
              </a>
            </>
          }
        />
      )}
    </div>
  );
}

interface SuccessMessageModalProps {
  handleByAction: () => void;
}

export const SuccessMessageModal = ({
  handleByAction,
}: SuccessMessageModalProps) => {
  const handleClick = () => {
    handleByAction();
    if (location.pathname === "/dashboard/documents") {
      window.location.reload();
    }
  };
  return (
    <div className="success-message__container">
      <Modal
        defaultBody={false}
        defaultHeader
        defaultHandleHide={handleByAction}
        defaultHeaderName="Upload Files"
        defaultHeaderIcon={solid("cloud-arrow-up")}
        customBody={
          <div>
            <p className="message__primary">
              Your files have been successfully uploaded!
            </p>
            <p className="message__secondary">
              You can view uploaded documents in the Documents tab
            </p>
            <Button title="Return To Dashboard" onClick={handleClick} />
          </div>
        }
      />
    </div>
  );
};

import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Button } from "components/ui/button/Button";
import useDesktopChecker from "hooks/useDesktopChecker";
import newAmericanFundingImg from "assets/images/newamerican-funding-logo-white.svg";
import experienceLogo from "assets/images/Experience-com-logo-white.png";
import {
  LoanOfficerDetail,
  useGetLoanOfficerRatingsQuery,
} from "pages/application/applicationService";
import "./loan-officer-highlight.scss";
import { NumericFormat } from "react-number-format";
import {
  AGREEMENTLINK,
  PRIVACYLINK,
  TERMSOFUSELINK,
  PARTNERSLINK,
  FOOTER_CONTACT_US,
  NMLSLINK,
  STATELICENSELINK,
} from "utilities/Constant";
import { useSelector } from "react-redux";
import { getUserId } from "features/authSlice";
import { useState } from "react";

const openInNewTab = (url: string) => () => window.open(url, "_blank");

const isPartialReviewStar = (averageRating: number, index: number) =>
  averageRating - (index - 1) > 0 && averageRating - (index - 1) < 1;

const isFullReviewStar = (averageRating: number, index: number) =>
  index <= Math.trunc(averageRating);

const pluralize = (word: string, count: number) =>
  count === 1 ? word : `${word}s`;

const ReviewStar = ({
  index,
  averageRating,
}: {
  index: number;
  averageRating: number;
}) => {
  if (isPartialReviewStar(averageRating, index)) {
    return <FontAwesomeIcon icon={solid("star-half-alt")} color="yellow" />;
  } else if (isFullReviewStar(averageRating, index)) {
    return <FontAwesomeIcon icon={solid("star")} color="yellow" />;
  }
  return <FontAwesomeIcon icon={solid("star")} color="gray" />;
};
const reviewStars = (averageRating: number) =>
  [1, 2, 3, 4, 5].map((starNumber) => (
    <ReviewStar
      key={starNumber}
      index={starNumber}
      averageRating={averageRating}
    />
  ));
const LoanOfficerHighlight = () => {
  const isDesktop = useDesktopChecker();
  const userId = useSelector(getUserId);

  const { data: loanOfficer } = useGetLoanOfficerRatingsQuery(userId);

  return (
    <>
      {loanOfficer?.loanOfficerDetail ? (
        <DefaultLoanOfficerHighlight
          isDesktop={isDesktop}
          {...loanOfficer.loanOfficerDetail}
        />
      ) : (
        <NoLoanOfficerAssigned
          isDesktop={isDesktop}
          {...loanOfficer?.overallRating}
        />
      )}
    </>
  );
};

const Reviews = ({
  reviewStars,
  isDesktop,
  averageRating,
  reviewCount,
  readMoreReviewsLink,
  buttonClassName = "",
}: {
  reviewStars: JSX.Element[];
  averageRating: number;
  reviewCount: number;
  isDesktop: boolean;
  readMoreReviewsLink?: string;
  buttonClassName?: string;
}) => (
  <div className="reviews">
    <div className="reviews__ratings">
      <p>
        <span className="reviews__stars">{reviewStars}</span>
        {averageRating}
        {"/5 "}
        <span className="rating-text">rating</span> {"based on  "}
        <NumericFormat
          value={reviewCount}
          displayType={"text"}
          thousandSeparator
        />
        {pluralize(" Review", reviewCount)}
      </p>
    </div>
    {reviewCount > 0 && readMoreReviewsLink ? (
      <Button
        title={`Read More ${pluralize("Review", reviewCount)}`}
        variant="white"
        size="small"
        onClick={openInNewTab(readMoreReviewsLink)}
        className={buttonClassName}
      />
    ) : (
      <div className="no-review-button" />
    )}
  </div>
);

interface LoanOfficerHighlightProps {
  fullName: string;
  nmlsLicenseNumber: string;
  email: string;
  phones: {
    contactID: number;
    type: string;
    number: string;
    extension: string;
  }[];
  photoImageUrl: string;
  hasRatingInfo: boolean;
  averageRating: number;
  reviewCount: number;
  readMoreReviewsLink: string;
  isDesktop: boolean;
  workEmail: string;
}

export const DefaultLoanOfficerHighlight = ({
  fullName,
  nmlsLicenseNumber,
  photoImageUrl,
  isDesktop,
  hasRatingInfo,
  workEmail,
  phones,
  averageRating,
  reviewCount,
  readMoreReviewsLink,
}: LoanOfficerHighlightProps) => {
  const loanOfficerPhoneNumbers = (phones: LoanOfficerDetail["phones"]) => {
    return phones.map((phone) => {
      const formattedNumber = phone.number.replace(
        /(\d{3})(\d{3})(\d{4})/,
        "($1)-$2-$3 "
      );
      return formattedNumber;
    });
  };

  const handleEmailClick = () => {
    const mailtoLink = `mailto:${workEmail}`;
    window.location.href = mailtoLink;
  };

  const handlePhoneClick = () => {
    if (phones.length > 0) {
      const { number } = phones[0];
      if (number) {
        window.location.href = `tel:${number}`;
      }
    }
  };
  const [isImageLoaded, setIsImageLoaded] = useState(true);
  const handleImageError = () => {
    setIsImageLoaded(false);
  };

  return (
    <div className="footerContainer">
      <section
        className={`footer--info loan-officer-highlight lo-view ${
          !isDesktop ? "lo-mobile-view" : ""
        }`}
      >
        <div className="corp-details">
          <div className="logo-and-rating">
            <img
              className="newamerican-funding-logo"
              src={newAmericanFundingImg}
              alt="logo"
            />

            {hasRatingInfo && (
              <div className="rating">
                <img
                  className="rating-company-logo"
                  src={experienceLogo}
                  alt="logo"
                />
                <p className="overall-text">Overall Rating</p>
                <Reviews
                  isDesktop={isDesktop}
                  reviewStars={reviewStars(averageRating)}
                  averageRating={averageRating}
                  reviewCount={reviewCount}
                  readMoreReviewsLink={readMoreReviewsLink}
                  buttonClassName="loan-officer-button"
                />
              </div>
            )}
          </div>
          <p className="footer-links">
            <a href={NMLSLINK} target="_blank" rel="noreferrer">
              NMLS ID#6606
            </a>{" "}
            |{" "}
            <a href={STATELICENSELINK} target="_blank" rel="noreferrer">
              State Licensing
            </a>{" "}
            |{" "}
            <a href={TERMSOFUSELINK} target="_blank" rel="noreferrer">
              Terms of Use
            </a>{" "}
            |{" "}
            <a href={PRIVACYLINK} target="_blank" rel="noreferrer">
              Privacy
            </a>{" "}
            |{" "}
            <a href={AGREEMENTLINK} target="_blank" rel="noreferrer">
              Electronic Consent
            </a>{" "}
            |{" "}
            <a href={PARTNERSLINK} target="_blank" rel="noreferrer">
              Partners
            </a>
          </p>
        </div>
        <div className="personal-details">
          {photoImageUrl && isImageLoaded ? (
            <img
              src={photoImageUrl}
              alt={`${fullName} Loan Officer Portrait`}
              className="personal-details__portrait"
              onError={handleImageError}
            />
          ) : (
            <span className="personal-details__portrait no-image-url">
              <FontAwesomeIcon
                className="no-image-url__icon"
                icon={solid("user")}
              />
            </span>
          )}
          <div className="personal-details__information">
            <h3 className="personal-details__name">{fullName}</h3>
            <p>Loan Officer</p>
            <p className="nmls">NMLS# {nmlsLicenseNumber}</p>

            {isDesktop ? (
              <div className="personal-details__contact-info">
                <p>{workEmail}</p>
                <p>{loanOfficerPhoneNumbers(phones)}</p>
              </div>
            ) : (
              <div className="personal-details__contact-info">
                <span>
                  <FontAwesomeIcon
                    onClick={handleEmailClick}
                    className="icon-envelope"
                    icon={solid("envelope")}
                  />
                </span>
                <span>
                  {" "}
                  <FontAwesomeIcon
                    onClick={handlePhoneClick}
                    className="icon-phone"
                    icon={solid("phone-flip")}
                  />
                </span>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export const NoLoanOfficerAssigned = ({
  isDesktop,
  averageRating,
  reviewCount,
}: {
  isDesktop: boolean;
  averageRating?: number;
  reviewCount?: number;
}) => {
  const isDocument = location.pathname === "/dashboard/documents";
  return (
    <div className="footerContainer">
      <section className={isDocument ? "document-footer--info no-lo-section" : "footer--info no-lo-section"}>
        <div className="no-loan-officer">
          <div className="no-loan-officer--info">
            <img
              className="newamerican-funding-logo"
              src={newAmericanFundingImg}
              alt="logo"
            />
            {isDesktop ? (
              <p className="contact">Contact Us: {FOOTER_CONTACT_US}</p>
            ) : (
              <Button
                title="CONTACT US"
                variant="white"
                className="btn-contact"
                onClick={() => {
                  window.location.href = `tel:${FOOTER_CONTACT_US}`;
                }}
              />
            )}
          </div>
          {averageRating && reviewCount && (
            <div className="rating">
              <img
                className="rating-company-logo"
                src={experienceLogo}
                alt="logo"
              />
              <p>Overall Rating</p>
              <Reviews
                isDesktop={false}
                reviewStars={reviewStars(averageRating)}
                averageRating={averageRating}
                reviewCount={reviewCount}
              />
            </div>
          )}
        </div>
        <p className="links">
          <a href={NMLSLINK} target="_blank" rel="noreferrer">
            NMLS ID#6606
          </a>{" "}
          |{" "}
          <a href={STATELICENSELINK} target="_blank" rel="noreferrer">
            State Licensing
          </a>{" "}
          |{" "}
          <a href={TERMSOFUSELINK} target="_blank" rel="noreferrer">
            Terms of Use
          </a>{" "}
          |{" "}
          <a href={PRIVACYLINK} target="_blank" rel="noreferrer">
            Privacy
          </a>{" "}
          |{" "}
          <a href={AGREEMENTLINK} target="_blank" rel="noreferrer">
            Electronic Consent
          </a>{" "}
          |{" "}
          <a href={PARTNERSLINK} target="_blank" rel="noreferrer">
            Partners
          </a>
        </p>
      </section>
    </div>
  );
};

export default LoanOfficerHighlight;

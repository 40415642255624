import { UseFormRegisterReturn } from "react-hook-form";
import { Input, InputProps } from "components/ui/input/Input";

/**
 * Wrapper component to encapsulate React hook Form handle logic with primitive input component
 */

interface FormInputProps {
  /**Form handle returned from register function in React Hook Form */
  formHandle: UseFormRegisterReturn;
  /**Input props required for primitive input component */
  inputProps: InputProps;
  dataType?: string;
}

export const FormInput = ({
  formHandle,
  inputProps,
  dataType,
}: FormInputProps) => {
  const { onChange: formHandleOnChange, onBlur: formHandleOnBlur } = formHandle;

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    await formHandleOnChange(e);
    inputProps.onChange?.(e);
  };

  const handleInputBlur = async (e: React.ChangeEvent<HTMLInputElement>) => {
    await formHandleOnBlur(e);
    inputProps.onBlur?.(e);
  };

  return (
    <Input
      dataType={dataType}
      //userdefined props
      {...inputProps}
      //formhandle props
      ref={formHandle.ref}
      name={formHandle.name as unknown as string}
      onChange={handleInputChange} 
      onBlur={handleInputBlur}
    />
  );
};

import "./checkbox.scss";

interface IcheckBoxProps {
  label?: string;
  hideCheckbox?: "hide" | "show";
  onChange?: (...event: any[]) => void;
  checkboxState?: boolean;
  disabled?: boolean;
}
const Checkbox = ({
  label,
  hideCheckbox = "show",
  onChange,
  checkboxState = false,
  disabled = false,
}: IcheckBoxProps) => {
  const handleChange = (value: boolean) => {
    onChange?.(value);
  };
  const checkboxClass = `checkbox_wrapper ${hideCheckbox} ${
    disabled ? "disabled" : ""
  }`;
  return (
    <div className={checkboxClass}>
      <input
        id={label}
        type="checkbox"
        aria-label={label}
        checked={checkboxState}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChange(e.target.checked)
        }
        disabled={disabled}
      />
      <label htmlFor={label}>{label}</label>
    </div>
  );
};

export default Checkbox;

import { PrivacyPolicy } from "components/privacy-policy/PrivacyPolicy";
import * as Constants from "utilities/Constant";
import "./consent.scss";

export interface ConsentProps {
  partnersLink?: string;
  termsOfUseLink: string;
  privacyLink: string;
  agreementLink: string;
}

export const Consent = ({ privacyLink }: ConsentProps) => {
  return (
    <div className="consent">
      <div className="consent__wrapper">
        <p className="consent--item">
          {Constants.Legal_Information_One}
          <a href={Constants.PRIVACYLINK} target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
          {Constants.Legal_Information_Two}
          <a href={Constants.PARTNERSLINK} target="_blank" rel="noreferrer">
            partners
          </a>
          {Constants.Legal_Information_Three}
          <a href={Constants.PARTNERSLINK} target="_blank" rel="noreferrer">
            partners
          </a>
          {Constants.Legal_Information_Four}
          <a href={Constants.TERMSOFUSELINK} target="_blank" rel="noreferrer">
            Terms of Use,
          </a> {" "}
          and {" "}
          <a href={Constants.AGREEMENTLINK} target="_blank" rel="noreferrer">
            Electronic Econsent Agreement
          </a>
        </p>
      </div>
      <div className="consent__reader">
        <div className="consent__reader--wrapper">
          {Constants.Assistant_Note}
        </div>
        <PrivacyPolicy privacyLink={privacyLink} />
      </div>
    </div>
  );
};

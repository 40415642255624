import { Outlet } from "react-router";
import {
  getCurrentAccessToken,
} from "./authSlice";
import { useSelector } from "react-redux";
import AuthRouting from "components/auth-routing/AuthRouting";

export default function PublicRoute() {

  const token = useSelector(getCurrentAccessToken);
  return <>{token ? <AuthRouting /> : <Outlet />}</>;
}

import React, { useCallback, useState } from "react";
import MaxCharacter from "./MaxCharacter";
import TextArea from "./TextArea";
import"./text-area-wrapper.scss";

export interface TextAreaWrapperProps {
  name: string;
  label?: string;
  maxcharacter: number;
  errorMessage?: string;
}

const TextAreaWrapper = ({
  name,
  maxcharacter,
  label,
  errorMessage,
}: TextAreaWrapperProps) => {
  const [content, setContent] = useState("");
  const setFormattedContent = useCallback(
    (value: string) => {
      setContent(value.slice(0, maxcharacter));
    },
    [maxcharacter, setContent]
  );
  return (
    <div className="textarea-wrapper">
      <TextArea
        maxLength={maxcharacter}
        name={name}
        label={label}
        errorMessage={errorMessage}
        onChange={(e) => setFormattedContent(e.currentTarget.value)}
      />
      <div className="textarea-wrapper--maxcharacter">
        <MaxCharacter limit={maxcharacter} contentLength={content.length} />
      </div>
    </div>
  );
};

export default TextAreaWrapper;

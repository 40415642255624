import {
  StepResponse,
  useGetApplicationByIdQuery,
  FieldResponse,
  BlockType,
} from "pages/application/applicationService";
import { useSelector } from "react-redux";
import { getCurrentUserApplicationId } from "features/authSlice";

export const useGetAcknowledgementsQuery = () => {
  const applicationId = useSelector(getCurrentUserApplicationId);
  return useGetApplicationByIdQuery(applicationId, {
    selectFromResult: ({ data }) => {
      const hasSpouseCoApplicant = data!
        .blocks!.find(
          ({ blockType }) => blockType === "acknowledgementsDemographics"
        )!
        .dataItems.some(
          ({ dataItemType }) => dataItemType === "coBorrowerDemographics"
        )!;
      const next = data!.nextStepActions.find(
        ({ actionType }) => actionType === "next"
      )!;

      return {
        hasSpouseCoApplicant,
        next,
      };
    },
  });
};
export const useGetApplicantQuery = () => {
  const applicationId = useSelector(getCurrentUserApplicationId);
  return useGetApplicationByIdQuery(applicationId, {
    selectFromResult: ({ data }) =>
      getBaseApplicantData(
        data,
        "acknowledgementsDemographics",
        "borrowerDemographics"
      ),
  });
};
export const useGetSpouseCoApplicantQuery = () => {
  const applicationId = useSelector(getCurrentUserApplicationId);
  return useGetApplicationByIdQuery(applicationId, {
    selectFromResult: ({ data }) =>
      getBaseApplicantData(
        data,
        "acknowledgementsDemographics",
        "coBorrowerDemographics"
      ),
  });
};

const getBaseApplicantData = (
  data: StepResponse | undefined,
  blockType: "acknowledgementsDemographics",
  dataItemType: "borrowerDemographics" | "coBorrowerDemographics"
) => {
  const { dataItems } = data!.blocks!.find(
    ({ blockType: thisBlockType }) => thisBlockType === blockType
  )!;

  const {
    label: name,
    fields,
    actions,
  } = dataItems.find(
    ({ dataItemType: thisDataItemType }) => thisDataItemType === dataItemType
  )!;

  const demographics = {
    ethnicityLabel: fields[0].displayName,
    ethnicity: fields[0].value,
    raceLabel: fields[1].displayName,
    race: fields[1].value,
    genderLabel: fields[2].displayName,
    gender: fields[2].value,
    editDetails: actions[0],
  };

  return {
    name,
    demographics,
  };
};

export const useGetDeclarationsQuery = () => {
  const applicationId = useSelector(getCurrentUserApplicationId);
  return useGetApplicationByIdQuery(applicationId, {
    selectFromResult: ({ data }) => {
      if (!hasBlock(data, "acknowledgementsDeclarations")) {
        return {
          hasSpouseCoApplicantDeclarations: false,
          spouseCoApplicantDeclarations: undefined,
          spouseCoApplicantName: undefined,
          hasApplicantDeclarations: false,
          applicantDeclarations: undefined,
          applicantName: undefined,
        };
      }
      let hasSpouseCoApplicantDeclarations = data!
        .blocks!.find(
          ({ blockType }) => blockType === "acknowledgementsDeclarations"
        )!
        .dataItems.some(
          ({ dataItemType }) => dataItemType === "coBorrowerDeclarations"
        )!;
      let spouseCoApplicantDeclarations: FieldResponse[] | undefined;
      let spouseCoApplicantName: string | undefined;
      if (hasSpouseCoApplicantDeclarations) {
        const { dataItems } = data!.blocks!.find(
          ({ blockType }) => blockType === "acknowledgementsDeclarations"
        )!;

        const { label: name, fields } = dataItems.find(
          ({ dataItemType }) => dataItemType === "coBorrowerDeclarations"
        )!;

        spouseCoApplicantDeclarations =
          fields.length != 0 ? [...fields] : undefined;
        spouseCoApplicantName = name;
        hasSpouseCoApplicantDeclarations = !!spouseCoApplicantDeclarations;
      } else {
        spouseCoApplicantDeclarations = undefined;
        spouseCoApplicantName = undefined;
      }

      let hasApplicantDeclarations = data!
        .blocks!.find(
          ({ blockType }) => blockType === "acknowledgementsDeclarations"
        )!
        .dataItems.some(
          ({ dataItemType }) => dataItemType === "borrowerDeclarations"
        )!;
      let applicantDeclarations: FieldResponse[] | undefined;
      let applicantName: string | undefined;
      if (hasApplicantDeclarations) {
        const { dataItems } = data!.blocks!.find(
          ({ blockType }) => blockType === "acknowledgementsDeclarations"
        )!;

        const { label: name, fields } = dataItems.find(
          ({ dataItemType }) => dataItemType === "borrowerDeclarations"
        )!;

        applicantDeclarations = fields.length != 0 ? [...fields] : undefined;
        applicantName = name;
        hasApplicantDeclarations = !!applicantDeclarations;
      } else {
        applicantDeclarations = undefined;
        applicantName = undefined;
      }

      return {
        hasSpouseCoApplicantDeclarations,
        spouseCoApplicantDeclarations,
        spouseCoApplicantName,
        hasApplicantDeclarations,
        applicantDeclarations,
        applicantName,
      };
    },
  });
};
export const hasBlock = (
  data: StepResponse | undefined,
  blockType: BlockType
) =>
  data?.blocks?.find(
    ({ blockType: thisBlockType }) => thisBlockType === blockType
  ) !== undefined;

import { store } from "store";

// Define an enum for UserVersion
enum UserVersion {
  VERSION_1 = "VERSION1",
  VERSION_2 = "VERSION2",
  VERSION_3 = "VERSION3",
  VERSION_4 = "VERSION4",
  // Add more versions if needed
}

// Define an enum for PathType
enum PathType {
  LIGHTNINGPATH = 'LightningPath',
  LIGHTNINGPATHV2 = 'LightningPathV2',
  LIGHTNINGPATHV3 = 'LightningPathV3'
}

// Define an enum for features
enum UserFeature {
  SHOW_DASHBOARD = "SHOW_DASHBOARD",
  SHOW_THANKYOU_PAGE = "SHOW_THANKYOU_PAGE ",
  SHOW_DOCUMENTS = "SHOW_DOCUMENTS",
  SHOW_INTERIM_DASHBOARD = " SHOW_INTERIM_DASHBOARD",
  SHOW_CAMERA = "SHOW_CAMERA"
}
//Dictionary to map UserVersion to features
const userVersionFeatureMap: Record<UserVersion, UserFeature[]> = {
  [UserVersion.VERSION_1]: [UserFeature.SHOW_THANKYOU_PAGE],
  [UserVersion.VERSION_2]: [UserFeature.SHOW_DASHBOARD, UserFeature.SHOW_DOCUMENTS],
  [UserVersion.VERSION_3]: [UserFeature.SHOW_DASHBOARD, UserFeature.SHOW_DOCUMENTS, UserFeature.SHOW_INTERIM_DASHBOARD],
  [UserVersion.VERSION_4]: [UserFeature.SHOW_DASHBOARD, UserFeature.SHOW_DOCUMENTS, UserFeature.SHOW_INTERIM_DASHBOARD]
};

// Function to check if the given feature is available in the current user version
const isFeatureAvailable = (feature: UserFeature): boolean => {
  const version = store.getState().auth.version;
  const versionFeatures = userVersionFeatureMap[version.toUpperCase() as UserVersion];
  return versionFeatures?.includes(feature);
};

const showDocuments = (): boolean => isFeatureAvailable(UserFeature.SHOW_DOCUMENTS);
const showCamera = (): boolean => isFeatureAvailable(UserFeature.SHOW_CAMERA);

export {
  userVersionFeatureMap,
  UserVersion,
  UserFeature,
  showDocuments,
  showCamera,
  PathType,
};

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { BASE_BFF_WEB_URL } from "utilities/Constant";

export interface MfaRequest {
  id: string;
  flowId: string;
}

export interface MfaResponse{
  id :string;
}

export const mfaApi = createApi({
  reducerPath: "mfaApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_BFF_WEB_URL }),
  endpoints: (build) => ({
    postMfa: build.mutation<MfaResponse, MfaRequest>({
      query: (body: MfaRequest) => ({
        url: "/Account/Mfa",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { usePostMfaMutation } = mfaApi;
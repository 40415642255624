import { useCallback, useState, useEffect } from "react";

import CurrencyInput from "react-currency-input-field";
import "assets/styles/layout/_cashfield.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { formatDollarAmount } from "utilities/string";
import InPlaceError from "components/in-place-error/InPlaceError";
import ToolTip from "components/ui/tooltip/ToolTip";

export interface CashPercentageProps {
  textLabel?: string;
  purchasePrice: number;
  onChange?: (amount: string) => void;
  initialValue?: string;
  errorMessage?: string;
}

const roundToTwoDecimals = (num: number) => Number(num.toFixed(2));

const CashPercentage = ({
  textLabel,
  purchasePrice,
  onChange,
  errorMessage,
  initialValue,
}: CashPercentageProps) => {
  const [amount, setAmount] = useState<number>();
  const [percentage, setPercentage] = useState<number | undefined>();
  const [loanAmount, setLoanAmount] = useState<number | undefined>();

  const handleAmountChange = useCallback(
    (amount: number) => {
      if (amount <= purchasePrice) {
        setAmount(amount);
        setPercentage(roundToTwoDecimals((amount / purchasePrice) * 100));
        setLoanAmount(roundToTwoDecimals(purchasePrice - amount));
      } else {
        setAmount(amount);
        setPercentage(undefined);
        setLoanAmount(undefined);
      }
    },
    [purchasePrice]
  );

  const handlePercentageChange = useCallback(
    (percentage: number) => {
      if (percentage <= 100) {
        setPercentage(percentage);
        const amount = roundToTwoDecimals((percentage / 100) * purchasePrice);
        setAmount(amount);
        setLoanAmount(roundToTwoDecimals(purchasePrice - amount));
      }
    },
    [purchasePrice]
  );

  useEffect(() => {
    if (initialValue) {
      handleAmountChange(Number(initialValue));
    }
  }, []);

  useEffect(() => {
    onChange?.(amount?.toString() ?? "");
  }, [amount]);

  return (
    <div className="currency--field">
      <label className="currency--field__label">
        {textLabel ?? "Enter Amount OR Percentage"}
        <div className="currency--field__label__info-icon">
          <ToolTip content="You can enter either value" position="up" />
          <FontAwesomeIcon icon={solid("info-circle")} className="icon" />
        </div>
      </label>
      <div className="currency">
        <CurrencyInput
          className="currency--amount"
          id="input-amount"
          name="input-amount"
          prefix="$"
          placeholder="$"
          value={amount}
          maxLength={20}
          aria-label="$"
          onValueChange={(amt) => handleAmountChange(Number(amt) || 0)}
          data-testid="input-amount"
        />
        <span></span>
        <CurrencyInput
          className="currency--percentage"
          id="input-percentage"
          name="input-percentage"
          suffix="%"
          placeholder="%"
          maxLength={4}
          decimalsLimit={2}
          value={percentage}
          aria-label="%"
          onValueChange={(percentage) =>
            handlePercentageChange(Number(percentage) || 0)
          }
          data-testid="input-percentage"
        />
      </div>

      <AmountLineItems purchasePrice={purchasePrice} loanAmount={loanAmount} />
      {errorMessage && <InPlaceError errorPhrase={errorMessage} />}
    </div>
  );
};

export default CashPercentage;

export interface AmountLineItemsProps {
  loanAmount?: number;
  purchasePrice: number;
}

export const AmountLineItems = ({
  purchasePrice,
  loanAmount,
}: AmountLineItemsProps) => {
  return (
    <div className="amount-line-items">
      <div className="amount-line-item">
        <p>Purchase Price</p>
        <label>{formatDollarAmount(purchasePrice.toString())}</label>
      </div>
      <div className="amount-line-item">
        <p>Loan Amount</p>
        {loanAmount !== undefined && (
          <label>{formatDollarAmount(loanAmount.toString())}</label>
        )}
      </div>
    </div>
  );
};

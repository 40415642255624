import {
  StepResponse,
  BlockType,
  useGetApplicationByIdQuery,
} from "pages/application/applicationService";
import { useSelector } from "react-redux";
import { getCurrentUserApplicationId } from "features/authSlice";

export const useGetBlocksQuery = ({
  spouseCoApplicantBlockName,
  assetsBlockName = "financesAssets",
  nonSpouseCoApplicantBlockName = "profileNonSpouseCoApplicant",
}: {
  spouseCoApplicantBlockName: BlockType;
  nonSpouseCoApplicantBlockName?: "profileNonSpouseCoApplicant";
  assetsBlockName?: "financesAssets";
}) => {
  const applicationId = useSelector(getCurrentUserApplicationId);
  return useGetApplicationByIdQuery(applicationId, {
    selectFromResult: ({ data }) => ({
      hasSpouseCoApplicant: hasBlock(data, spouseCoApplicantBlockName),
      hasNonEmptySpouseCoApplicant:
        hasBlock(data, spouseCoApplicantBlockName) &&
        hasDataItems(data, spouseCoApplicantBlockName),
      hasNonSpouseCoApplicant: hasBlock(data, nonSpouseCoApplicantBlockName),
      hasAssets: hasBlock(data, assetsBlockName),
      next: data!.nextStepActions.find(
        ({ actionType }) => actionType === "next"
      )!,
    }),
  });
};

const hasDataItems = (data: StepResponse | undefined, blockType: BlockType) =>
  data?.blocks?.find(
    ({ blockType: thisBlockType }) => thisBlockType === blockType
  )?.dataItems.length !== 0;

export const hasBlock = (
  data: StepResponse | undefined,
  blockType: BlockType
) =>
  data?.blocks?.find(
    ({ blockType: thisBlockType }) => thisBlockType === blockType
  ) !== undefined;

export const getBlockType = (
  data: StepResponse | undefined,
  blockType: BlockType
) =>
  data!.blocks!.find(
    ({ blockType: thisBlockType }) => thisBlockType === blockType
  )!;

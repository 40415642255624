import WelcomePopup from "pages/welcome-popUp/WelcomePopUp";
import "./dashboard-popup.scss";
interface DashboardPopUpProps {
  readonly onClose: () => void;
  readonly userName?: string;
}
export default function DashboardPopUp({
  onClose,
  userName,
}: DashboardPopUpProps) {
  return (
    <div className="pop-up">
      <div className="pop-up__content">
        <div className="pop-up__content1">
          <WelcomePopup onClose={onClose} userName={userName} />
        </div>
      </div>
    </div>
  );
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Popup from "../popup/Popup";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setLogout } from "features/authSlice";
import { useNavigate } from "react-router-dom";

import "./logout.scss";
import useDesktopChecker from "hooks/useDesktopChecker";

export default function Logout({ name }: { name?: string }) {
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPopUp, setShowPopUp] = useState(false);

  const handleChange = () => {
    setChecked(!checked);
    setShowPopUp(!showPopUp);
  };
  const onClickContinue = () => {
    setChecked(false);
    setShowPopUp(!showPopUp);
  };
  const onClickLogout = () => {
    dispatch(setLogout());
    navigate("/");
  };
  const isDashboard =
    location.pathname === "/dashboard" ||
    location.pathname === "/dashboard/documents";
  const isDesktop = useDesktopChecker();
  return (
    <>
      {isDesktop ? (
        <div className="logout" onClick={() => handleChange()}>
          <span className="logout__description">{name}</span>
          <span className="logout__icon">
            <FontAwesomeIcon icon={solid("right-from-bracket")} />
          </span>
        </div>
      ) : (
        <div className="logout" onClick={() => handleChange()}>
          <span className="logout__icon">
            {isDashboard ? (
              <div className="poweroff">
                <FontAwesomeIcon
                  className="poweroff__icon"
                  icon={solid("power-off")}
                />
                <p className="poweroff__text">Logout</p>
              </div>
            ) : (
              <FontAwesomeIcon
                className="bracketicon"
                icon={solid("right-from-bracket")}
              />
            )}
          </span>
        </div>
      )}
      {showPopUp && (
        <Popup
          onShow={checked}
          onClickContinue={onClickContinue}
          onClickLogout={onClickLogout}
          handleHide={handleChange}
        />
      )}
    </>
  );
}

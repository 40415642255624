import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "components/ui/button/Button";
import Textarea from "components/ui/textarea/TextArea";
import {
  NextStepActionResponse,
  useSetApplicationStepAnswerMutation,
} from "pages/application/applicationService";
import { useSelector } from "react-redux";
import { getCurrentUserApplicationId } from "features/authSlice";

const OtherButtonAndText = ({
  label,
  showOtherTextInput,
  setShowOtherTextInput,
  otherText,
}: {
  label: string;
  showOtherTextInput: boolean;
  setShowOtherTextInput: React.Dispatch<React.SetStateAction<boolean>>;
  otherText: NextStepActionResponse;
}) => {
  return (
    <>
      <Button
        title={label}
        className={`single-choice-button ${
          showOtherTextInput ? "retain-focus" : ""
        }`}
        onClick={() => setShowOtherTextInput(true)}
      />
      {showOtherTextInput && <OtherTextInput textInputStepAction={otherText} />}
    </>
  );
};

const OtherTextInput = ({
  textInputStepAction,
}: {
  textInputStepAction: NextStepActionResponse;
}) => {
  const [updateApplication] = useSetApplicationStepAnswerMutation({
    fixedCacheKey: "updateApplication",
  });
  const applicationId = useSelector(getCurrentUserApplicationId);
  const onSubmit = async () => {
    await updateApplication({
      id: applicationId,
      stepActionId: stepActionId,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(
      z.object({
        action: z.string().trim().min(1, "This field is required"),
      })
    ),
  });

  const { label, stepActionId } = textInputStepAction;
  return (
    <>
      <Textarea
        {...register("action")}
        label={label}
        errorMessage={errors.action?.message as string}
      />
      <Button title="Continue" onClick={() => handleSubmit(onSubmit)} />
    </>
  );
};

export default OtherButtonAndText;

import "./welcome-los.scss";
import { ReactComponent as DesktopThankYouGraphic } from "assets/images/ThankYouGraphicDesktop.svg";
import Footer from "components/footer/Footer";
import Overlay from "components/ui/loading-spinners/Overlay/Overlay";
import { getUserId } from "features/authSlice";
import { LoanOfficerDetail } from "pages/application/applicationService";
import { useGetloanOfficerQuery } from "pages/registration/registrationService";
import { useSelector } from "react-redux";
import { CONTACT_US } from "utilities/Constant";

export default function WelcomeLos() {
  const userId = useSelector(getUserId);
  const {
    data: loanOfficer,
    isLoading,
    isFetching,
  } = useGetloanOfficerQuery(userId);

  if (isFetching || isLoading) {
    return <Overlay />;
  }

  const loanOfficerPhoneNumbers = (phones: LoanOfficerDetail["phones"]) => {
    return phones.map((phone) => {
      const formattedNumber = phone.number.replace(
        /(\d{3})(\d{3})(\d{4})/,
        "$1-$2-$3"
      );
      return formattedNumber;
    });
  };

  return (
    <div className="welcome-los">
      <div className="welcome-los__body">
        <p className="welcome-los__body--sub1">Hello!</p>
        {loanOfficer ? (
          <p className="welcome-los__body--sub2">
            If you have any questions about your mortgage inquiry, please reach
            out to your Loan Officer {loanOfficer.fullName} at{" "}
            {loanOfficerPhoneNumbers(loanOfficer.phones)}.
          </p>
        ) : (
          <p className="welcome-los__body--sub2">
            If you have any questions about your mortgage inquiry, please reach
            out to your Loan Officer or call us at {CONTACT_US}.
          </p>
        )}
      </div>
      <div className="welcome-los__graphic">
        <DesktopThankYouGraphic />
      </div>
      <Footer />
    </div>
  );
}

import "./acknowledgements.scss";
import { ActionButton } from "pages/application/templates/Summary";
import Declarations from "./Declarations";
import {
  useGetAcknowledgementsQuery,
  useGetApplicantQuery,
  useGetDeclarationsQuery,
  useGetSpouseCoApplicantQuery,
} from "./queries";
import { NextStepActionResponse } from "pages/application/applicationService";

export const Acknowledgements = () => {
  const { hasSpouseCoApplicant, next } = useGetAcknowledgementsQuery();
  const { hasSpouseCoApplicantDeclarations, hasApplicantDeclarations } =
    useGetDeclarationsQuery();
  return (
    <div
      className="acknowledgements-section"
      data-testid="acknowledgements-section"
    >
      <div className="demographics">
        <Applicant />
        {hasSpouseCoApplicant && <SpouseCoApplicant />}
      </div>

      {(hasApplicantDeclarations || hasSpouseCoApplicantDeclarations) && (
        <div className="declarations" data-testid="declarations">
          <Declarations hasSpouseCoApplicant={hasSpouseCoApplicant} />
        </div>
      )}
      <ActionButton action="next" {...next} />
    </div>
  );
};

export const Applicant = () => {
  const { name, demographics } = useGetApplicantQuery();
  return (
    <div className="acknowledgements-applicant">
      <h6 className="demographics-subheader">{name}</h6>
      <Details {...demographics} />
    </div>
  );
};

export const SpouseCoApplicant = () => {
  const { name, demographics } = useGetSpouseCoApplicantQuery();
  return (
    <div className="acknowledgements-applicant">
      <h6 className="demographics-subheader">{name}</h6>
      <Details {...demographics} />
    </div>
  );
};

export const Details = ({
  ethnicityLabel,
  ethnicity,
  raceLabel,
  race,
  genderLabel,
  gender,
  editDetails,
}: {
  ethnicityLabel: string;
  ethnicity: string;
  raceLabel: string;
  race: string;
  genderLabel: string;
  gender: string;
  editDetails: NextStepActionResponse;
}) => {
  return (
    <div className="details-wrapper">
      <div className="details">
        <span className="details-label">{ethnicityLabel}:</span>
        <span className="details-value">{ethnicity}</span>
      </div>
      <div className="details">
        <span className="details-label">{raceLabel}: </span>
        <span className="details-value">{race}</span>
      </div>
      <div className="details">
        <span className="details-label">{genderLabel}: </span>
        <span className="details-value">{gender}</span>
      </div>
      <div className="edit-details">
        <ActionButton action="edit" {...editDetails} />
      </div>
    </div>
  );
};

export default Acknowledgements;

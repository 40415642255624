import { forwardRef } from "react";

import InPlaceError from "components/in-place-error/InPlaceError";
import "./text-area.scss";

export interface TextareaProps {
  name: string;
  label?: string;
  value?: string;
  errorMessage?: string;
  maxLength?: number;
  onChange?(e: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>): void;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    { name, label, errorMessage, onChange, value, maxLength }: TextareaProps,
    ref
  ) => {
    const id = `textarea-${name}`;
    return (
      <div className="textarea-container">
        {label && (
          <label htmlFor={id} className="textarea-label">
            {label}
          </label>
        )}
        <textarea
          name={name}
          id={id}
          ref={ref}
          className={errorMessage ? "textarea textarea--error" : "textarea"}
          onChange={onChange}
          value={value}
          maxLength={maxLength}
        />
        {errorMessage && <InPlaceError errorPhrase={errorMessage} />}
      </div>
    );
  }
);

TextArea.displayName = "TextArea";

export default TextArea;

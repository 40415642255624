import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { useSearchParams, useNavigate } from "react-router-dom";
import { z } from "zod";
import Modal from "components/modal/Modal";
import { Consent } from "components/consent/Consent";
import PasswordAndConfirmPassword from "components/password-and-confirm-password/PasswordAndConfirmPassword";
import { Button } from "components/ui/button/Button";
import Form from "components/ui/form/Form";
import { FormInput } from "components/ui/form/FormInput";
import { LoadingOverlay } from "components/ui/loading-spinners";

import {
  UpdatePasswordRequest,
  usePatchUpdatePasswordMutation,
  useGetDecryptEmailQuery,
} from "./registrationService";
import * as Constant from "utilities/Constant";

import {
  confirm_password,
  emailAddress,
  password_input,
} from "utilities/formSchemas";
import "./registration-co-borrower.scss";

import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { ErrorType } from "utilities/error";
import { useDispatch } from "react-redux";
import { setUserId } from "features/authSlice";
import { setResendCodeDeviceId } from "pages/login/loginSlice";
import { setFSUserProperties } from "utilities/full-story";

export const formSchema = z.object({
  email: emailAddress,
  password_input,
  confirm_password
});

export default function RegistrationCoBorrower() {

  const {
    handleSubmit,
    control,
    register,
    formState: { errors, dirtyFields },
    setValue,
    getValues,
  } = useForm({
    resolver: zodResolver(formSchema),
  });
  const [error, setError] = useState(0);
  const [searchParams] = useSearchParams();
  const encryptedUser = searchParams.get("t")!;
  const {
    data: encryptedEmailReponse,
    isLoading,
    isFetching,
  } = useGetDecryptEmailQuery(encryptedUser, {
    skip: !encryptedUser,
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (encryptedEmailReponse) {
      const { ex, em } = encryptedEmailReponse;
      setFSUserProperties(em);
      if (ex !== true) {
        setValue("email", em);
      }
      else {
        navigate("/", { replace: true });
      }
    }
  }, [encryptedEmailReponse])

  const [hasPwdError, setHasPwdError] = useState(false);

  let showModal = false;
  if (!isLoading && !isFetching && !encryptedEmailReponse) {
    showModal = true;
  }

  const [sendRequest, { data, isSuccess, isLoading: isRegistrationLoading }] =
    usePatchUpdatePasswordMutation();
  const dispatch = useDispatch();
  const onSubmit = (data: UpdatePasswordRequest) => {
    if (!hasErrors && !hasPwdError) {
      sendRequest(data)
        .unwrap()
        .catch((error: ErrorType) => {
          if (error.status == 409) {
            navigate("/", { replace: true });
          } else {
            setError(500);
          }
        });
    }
  };

  useEffect(() => {
    if (isSuccess && data) {
      setError(0);
      setFSUserProperties(getValues("email") as string, data.userLastName?.toLowerCase());
      dispatch(setUserId(data.userId));
      dispatch(setResendCodeDeviceId(data.deviceAuthId));
      navigate("/registrationAuth", { replace: true });
    }
  }, [isSuccess, data])

  const ispasswordFieldModified =
    (dirtyFields.password_input as boolean) || false;
  const hasErrors = !!Object.keys(errors).length;
  if (isRegistrationLoading) return <LoadingOverlay text="Registering..." />;


  return (
    <>
      {(isLoading || isFetching) && (
        <LoadingOverlay text="Loading user details..." />
      )}

      <div className="container registration-coborrower">
        <div className="heading-section">
          <h1 className="heading">Welcome to New American Funding!</h1>
          <p className="sub-header sub-header--primary margin-bottom-small margin-top-small registration-sub-header">
            To get started, please create a password so that you can come back to this information, at your convenience.
          </p>

          {error == 500 && (
            <div className="error__container">
              {Constant.RegistrationError.map((error) => {
                return (
                  <p key={error} className="error__message">
                    {error}
                  </p>
                );
              })}
            </div>
          )}
        </div>

        {showModal && (
          <Modal
            defaultBody
            defaultBodyLabel="Something went wrong!"
            defaultBodyLabelIcon={regular("triangle-exclamation")}
            defaultBodySubContent={[
              "Please open the email we sent you and click on the link so that we can try again.",
              "We apologize for the inconvenience.",
            ]}
            footer={<></>}
          />
        )}

        <Form
          onSubmit={handleSubmit((data) => {
            onSubmit({
              email: data.email as string,
              password: data.password_input as string,
            });
          })}
        >
          <FormInput
            formHandle={register("email")}
            inputProps={{
              name: "email",
              placeholder: "Enter Your Email",
              label: "Email",
              errorMessage: errors.email?.message as string,
              isReadOnly: true
            }}
          />
          <Controller
            name="password_input"
            control={control}
            render={({ field: { onChange } }) => (
              <PasswordAndConfirmPassword
                onChange={onChange}
                checkErrors={ispasswordFieldModified}
                createLabel="Create Password"
                confirmLabel="Confirm Password"
                setHasPasswordError={setHasPwdError}
                errorMessage={errors.password_input?.message as string ? Constant.REQUIRED_PASSWORD : ""}
                confirmPasswordErrorMessage={errors.confirm_password?.message as string ? Constant.REQUIRED_PASSWORD_CONFIRMATION : ""}
                setValue={setValue}
                confirmPasswordInputName="confirm_password"
              />
            )}
          />
          <Button title="Continue" className="spouseSignUp" />
        </Form>
      </div>
      <Consent
        termsOfUseLink={Constant.TERMSOFUSELINK}
        privacyLink={Constant.PRIVACYLINK}
        partnersLink={Constant.PARTNERSLINK}
        agreementLink={Constant.AGREEMENTLINK}
      />
    </>
  );
}

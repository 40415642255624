import { useState, useEffect } from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import PasswordChecklist from "react-password-checklist";
import { ReactComponent as CheckImg } from "assets/images/check.svg";
import { ReactComponent as CircleImg } from "assets/images/circle.svg";
import { ReactComponent as Xmark } from "assets/images/xmark.svg";
import { Input } from "components/ui/input/Input";
import * as Constant from "utilities/Constant";
import "../password_checker.scss";

export const passwordIcon = (isPasswordVisible: boolean) =>
  isPasswordVisible ? solid("eye") : solid("eye-slash");
export const passwordType = (isPasswordVisible: boolean) =>
  isPasswordVisible ? "text" : "password";

interface PasswordAndConfirmPasswordProps {
  checkErrors?: boolean;
  confirmPasswordErrorMessage?: string;
  onChange?: (...event: any[]) => void;
  createLabel?: string;
  confirmLabel?: string;
  setHasPasswordError?: (hasError: boolean) => void;
  setValue?: (
    name: string,
    value: any,
    options?: Partial<{ shouldValidate: boolean; shouldDirty: boolean }>
  ) => void;
  confirmPasswordInputName?: string;
  createPasswordPlaceholderName?: string;
  inputBorderHighlightForError?: boolean;
  showConfirmPassword?: boolean;
}

const PasswordCheckerVersion2 = ({
  checkErrors = true,
  confirmPasswordErrorMessage,
  onChange,
  createLabel,
  confirmLabel,
  setHasPasswordError,
  setValue,
  confirmPasswordInputName,
  createPasswordPlaceholderName = "Password",
  inputBorderHighlightForError,
  showConfirmPassword,
}: PasswordAndConfirmPasswordProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isPasswordAgainVisible, setIsPasswordAgainVisible] = useState(false);
  const [passwordCheckerError, setPasswordCheckerError] = useState("");
  const [checkPasswordError, setCheckPasswordError] = useState({
    password: false,
    confirmPassword: false,
  });
  const [passwordsDoNotMatchError, setPasswordsDoNotMatchError] = useState("");
  const [password, setPassword] = useState<string>("");
  const [passwordAgain, setPasswordAgain] = useState<string>("");
  const [isValid, setIsValid] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const getConfirmPasswordErrorMessage = (): string => {
    if (confirmPasswordErrorMessage) {
      return confirmPasswordErrorMessage;
    }
    if (
      checkPasswordError.confirmPassword &&
      checkErrors &&
      passwordAgain &&
      passwordsDoNotMatchError
    ) {
      return passwordsDoNotMatchError;
    }
    return "";
  };

  useEffect(() => {
    if (checkErrors) {
      setPasswordsDoNotMatchError(
        password !== passwordAgain ? Constant.PWD_MATCH_ERROR : ""
      );
      if (setHasPasswordError) {
        setHasPasswordError(
          passwordCheckerError !== "" || passwordsDoNotMatchError !== ""
        );
      }
    }
  }, [
    checkErrors,
    password,
    passwordAgain,
    passwordsDoNotMatchError,
    passwordCheckerError,
    checkPasswordError,
  ]);
  const passwordChecklistStyle = isValid
    ? " password-checker--validFalse"
    : " password-checker--validTrue";
  return (
    <>
      <div className="password-input-checker">
        <Input
          name="password_input"
          type={passwordType(isPasswordVisible)}
          placeholder={createPasswordPlaceholderName}
          icon={passwordIcon(isPasswordVisible)}
          label={createLabel}
          containerClassName
          onIconClick={() => setIsPasswordVisible(!isPasswordVisible)}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            setPassword(e.currentTarget.value);
            if (onChange) {
              onChange(e);
            }
          }}
          onBlur={(e: React.FormEvent<HTMLInputElement>) => {
            setCheckPasswordError((props) => ({
              ...props,
              password: true,
            }));
          }}
          isIconPointer
          inputBorderHighlightForError={checkPasswordError.password && !isValid}
          onFocus={() => setIsFocus(true)}
        />
        {isFocus && (
          <div
            className={
              "input__wrapper no-margin-top password-checker" +
              passwordChecklistStyle
            }
          >
            Passwords must
            <PasswordChecklist
              rules={[
                "capital",
                "lowercase",
                "number",
                "specialChar",
                "minLength",
              ]}
              minLength={Constant.PWD_MIN_LENGTH}
              value={password}
              valueAgain={passwordAgain}
              messages={{
                minLength: Constant.PWD_CHECK_MIN_LENGTH,
                capital: Constant.PWD_CHECK_UPPER_CASE,
                lowercase: Constant.PWD_CHECK_LOWER_CASE,
                number: Constant.PWD_CHECK_NUMBER,
                specialChar: Constant.PWD_CHECK_SPECIAL_CHAR,
              }}
              onChange={(isValid) => {
                setIsValid(isValid);
                setPasswordCheckerError(
                  isValid ? "" : Constant.INVALID_PASSWORD_ERROR
                );
              }}
              iconComponents={{
                ValidIcon: <CheckImg className="validCheck margin-small" />,
                InvalidIcon: checkPasswordError.password ? (
                  <Xmark className="invalidMark margin-small" />
                ) : (
                  <CircleImg className="invalidCircle margin-small" />
                ),
              }}
            />
          </div>
        )}
      </div>
      {showConfirmPassword && (
        <Input
          name="confirm_password"
          type={passwordType(isPasswordAgainVisible)}
          placeholder="Confirm Password"
          label={confirmLabel}
          icon={passwordIcon(isPasswordAgainVisible)}
          onIconClick={() => setIsPasswordAgainVisible(!isPasswordAgainVisible)}
          isIconPointer
          errorMessage={getConfirmPasswordErrorMessage()}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            setPasswordAgain(e.currentTarget.value);
            confirmPasswordInputName &&
              setValue?.(confirmPasswordInputName, e.currentTarget.value, {
                shouldValidate: true,
              });
          }}
          onBlur={() => {
            setCheckPasswordError((props) => ({
              ...props,
              confirmPassword: true,
            }));
          }}
        />
      )}
    </>
  );
};

export default PasswordCheckerVersion2;

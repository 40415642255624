import { useState, useEffect } from "react";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import PasswordChecklist from "react-password-checklist";
import { ReactComponent as CheckImg } from "assets/images/check.svg";
import { ReactComponent as CircleImg } from "assets/images/circle.svg";
import { Input } from "components/ui/input/Input";
import * as Constant from "utilities/Constant";
import "./password_checker.scss";

export const passwordIcon = (isPasswordVisible: boolean) =>
  isPasswordVisible ? regular("eye") : regular("eye-slash");
export const passwordType = (isPasswordVisible: boolean) =>
  isPasswordVisible ? "text" : "password";

interface PasswordAndConfirmPasswordProps {
  checkErrors?: boolean;
  errorMessage?: string;
  confirmPasswordErrorMessage?: string;
  onChange?: (...event: any[]) => void;
  createLabel?: string;
  confirmLabel?: string;
  setHasPasswordError?: (hasError: boolean) => void;
  setValue?: (name: string, value: any, options?: Partial<{ shouldValidate: boolean; shouldDirty: boolean; }>) => void;
  confirmPasswordInputName?: string;
  createPasswordPlaceholderName?:string;
}

const PasswordAndConfirmPassword = ({
  checkErrors = true,
  errorMessage,
  confirmPasswordErrorMessage,
  onChange,
  createLabel,
  confirmLabel,
  setHasPasswordError,
  setValue,
  confirmPasswordInputName,
  createPasswordPlaceholderName = "Password",
}: PasswordAndConfirmPasswordProps) => {
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [isPasswordAgainVisible, setPasswordAgainVisible] = useState(false);
  const [passwordCheckerError, setPasswordCheckerError] = useState("");
  const [checkPasswordError, setCheckPasswordError] = useState({ password: false, confirmPassword: false });
  const [passwordsDoNotMatchError, setPasswordsDoNotMatchError] = useState("");
  const [password, setPassword] = useState<string>("");
  const [passwordAgain, setPasswordAgain] = useState<string>("");
  const [isValid, setIsValid] = useState(false);
  const getConfirmPasswordErrorMessage = ():string => {
    if (confirmPasswordErrorMessage) {
      return confirmPasswordErrorMessage;
    }
    if (checkPasswordError.confirmPassword && checkErrors && passwordAgain && passwordsDoNotMatchError) {
      return passwordsDoNotMatchError;
    }
    return "";
  }

  useEffect(() => {
    if (checkErrors) {
      setPasswordsDoNotMatchError(
        password !== passwordAgain ? Constant.PWD_MATCH_ERROR : ""
      );
      if (setHasPasswordError) {
        setHasPasswordError(
          passwordCheckerError !== "" || passwordsDoNotMatchError !== ""
        );
      }
    }
  }, [
    checkErrors,
    password,
    passwordAgain,
    passwordsDoNotMatchError,
    passwordCheckerError,
    checkPasswordError,
  ]);

  const passwordErrorMessage = () => {
    if (errorMessage) {
      return errorMessage;
    }

    if (checkErrors && password && passwordCheckerError) {
      return passwordCheckerError;
    }

    return "";
  };
  const passwordChecklistStyle = isValid
    ? " password-checker--validFalse"
    : " password-checker--validTrue";
  return (
    <>
      <Input
        name="password_input"
        type={passwordType(isPasswordVisible)}
        placeholder={createPasswordPlaceholderName}
        icon={passwordIcon(isPasswordVisible)}
        label={createLabel}
        onIconClick={() => setPasswordVisible(!isPasswordVisible)}
        onChange={(e: React.FormEvent<HTMLInputElement>) => {
          setPassword(e.currentTarget.value);
          if (onChange) {
            onChange(e);
          }
        }}
        onBlur={(e: React.FormEvent<HTMLInputElement>) => {
          setCheckPasswordError(props => ({
            ...props,
            password: true
          }));
        }}
        isIconPointer
        errorMessage={(errorMessage) || checkPasswordError.password ? passwordErrorMessage() : ""}
      />
      <div
        className={
          "input__wrapper no-margin-top password-checker" +
          passwordChecklistStyle
        }
      >
        Passwords must:
        <PasswordChecklist
          rules={["capital", "lowercase", "number", "specialChar", "minLength"]}
          minLength={Constant.PWD_MIN_LENGTH}
          value={password}
          valueAgain={passwordAgain}
          messages={{
            minLength: Constant.PWD_CHECK_MIN_LENGTH,
            capital: Constant.PWD_CHECK_UPPER_CASE,
            lowercase: Constant.PWD_CHECK_LOWER_CASE,
            number: Constant.PWD_CHECK_NUMBER,
            specialChar: Constant.PWD_CHECK_SPECIAL_CHAR,
          }}
          onChange={(isValid) => {
            setIsValid(isValid);
            setPasswordCheckerError(
              isValid ? "" : Constant.INVALID_PASSWORD_ERROR
            );
          }}
          iconComponents={{
            ValidIcon: <CheckImg className="validCheck margin-small" />,
            InvalidIcon: <CircleImg className="invalidCircle margin-small" />,
          }}
        />
      </div>
      <Input
        name="confirm_password"
        type={passwordType(isPasswordAgainVisible)}
        placeholder="Confirm Password"
        label={confirmLabel}
        icon={passwordIcon(isPasswordAgainVisible)}
        onIconClick={() => setPasswordAgainVisible(!isPasswordAgainVisible)}
        isIconPointer
        errorMessage = {getConfirmPasswordErrorMessage()}
        onChange={(e: React.FormEvent<HTMLInputElement>) => {
          setPasswordAgain(e.currentTarget.value);
          confirmPasswordInputName && setValue?.(confirmPasswordInputName, e.currentTarget.value, { shouldValidate: true });
        }}

        onBlur={() => {
          setCheckPasswordError(props => ({
            ...props,
            confirmPassword: true
          }));
        }}
      />
    </>
  );
};

export default PasswordAndConfirmPassword;

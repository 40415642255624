import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./mfa.scss"
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro"
import { PrivacyPolicy } from "components/privacy-policy/PrivacyPolicy"
import * as Constant from "utilities/Constant";
import { useDispatch, useSelector } from "react-redux";
import { getAuthDevicesDetails, setResendCodeDeviceId } from "pages/login/loginSlice";
import { useRedirect } from "hooks/useRedirect";
import { usePostMfaMutation } from "./mfaService";
import { useNavigate } from "react-router-dom";
import Overlay from "components/ui/loading-spinners/Overlay/Overlay";

const Mfa = () => {
  const { flowId: MfaFlowId, _embedded: deviceDetailsList } = useSelector(getAuthDevicesDetails);
  const { devices } = deviceDetailsList;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useRedirect(MfaFlowId == "", "/")

  const emailAuthDetails = devices.find(obj => obj.type.toLowerCase() == "email");
  const smsAuthDetails = devices.find(obj => obj.type.toLowerCase() == "sms");

  const [sendRequest, { data, isSuccess, isLoading }] = usePostMfaMutation();

  if (data && isSuccess) {
    navigate("/authcode", { replace: true })
  }

  const handleClick = (deviceId: string) => {
    dispatch(setResendCodeDeviceId(deviceId));
    return sendRequest({
      id: deviceId,
      flowId: MfaFlowId,
    })
  };

  if (isLoading) return <Overlay />

  return (
    <div className="mfa-container">
      <p className="mfa-container__header">
        Two Factor Authentication
      </p>

      <p className="mfa-container__message">
        How would you like to receive your authentication code?
      </p>

      <div className="box-container"
        onClick={async () => await handleClick(smsAuthDetails?.id ?? "")}
        onKeyUp={async () => await handleClick(smsAuthDetails?.id ?? "")}
      >
        <FontAwesomeIcon icon={regular("mobile-retro")} className="icon-mobile" />
        <div className="description">
          <div className="box">
            <p className="box__header">SMS Text Message</p>
            <FontAwesomeIcon icon={regular("arrow-right")} className="box__arrow-icon" />
          </div>
          <p className="box__message">Receive a text message to your mobile device.</p>
          <p className="box__detail">{smsAuthDetails?.phone}</p>
        </div>
      </div>

      <div className="box-container"
        onClick={async () => await handleClick(emailAuthDetails?.id ?? "")}
        onKeyUp={async () => await handleClick(emailAuthDetails?.id ?? "")}
      >
        <FontAwesomeIcon icon={regular("envelope")} className="icon-envelope" />
        <div className="description">
          <div className="box">
            <p className="box__header">Email</p>
            <FontAwesomeIcon icon={regular("arrow-right")} className="box__arrow-icon" />
          </div>
          <p className="box__message">Receive an email to the email address you provided.</p>
          <p className="box__detail">{emailAuthDetails?.email}</p>
        </div>
      </div>
      <div className="bottom">
        <PrivacyPolicy privacyLink={Constant.PRIVACYLINK} />
      </div>
    </div>
  )
}
export default Mfa;
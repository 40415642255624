import InPlaceError from "components/in-place-error/InPlaceError";
import React, { useEffect, useState } from "react";
import "./date-input.scss";
import { Label } from "components/ui/input/Input";

export interface DateInputProps {
  errorMessage?: string;
  onChange?: (e: string) => void;
  placeholder?: string;
  label?: string;
  initialValue: string;
}

const DateInput = ({
  errorMessage,
  onChange,
  placeholder,
  label,
  initialValue,
}: DateInputProps) => {
  const [inputValue, setInputValue] = useState("");
  const [inputError, setInputError] = useState("");
  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  const getCurrentMonth = () => {
    return new Date().getMonth() + 1;
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value;
    const sanitizedValue = rawValue.replace(/\D/g, "");

    let formattedValue = "";

    if (sanitizedValue.length > 2) {
      const month = parseInt(sanitizedValue.slice(0, 2), 10);
      const year = parseInt(sanitizedValue.slice(2, 6), 10);

      if (
        year > getCurrentYear() ||
        (year === getCurrentYear() && month > getCurrentMonth())
      ) {
        setInputError("Invalid month/year");
      } else if (
        month > 12 ||
        (year === getCurrentYear() && month > getCurrentMonth())
      ) {
        setInputError("Invalid month");
      } else {
        formattedValue = `${Math.min(month, 12)
          .toString()
          .padStart(2, "0")}/${Math.min(year, getCurrentYear())}`;
        setInputError("");
      }
    } else {
      formattedValue = sanitizedValue;
      setInputError("");
    }

    setInputValue(formattedValue);

    if (onChange) {
      onChange(formattedValue);
    }
  };

  return (
    <div className="dateinput-container">
      {label && <Label label={label} />}
      <div className="dateinput-container__wrapper">
        <input
          className={errorMessage ? "dateinput dateinput--error" : "dateinput"}
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          placeholder={placeholder}
          minLength={7}
        />
        {(errorMessage ?? inputError) && (
          <InPlaceError errorPhrase={errorMessage ?? inputError} />
        )}
      </div>
    </div>
  );
};
export default DateInput;

export const getCardWidth=(width:string)=>{
  switch (width) {
    case "small":
      return "15%";
    case "medium":
      return "50%";
    case "large":
      return "70%";
    case "full":
      return "100%";
  }
}

export const CardData = [
  {
    fileName: "Jayson Tatum CDL.png",
    createdDate: "Mar 20, 2022 @ 12:58pm",
    uploadedby: "Jayson Tatum",
  },
  {
    fileName: "2021 W2.pdf",
    createdDate: "Mar 20, 2022 @ 12:58pm",
    uploadedby: "Jayson Tatum",
  },
  {
    fileName: "2021 1040.pdf",
    createdDate: "Mar 20, 2022 @ 12:58pm",
    uploadedby: "Jayson Tatum",
  },
  {
    fileName: "Feb Pay Stub.pdf",
    createdDate: "Mar 20, 2022 @ 12:58pm",
    uploadedby: "Jayson Tatum",
  },
];

import "./overlay.scss";

const AnimatedRings = () => {
  return (
    <svg
      className="loading-overlay-block__spinner"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx="50"
        cy="50"
        r="32"
        strokeWidth="8"
        stroke="#ffffff"
        strokeDasharray="50.26548245743669 50.26548245743669"
        fill="none"
        strokeLinecap="round"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1.3513513513513513s"
          keyTimes="0;1"
          values="0 50 50;360 50 50"
        ></animateTransform>
      </circle>
    </svg>
  );
};

const Overlay = ({ text = "Loading..." }: { text?: string }) => {
  return (
    <>
      <div className="loading-overlay" />
      <div className="loading-overlay-block">
        <AnimatedRings />
        <p className="loading-overlay-block__text">{text}</p>
      </div>
    </>
  );
};

export default Overlay;

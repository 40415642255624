import { BaseQueryFn, FetchArgs, FetchBaseQueryError, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setLastActiveSession, setLogout, setTokens } from "features/authSlice";
import { TokensRequest, authenticationApi } from "pages/authentication/authenticationService";
import { ErrorCode } from "react-dropzone";
import { RootState } from "store";
import * as Constant from "utilities/Constant";
import { minutesToSecondsConvertion, subtractDates } from "utilities/date";


export const baseQuery = fetchBaseQuery({ baseUrl: Constant.BASE_BFF_WEB_URL })

const baseQueryWithHeader = fetchBaseQuery({
  baseUrl: Constant.BASE_BFF_WEB_URL,
  prepareHeaders: (headers: Headers, { getState }) => {
    const accessToken = (getState() as RootState).auth.accessToken;
    if (accessToken) {
      headers.set("authorization", `Bearer ${accessToken}`);
    }
    return headers;
  }
});

export const baseQueryWithAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const { refreshToken, lastActiveTime, tokenExpiration } = (api.getState() as RootState).auth.tokenValidationParams;
  const accessToken = (api.getState() as RootState).auth.accessToken;
  const currentDate = new Date();
  const isUserActiveSessionLimitExceeds = subtractDates(currentDate, lastActiveTime) > minutesToSecondsConvertion(Constant.ACCESS_TOKEN_EXPIRATION_MINUTES);
  const isTokenExpired = subtractDates(tokenExpiration, currentDate) < minutesToSecondsConvertion(Constant.SKEW_TIME_IN_MINUTES);

  if (isUserActiveSessionLimitExceeds) {
    api.dispatch(setLogout())
  }
  else if (isTokenExpired) {
    const tokenRequestData: TokensRequest = {
      accessToken: accessToken,
      refreshToken: refreshToken
    }
    await api.dispatch(authenticationApi.endpoints.getNewTokens.initiate(tokenRequestData))
      .unwrap()
      .then((data) => {
        api.dispatch(setTokens(data))
      }
      )
      .catch(() =>
        api.dispatch(setLogout())
      );
  }
  const result = await baseQueryWithHeader(args, api, extraOptions);
  if (result.error?.status == 401 || result.error?.status == 403) {
    api.dispatch(setLogout())
  }
  api.dispatch(setLastActiveSession(new Date()));
  return result;
};

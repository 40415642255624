import useDesktopChecker from "hooks/useDesktopChecker";
import "./progress-bar.scss";

const clamp = (value: number, min = 0, max = 100) =>
  Math.min(Math.max(value, min), max);

const ProgressBar = ({
  progressPercentage,
}: {
  progressPercentage: number;
}) => {
  const mobileLabelPosition = clamp(progressPercentage - 8, 0, 100);
  const desktopLabelPosition = clamp(progressPercentage - 2, 0, 100);
  const isDesktop = useDesktopChecker();
  return (
    <div className="progressBarContainer">
      <div className="progress-bar">
        <div
          className="progress-bar__shaded-progress"
          style={{ width: `${clamp(progressPercentage)}%` }}
          data-testid="progress-bar"
        />
        {isDesktop ? (
          <span
            className="progress-bar__shaded-progress-label"
            style={{ left: `${desktopLabelPosition}%` }}
          >
            {progressPercentage}%
          </span>
        ) : (
          <span
            className="progress-bar__shaded-progress-label"
            style={{ left: `${mobileLabelPosition}%` }}
          >
            {progressPercentage}%
          </span>
        )}
      </div>
    </div>
  );
};

export default ProgressBar;

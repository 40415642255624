import { VisibleStepMetadata, TableRowMetadata } from "pages/application/applicationService";
import "./Metadata.scss";

const Metadata = ({ metadata }: { metadata: VisibleStepMetadata[] }) => {
  return (
    <div className="data">
      {metadata.map((item) => (
        <div key={item.text}>
          <div className="data__label">
            {item.header}
          </div>
          <div className="data__values">
            {item.text}
          </div>
         {item.table && (
            <div className="data__table">
              {item.table.rows.map((row: TableRowMetadata) => (
                <div key ={row.columns[0]} className="data__table__row">
                  <span>{row.columns[0]}</span> 
                  <span> {row.columns[1]}</span>
                </div>
              ))} 
              </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Metadata;

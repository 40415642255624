import { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { getStepData } from "components/question/questionSlice";
import "./nav-bar.scss";
import Logout from "../logout/Logout";
import { useSelector } from "react-redux";
import useDesktopChecker from "hooks/useDesktopChecker";
import { showDocuments } from "utilities/UserversionFeatures";
export interface INavBarProps {
  userName?: string;
  showNavLinks?: boolean;
  tooltip?: boolean;
}

export default function NavBar({
  userName,
  showNavLinks = false,
}: INavBarProps) {
  const navRef = useRef<HTMLElement>(null);
  const [showMenu, setShowMenu] = useState(false);
  const showNavbar = () => {
    setShowMenu(!showMenu);
  };
  const isDesktop = useDesktopChecker();
  const stepData = useSelector(getStepData);
  const isSummary = stepData?.stepType === "summary";
  return (
    <div className="navbar-container">
      {isDesktop ? (
        <nav ref={navRef} className="navbar">
          {!isSummary && showNavLinks && <NavLinks />}
          {userName && <Logout name={userName} />}
          <span onClick={showNavbar} className="navbar__btn navbar__close-btn">
            <FontAwesomeIcon icon={regular("xmark")} />
          </span>
        </nav>
      ) : (
        <>
          {showMenu && (
            <nav ref={navRef} className="navbar">
              {!isSummary && showNavLinks && <NavLinks />}
              {userName && <Logout name={userName} />}
              <span
                onClick={showNavbar}
                className="navbar__btn navbar__close-btn"
              >
                <FontAwesomeIcon icon={regular("xmark")} />
              </span>
            </nav>
          )}
        </>
      )}

      {isDesktop || showNavLinks ? (
        <span onClick={showNavbar} className="navbar__btn">
          <FontAwesomeIcon icon={regular("bars")} />
        </span>
      ) : (
        <Logout />
      )}
    </div>
  );
}
function NavLinks({ className = "" }: { className?: string }) {
  const navbarClass = "navbar__links" + " " + className;
  const isDesktop = useDesktopChecker();
  return (
    <>
      {isDesktop ? (
        <div className={navbarClass}>
          <NavLink to="/dashboard" className="link" end>
            <p>My Dashboard</p>
          </NavLink>
          {showDocuments() && (
            <NavLink to="/dashboard/documents" className="link">
              My Documents
            </NavLink>
          )}
        </div>
      ) : (
        <div className={navbarClass}>
          <NavLink to="/dashboard" className="link" end>
            <FontAwesomeIcon
              className="link__icon"
              icon={regular("table-columns")}
            />
            <p className="link__text">My Dashboard</p>
          </NavLink>
          {showDocuments() && (
            <NavLink to="/dashboard/documents" className="link">
              <FontAwesomeIcon
                className="link__icon"
                icon={regular("file-lines")}
              />
              <p className="link__text">My Documents</p>
            </NavLink>
          )}

          <div className="lines"></div>
        </div>
      )}
    </>
  );
}

import { ReactComponent as DesktopThankYouGraphic } from "assets/images/ThankYouGraphicDesktop.svg";
import { ReactComponent as MobileThankYouGraphic } from "assets/images/ThankYouGraphicMobile.svg";
import { Button } from "components/ui/button/Button";
import useDesktopChecker from "hooks/useDesktopChecker";
import Footer from "components/footer/Footer";
import "./questionnaire-thank-you.scss";
import { setLogout } from "features/authSlice";
import { getStepData } from "components/question/questionSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SectionBar from "pages/application/header/section-bar/SectionBar";
import { CONTACT_US } from "utilities/Constant";

export default function QuestionnaireThankYou({ status }: { status?: string }) {
  return (
    <>
      {status === "Completed" ? (
        <ThankYouTemplate
          header="Welcome Back!"
          subHeaderOne="You're all done here! Let us help you with the next steps."
          subHeaderTwo={`Please call us at ${CONTACT_US}.`}
          buttonTitle="Logout"
        />
      ) : (
        <ThankYouTemplate
          header="Thank you!"
          subHeaderOne="You've completed an important step! A Loan Advisor will be reaching out to you shortly."
          buttonTitle="Save & Logout"
        />
      )}
    </>
  );
}
interface ThankYouTemplate {
  header: string;
  subHeaderOne: string;
  subHeaderTwo?: string;
  buttonTitle: string;
}

export const ThankYouTemplate = ({
  header,
  subHeaderOne,
  subHeaderTwo,
  buttonTitle,
}: ThankYouTemplate) => {
  const isDesktop = useDesktopChecker();
  const { section: activeSection, sections } = useSelector(getStepData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onClickLogout = () => {
    dispatch(setLogout());
    navigate("/");
  };
  return (
    <>
      <div className="question-container">
        <div>
          {!isDesktop && (
            <SectionBar activeSection={activeSection} sections={sections} />
          )}
        </div>
        <p className="question-container_header">{header}</p>
      </div>
      <div className="thank-you">
        <span className="thank-you_header">
          <p className="thank-you_header_one">{subHeaderOne}</p>
          {subHeaderTwo && (
            <p className="thank-you_header_two">{subHeaderTwo}</p>
          )}
        </span>
        <div className="thank-you_btn">
          <Button title={buttonTitle} onClick={onClickLogout} />
        </div>
        <p className="thank-you_graphic">
          {isDesktop ? <DesktopThankYouGraphic /> : <MobileThankYouGraphic />}
        </p>
      </div>
      <div className="thank-you-footer">
        <Footer />
      </div>
    </>
  );
};

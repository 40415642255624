import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ValidateOtpResponse } from "pages/authentication/authenticationService";
import { BASE_BFF_WEB_URL } from "utilities/Constant";

export interface LoginData {
  emailaddress: string;
  password: string;
}

export interface LoginResponse {
  flowId: string;
  _embedded: {
    devices: AuthDeviceDetails[];
  }
  status: string;
}

export interface AuthDeviceDetails {
  id: string;
  type: string;
  phone?: string;
  email?: string;
}

export const loginApi = createApi({
  reducerPath: "loginApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_BFF_WEB_URL }),
  endpoints: (build) => ({
    postLogin: build.mutation<LoginResponse | ValidateOtpResponse, LoginData>({
      query: (body: LoginData) => ({
        url: "/Account/login",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { usePostLoginMutation } = loginApi;

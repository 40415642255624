import {
  useSetApplicationStepAnswerMutation,
  NextStepActionResponse,
  Validation,
} from "pages/application/applicationService";
import MultipleInput from "pages/application/templates/MultipleInput";
import SingleChoice from "pages/application/templates/SingleChoice";
import SingleInput from "pages/application/templates/SingleInput";
import SingleChoiceWithData from "pages/application/templates/SingleChoiceWithData";
import { useSelector } from "react-redux";
import Informational from "../templates/Informational";
import MultipleSelection from "../templates/MultipleSelection";
import { Button } from "components/ui/button/Button";
import { DISPLAY_STEP_CODE } from "utilities/Constant";
import { getStepData } from "components/question/questionSlice";
import ToastMessage from "components/ui/toast-message/ToastMessage";
import { getCurrentUserApplicationId } from "features/authSlice";

const Constants = {
  SingleInput: "singleInput",
  SingleChoice: "singleChoice",
  MultipleInput: "multipleInput",
  SingleChoiceWithData: "singleChoiceWithData",
  Informational: "informational",
  MultipleSelection: "multipleSelection",
};

const getStepComponent = (stepType: string) => {
  switch (stepType) {
    case Constants.SingleInput:
      return SingleInput;
    case Constants.SingleChoice:
      return SingleChoice;
    case Constants.MultipleInput:
      return MultipleInput;
    case Constants.SingleChoiceWithData:
      return SingleChoiceWithData;
    case Constants.Informational:
      return Informational;
    case Constants.MultipleSelection:
      return MultipleSelection;
    default:
      return () => null;
  }
};

const shouldShowValidationToast = (validation: Validation): boolean => {
  if (validation.success || !validation.message) {
    return false;
  }
  
  return true;
};

const Question = () => {
  const stepData = useSelector(getStepData);
  const {
    stepCodeText: stepCode,
    stepType,
    label: stepLabel,
    subLabel: stepLabelSubText,
  } = stepData;

  const StepComponent = getStepComponent(stepType);
  const [_, { validation }] = useSetApplicationStepAnswerMutation({
    fixedCacheKey: "updateApplication",
    selectFromResult: (result) => ({
      validation: result.data?.validation,
    }),
  });
  return (
    <>
      <main className="application-main">
        {DISPLAY_STEP_CODE && <h4>{stepCode} :</h4>}
        {validation && shouldShowValidationToast(validation) && (
          <ToastMessage
            message={validation.message}
            timeout={3000}
            position="top-right"
            state="error"
          />
        )}
        <div className="questionContainer">
          <div className="application-main__question">
            <h3 className="application-main__question--label">{stepLabel}</h3>
            {stepLabelSubText && (
              <p className="application-main__question--subtext">
                {stepLabelSubText}
              </p>
            )}
          </div>
        </div>
      </main>
      <div className="stepsContainer">
        <div className="application-steps">
          <StepComponent />
        </div>
      </div>
    </>
  );
};

export const SkipButton = ({
  skipAction,
  skipActionHandler,
}: {
  skipAction: NextStepActionResponse;
  skipActionHandler?: () => void;
}) => {
  const [skipQuestion] = useSetApplicationStepAnswerMutation({
    fixedCacheKey: "updateApplication",
  });
  const applicationId = useSelector(getCurrentUserApplicationId);
  const handleSkip = async () => {
    if (skipActionHandler) {
      skipActionHandler();
      return;
    }
    await skipQuestion({
      id: applicationId,
      stepActionId: skipAction.stepActionId,
    });
  };
  return (
    <div className="skip-button-link">
      <Button
        onClick={() => void handleSkip()}
        title={skipAction.label}
        type="link"
      />
    </div>
  );
};

export default Question;

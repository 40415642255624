import { createSlice, createSelector } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "store";
import { LoanStatus, dashboardApi } from "./dashboardService";

const initialState: LoanStatus = {
  status: "Unknown",
}

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setLoanStatus: (state, action: PayloadAction<LoanStatus>) => {
      state.status = action.payload.status;
    },
  },
  extraReducers: (builder) =>{
    builder.addMatcher(
      dashboardApi.endpoints.getLoanStatus.matchFulfilled,
      (state, action: PayloadAction<LoanStatus>) => {
        state.status = action.payload.status
      }
    )
  }
})

export const getMileStoneStatus = createSelector(
  (state: RootState) => state.dashboard,
  ({ status }) => status
);

export default dashboardSlice.reducer;
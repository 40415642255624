import { MonthSelect } from "components/month-select/MonthSelect";
import { YearSelect } from "components/year-select/YearSelect";
import { useEffect, useState } from "react";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";

export interface MonthAndYearState {
  month: string;
  year: string;
}
interface IMonthAndYearSelectProps {
  monthLabel?: string;
  yearLabel?: string;
  monthClassName?: string;
  yearClassName?: string;
  errors?: Merge<FieldError, FieldErrorsImpl<MonthAndYearState>>;
  onChange: (...event: unknown[]) => void;
}

export function MonthAndYearSelect({
  monthLabel = "Month",
  yearLabel = "Year",
  monthClassName = "month-select",
  yearClassName = "year-select",
  onChange,
  errors,
}: IMonthAndYearSelectProps) {
  const [monthAndYear, setMonthAndYear] = useState<MonthAndYearState>({
    month: "",
    year: "",
  });

  useEffect(() => {
    onChange(monthAndYear);
  }, [monthAndYear.month, monthAndYear.year]);

  return (
    <div className="month-and-year-select">
      <MonthSelect
        label={monthLabel}
        className={monthClassName}
        onChange={setMonthAndYear}
        monthAndYear={monthAndYear}
        errorMessage={errors?.month?.message}
      />
      <YearSelect
        label={yearLabel}
        className={yearClassName}
        onChange={setMonthAndYear}
        monthAndYear={monthAndYear}
        errorMessage={errors?.year?.message}
      />
    </div>
  );
}

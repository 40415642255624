import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { LoanStatus } from "pages/dashboard/home/dashboardService";
import { baseQueryWithAuth } from "services/common/rtkQueryConfig";

export interface AuthActionResponse {
  userId: string;
  applicationRoleType: string;
  nhApplicationId?: string;
  dealApplicationId?: string;
  version: string;
  hasLosLoanNumber: boolean;
  loanStatus?: LoanStatus;
  applicationStatus: string;
}

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: baseQueryWithAuth,
  endpoints: (build) => ({
    getAuthActions: build.query<AuthActionResponse, string>({
      query: (userId: string) => ({
        url: `/dealapplication/user/${userId}?losloannumber=true&loanstatus=true`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const { useGetAuthActionsQuery } = userApi;

import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SectionType, Section } from "pages/application/applicationService";
import "./section-bar.scss";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  IconDefinition,
  faCircle1,
  faCircle2,
  faCircle3,
  faCircle4,
  faCircle5,
} from "@fortawesome/pro-solid-svg-icons";
import useDesktopChecker from "hooks/useDesktopChecker";
import { useState } from "react";
import { getCurrentUserApplicationStatus } from "features/authSlice";
import { getStepData } from "components/question/questionSlice";
import { useSelector } from "react-redux";

export enum SectionProgressState {
  Completed,
  InProgress,
  NotStarted,
}

interface SectionIndex {
  [x: string]: any;
  name: SectionType;
  icon: IconDefinition;
}

export const sectionIcons: SectionIndex[] = [
  {
    name: "Profile",
    icon: faCircle1,
  },
  {
    name: "Property",
    icon: faCircle2,
  },
  {
    name: "Credit",
    icon: faCircle3,
  },
  {
    name: "Finances",
    icon: faCircle4,
  },
  {
    name: "Acknowledgements",
    icon: faCircle5,
  },
];

export default function SectionBar({
  activeSection,
  sections,
}: {
  activeSection: SectionType;
  readonly sections: Section[];
}) {
  if (activeSection === "Unknown") {
    throw new Error(`Section not found`);
  }
  const [selectedSection, setSelectedSection] = useState(true);
  const isDesktop = useDesktopChecker();
  const applicationStatus = useSelector(getCurrentUserApplicationStatus);
  const stepData = useSelector(getStepData);
  const handleSectionClick = () => {
    setSelectedSection(!selectedSection);
  };
  return (
    <nav className="section-bar">
      <ul className="section-bar__wrapper">
        {sections.map((section) => {
          const isApplicationCompleted = applicationStatus === "Completed";
          const isEndOfFlow = stepData.stepCodeText === "EndOfFlow";
          const shouldRenderSectionItem =
            isDesktop ||
            !selectedSection ||
            isApplicationCompleted ||
            isEndOfFlow ||
            section.sectionCode === activeSection;

          return (
            shouldRenderSectionItem && (
              <SectionBarItem
                key={section.sectionCode}
                name={section.sectionLabel}
                icon={getSectionIcon(section.sectionCode)}
                state={getSectionState(activeSection, section)}
                onClick={
                  shouldRenderSectionItem && !isDesktop
                    ? () => handleSectionClick()
                    : undefined
                }
              />
            )
          );
        })}
      </ul>
    </nav>
  );
}

const getSectionIcon = (sectionCode: string) => {
  const section = sectionIcons.find((s) => s.name === sectionCode);
  if (section) {
    return section.icon;
  } else {
    throw new Error(`Section not found`);
  }
};

const getSectionState = (activeSection: string, section: Section) => {
  if (section.complete) {
    return SectionProgressState.Completed;
  } else {
    return activeSection === section.sectionCode
      ? SectionProgressState.InProgress
      : SectionProgressState.NotStarted;
  }
};

interface SectionBarItemProps {
  name: string;
  state: SectionProgressState;
  icon: IconDefinition;
  onClick?: () => void;
}

export const SectionBarItem = ({
  name,
  state,
  icon,
  onClick,
}: SectionBarItemProps) => {
  let sectionBarClassName;
  let iconElement;

  switch (state) {
    case SectionProgressState.Completed:
      sectionBarClassName = "section-bar__item--completed";
      iconElement = (
        <FontAwesomeIcon
          icon={solid("check-circle")}
          className="section-bar__item--completed__check"
        />
      );
      break;

    case SectionProgressState.NotStarted:
      sectionBarClassName = "section-bar__item--not-started";
      iconElement = (
        <FontAwesomeIcon
          icon={icon as IconProp}
          className="section-bar__item--not-started__uncheck"
        />
      );
      break;

    case SectionProgressState.InProgress:
      sectionBarClassName = "section-bar__item--inprogress";
      iconElement = (
        <FontAwesomeIcon
          icon={icon as IconProp}
          className="section-bar__item--inprogress__uncheck"
        />
      );
      break;
  }

  return (
      <li className="section-bar__item" onClick={onClick}>
        <span className={sectionBarClassName} data-testid="section-bar-item">
          <span className="section-bar__item--icon">{iconElement}</span>
          <span className="section-bar__item--name">{name}</span>
        </span>
      </li>
  );
};

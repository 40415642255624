import { GetAllVersions, useGetAllVersionsQuery } from './versionService'
import { ApplicationName, NH_INFO_VERSION, NH_VERSION } from 'utilities/Constant';
import "./version.scss"
import Overlay from 'components/ui/loading-spinners/Overlay/Overlay';

const Version = () => {
  const { data, isFetching } = useGetAllVersionsQuery();
  if (isFetching) return <Overlay />

  const frontEndValues: GetAllVersions = {
    applicationName: ApplicationName,
    version: NH_VERSION,
    informationalVersion: NH_INFO_VERSION
  }
  const versionData: GetAllVersions[] = [frontEndValues]
  const resultArray = data ? versionData.concat(data) : versionData;
  return (
    <div className='version__container'>
      <table>
        <thead>
          <tr className='listed__items'>
            <th>
              Application Name
            </th>
            <th>
              Version
            </th>
            <th>
              Informational Version
            </th>
          </tr>
        </thead>
        {resultArray?.map((obj) => {
          return (
            <tbody key={obj.applicationName + obj.version}>
              <tr className='listed__items'>
                <td >
                  {obj.applicationName}
                </td>
                <td>
                  {obj.version}
                </td>
                <td>
                  {obj.informationalVersion}
                </td>
              </tr>
            </tbody>
          )
        }
        )
        }
      </table>
    </div>
  )
}

export default Version
import React, { useState, useEffect } from "react";
import { Label, eyeIcon, Icon, SSNProps } from "./SSN";
import InPlaceError from "components/in-place-error/InPlaceError";
import "./ssn.scss";

const formatSSN = (value: string) => {
  const digits = value.replace(/\D/g, "");
  if (digits.length > 3 && digits.length <= 5) {
    return `${digits.slice(0, 3)}-${digits.slice(3)}`;
  } else if (digits.length > 5) {
    return `${digits.slice(0, 3)}-${digits.slice(3, 5)}-${digits.slice(5, 9)}`;
  }
  return digits;
};

const SsnForMobile = ({
  label,
  errorMessage,
  placeholder,
  isReadOnly,
  isDisabled,
  initialValue,
  onChange,
  onBlur,
}: SSNProps) => {
  const [ssn, setSsn] = useState("");
  const [isSSNVisible, setIsSSNVisible] = useState(false);

  useEffect(() => {
    if (initialValue) {
      setSsn(initialValue);
    }
  }, [initialValue]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formatted = formatSSN(e.target.value);
    setSsn(formatted);
    onChange?.(formatted);
  };

  return (
    <div className="ssn-container">
      {label && <Label label={label} />}
      <div className="ssn__wrapper">
        <input
          className={errorMessage ? "ssn ssn--error" : "ssn"}
          type={isSSNVisible ? "text" : "password"}
          id="ssn"
          name="ssn"
          placeholder={placeholder}
          value={ssn}
          onInput={handleInputChange}
          disabled={isDisabled}
          readOnly={isReadOnly}
          onBlur={onBlur}
          spellCheck={false}
          autoComplete="off"
          aria-label={label}
        />
        <Icon
          icon={eyeIcon(isSSNVisible)}
          isIconPointer
          onIconClick={() => setIsSSNVisible(!isSSNVisible)}
        />
        {errorMessage && <InPlaceError errorPhrase={errorMessage} />}
      </div>
    </div>
  );
};

export default SsnForMobile;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import React from "react";

import "./modal.scss";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import classNames from "classnames";

export interface IDefaultHeaderProps {
  header?: string;
  handleHide?: () => void;
  icon?: IconDefinition;
  shouldShowCloseButton?: boolean;
}

export const DefaultHeader = ({
  header,
  handleHide,
  icon,
  shouldShowCloseButton = true
}: IDefaultHeaderProps) => {
  return (
    <>
      <div className="modal__header--left">
        {icon && (
          <span className="circle circle--primary">
            <FontAwesomeIcon icon={icon} />
          </span>
        )}
        <h3>{header}</h3>
      </div>
      {shouldShowCloseButton && (
        <span className="close" onClick={handleHide}>
          <FontAwesomeIcon icon={regular("xmark")} />
        </span>
      )}
    </>
  );
};

export interface IDefaultBodyProps {
  label?: string;
  icon?: IconDefinition;
  subContents?: string[];
}

export const DefaultBody = ({
  label,
  icon,
  subContents,
}: IDefaultBodyProps) => {
  return (
    <div className="modal__body--content">
      <label className="modal__body--content__label">
        {icon && <FontAwesomeIcon icon={icon} />}
        {label}
      </label>
      <div className="modal__body--content__sub-content">
        {subContents?.map((content) => (
          <React.Fragment key={content}>
            <p>{content}</p>
            <br />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};


export interface IModalProps {
  readonly defaultHeader?: boolean;
  readonly defaultHeaderName?: string;
  readonly shouldShowCloseButton? : boolean;
  readonly defaultHeaderIcon?: IconDefinition;
  readonly defaultHandleHide?: () => void;
  readonly customHeader?: React.ReactNode;
  readonly defaultBody?: boolean;
  readonly defaultBodyLabel?: string;
  readonly defaultBodyLabelIcon?: IconDefinition;
  readonly defaultBodySubContent?: string[];
  readonly customBody?: React.ReactNode;
  readonly footer?: React.ReactNode;
  readonly className?: string;
  readonly footerContentPosition?: "left" | "center" | "right";
}

export default function Modal({
  defaultHeader,
  defaultHeaderIcon,
  shouldShowCloseButton,
  defaultHandleHide,
  defaultHeaderName,
  customHeader,
  defaultBody,
  defaultBodyLabel,
  defaultBodyLabelIcon,
  defaultBodySubContent,
  customBody,
  footer,
  className,
  footerContentPosition,
}: IModalProps) {
  const footerClass = classNames(
    "modal__footer",
    {
      "modal__footer--left": footerContentPosition === "left",
      "modal__footer--center": footerContentPosition === "center",
      "modal__footer--right": footerContentPosition === "right",
    },
    className
  );
  return (
    <div className="modal">
      <div className="modal__content">
        <div className="modal__header">
          {defaultHeader ? (
            <DefaultHeader
              header={defaultHeaderName}
              icon={defaultHeaderIcon}
              handleHide={defaultHandleHide}
              shouldShowCloseButton = {shouldShowCloseButton}
            />
          ) : (
            customHeader
          )}
        </div>

        <div className="modal__body">
          {defaultBody ? (
            <DefaultBody
              label={defaultBodyLabel}
              icon={defaultBodyLabelIcon}
              subContents={defaultBodySubContent}
            />
          ) : (
            customBody
          )}
        </div>
        {footer && <div className={footerClass}>{footer}</div>}
      </div>
    </div>
  );
}

import { useGetApplicationByIdQuery } from "pages/application/applicationService";
import {
  hasBlock,
  getBlockType,
} from "pages/application/templates/Summary/queries";
import { useSelector } from "react-redux";
import { getCurrentUserApplicationId } from "features/authSlice";

export const useGetIncomeQuery = (
  blockType: "financesApplicantIncome" | "financesSpouseCoApplicantIncome"
) => {
  const applicationId = useSelector(getCurrentUserApplicationId);
  return useGetApplicationByIdQuery(applicationId, {
    selectFromResult: ({ data }) => {
      if (!hasBlock(data, blockType)) {
        return {
          incomeLabel: "",
          name: "",
          incomes: [],
          addIncomeAction: undefined,
        };
      }
      const {
        label: incomeLabel,
        description: name,
        dataItems,
        actions,
      } = getBlockType(data, blockType);
      const incomes = dataItems.map(({ fields, actions }) => ({
        source: fields[0].value,
        amount: fields[1].value,
        editAction: actions.find(({ actionType }) => actionType === "edit")!,
        removeAction: actions.find(
          ({ actionType }) => actionType === "remove"
        )!,
      }));
      const addIncomeAction = actions.find(
        ({ actionType }) => actionType === "add"
      )!;
      return {
        incomeLabel,
        name,
        incomes,
        addIncomeAction,
      };
    },
  });
};
interface ElectronicallyVerifiedAsset {
  category: string;
  source: string;
  amount: string;
}

interface ManuallyListedAsset {
  category: string;
  source: string;
  amount: string;
  editAction: {
    stepActionId: string;
    label: string;
  };
  removeAction: {
    stepActionId: string;
    label: string;
  };
}

interface Assets {
  electronicallyVerifiedAssets: ElectronicallyVerifiedAsset[];
  manuallyListedAssets: ManuallyListedAsset[];
}

export const useGetAssetsQuery = () => {
  const applicationId = useSelector(getCurrentUserApplicationId);
  return useGetApplicationByIdQuery(applicationId, {
    selectFromResult: ({ data }) => {
      if (!hasBlock(data, "financesAssets")) {
        return {
          assetsLabel: "",
          electronicallyVerifiedAssets: [],
          manuallyListedAssets: [],
          addAssetAction: undefined,
        };
      }
      const {
        label: assetsLabel,
        dataItems,
        actions,
      } = getBlockType(data, "financesAssets");

      const assets = dataItems.reduce<Assets>(
        (acc, { label, fields, actions, dataItemType }) => {
          const isElectronicallyVerifiedAsset =
            dataItemType === "electronicallyVerifiedAsset";
          if (isElectronicallyVerifiedAsset) {
            const asset: ElectronicallyVerifiedAsset = {
              category: label,
              source: fields[0].value,
              amount: fields[1].value,
            };
            acc.electronicallyVerifiedAssets.push(asset);
          } else {
            const asset: ManuallyListedAsset = {
              category: label,
              source: fields[0].value,
              amount: fields[1].value,
              editAction: actions.find(
                ({ actionType }) => actionType === "edit"
              )!,
              removeAction: actions.find(
                ({ actionType }) => actionType === "remove"
              )!,
            };
            acc.manuallyListedAssets.push(asset);
          }
          return acc;
        },
        {
          electronicallyVerifiedAssets: [],
          manuallyListedAssets: [],
        }
      );

      const addAssetAction = actions.find(
        ({ actionType }) => actionType === "add"
      )!;

      return {
        assetsLabel,
        ...assets,
        addAssetAction,
      };
    },
  });
};

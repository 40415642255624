import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import "./disclaimer-text.scss";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface Disclaimer {
  Header: string;
  Text?: string;
  hasIcon?: boolean;
}

const DisclaimerText = ({ Header, Text, hasIcon }: Disclaimer) => {
  return (
    <div className="disclaimer">
      <div className="disclaimer--header">
        {hasIcon && (
          <span className="disclaimer--header__icon">
            <FontAwesomeIcon icon={faInfoCircle as IconProp} />
          </span>
        )}
        <span className="disclaimer--header__label">{Header}</span>
      </div>
      {Text && <div className="disclaimer--text">{Text}</div>}
    </div>
  );
};

export default DisclaimerText;

import InPlaceError from "components/in-place-error/InPlaceError";
import { Label } from "../input/Input";

export interface PhoneInputProps {
    label: string;
    errorMessage?: string;
    onChange?(phoneNumber: string): void;
    value?: string;
}

const PhoneNumberInput = ({
    label,
    errorMessage,
    onChange,
    value,
}: PhoneInputProps) => {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.(event.target.value)
    };

    return (
        <div className="input-container">
            {label && <Label label={label} />}
            <div className="input__wrapper">
                <input
                    type="text"
                    value={value}
                    onChange={handleChange}
                    className={`input ${errorMessage ? "input--error" : ""}`}
                    placeholder={`Enter ${label}`}
                />
                {errorMessage && <InPlaceError errorPhrase={errorMessage} />}
            </div>
        </div>
    );
};

export default PhoneNumberInput;

import useDesktopChecker from "hooks/useDesktopChecker";
import { Button } from "../button/Button";
import "./popup.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

export interface PopupProps {
  onClickContinue?: () => void;
  onClickLogout?: () => void;
  handleHide?: () => void;
  onShow?: boolean;
}

const Popup = ({
  onClickContinue,
  onClickLogout,
  onShow,
  handleHide,
}: PopupProps) => {
  const isDesktop = useDesktopChecker();
  return (
    <>
      {isDesktop ? (
        <div className={`popup ${onShow ? "popup--show" : "popup--hide"}`}>
          <div className="popup__content">
            <p>Hello! </p>
            <p>
              Your progress is automatically saved. Press continue to return to
              application or logout. You will not lose any information by
              logging out.
            </p>
          </div>
          <div className="popup__footer">
            <Button
              title="Continue"
              type="default-left"
              variant="secondary-three"
              onClick={onClickContinue}
            />
            <Button
              title="Logout"
              type="default-right"
              onClick={onClickLogout}
            />
          </div>
        </div>
      ) : (
        <div className="wrapper">
          <div className={`popup ${onShow ? "popup--show" : "popup--hide"}`}>
            <span className="popup__close" onClick={handleHide}>
              <FontAwesomeIcon icon={regular("xmark")} className="xmarkIcon" />
            </span>
            <div className="popup__content">
              <p className="popup__text1">Hello! </p>
              <p className="popup__text2">
                Your progress is automatically saved. Press continue to return
                to application or logout. You will not lose any information by
                logging out.
              </p>
            </div>
            <div className="popup__footer">
              <Button
                title="Continue"
                className="popup__footer--continue"
                onClick={onClickContinue}
              />
              <Button
                title="Logout"
                className="popup__footer--logout"
                variant="transparent"
                onClick={onClickLogout}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Popup;

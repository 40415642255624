import {
  StepResponse,
  useGetApplicationByIdQuery,
} from "pages/application/applicationService";
import { useSelector } from "react-redux";
import { getCurrentUserApplicationId } from "features/authSlice";

export const useGetApplicantQuery = () => {
  const applicationId = useSelector(getCurrentUserApplicationId);
  return useGetApplicationByIdQuery(applicationId, {
    selectFromResult: ({ data }) =>
      getBaseApplicantData(data, "creditApplicant", "primaryBorrower"),
  });
};

export const useGetSpouseCoApplicantQuery = () => {
  const applicationId = useSelector(getCurrentUserApplicationId);
  return useGetApplicationByIdQuery(applicationId, {
    selectFromResult: ({ data }) =>
      getBaseApplicantData(data, "creditSpouseCoApplicant", "borrowerSpouse"),
  });
};
const getBaseApplicantData = (
  data: StepResponse | undefined,
  blockType: "creditApplicant" | "creditSpouseCoApplicant",
  dataItemType: "primaryBorrower" | "borrowerSpouse"
) => {
  const { label: name, dataItems } = data!.blocks!.find(
    ({ blockType: thisBlockType }) => thisBlockType === blockType
  )!;

  const { fields, actions } = dataItems.find(
    ({ dataItemType: thisDataItemType }) => thisDataItemType === dataItemType
  )!;

  const details = {
    dateOfBirthLabel: fields[0].displayName,
    dateOfBirth: fields[0].value,
    socialSecurityNumberLabel: fields[1].displayName,
    socialSecurityNumber: fields[1].value,
    editDetails: actions[0],
  };

  return {
    name,
    details,
  };
};

import { createSlice, createSelector } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "store";
import {
  MutationResponse,
  StepResponse,
  applicationApi,
} from "pages/application/applicationService";

const initialState: MutationResponse = {
  validation: {
    success: true,
    message: "",
    inputErrors: [],
  },
  nextStep: {
    stepId: "",
    label: "",
    nextStepActions: [],
    stepType: "",
    inputs: [],
    hasPreviousStep: false,
    section: "Profile",
    stepCodeText: "",
    progress: 0,
    texts: [],
    sections: [],
  },
  actionMessage: "",
};

export const questionSlice = createSlice({
  name: "question",
  initialState,
  reducers: {
    setStepData: (state, action: PayloadAction<StepResponse>) => {
      state.nextStep = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      applicationApi.endpoints.getApplicationById.matchFulfilled,
      (state, action: PayloadAction<StepResponse>) => {
        state.nextStep = action.payload;
      }
    );

    builder.addMatcher(
      applicationApi.endpoints.setApplicationStepAnswer.matchFulfilled,
      (state, action: PayloadAction<MutationResponse>) => {
        state.validation = action.payload.validation;
        state.nextStep = action.payload.nextStep;
      }
    );

    builder.addMatcher(
      applicationApi.endpoints.setApplicationPreviousStep.matchFulfilled,
      (state, action: PayloadAction<StepResponse>) => {
        state.nextStep = action.payload;
      }
    );
  },
});

export const getStepData = createSelector(
  (state: RootState) => state.question,
  ({ nextStep }) => nextStep
);
export const { setStepData } = questionSlice.actions;
export default questionSlice.reducer;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import Checkbox from "components/ui/checkbox/Checkbox";
import "./tooltip.scss";
export interface IToolTip {
  contentHeading?:string
  content?: string;
  checkboxLabel?: string;
  position?: "up" | "down" | "left" | "right";
  btnToolTip?: boolean;
  isIconRequired?: boolean;
  isCheckBoxRequired?: boolean;
  handleClick?: () => void;
}

function ToolTip({
  contentHeading,
  content,
  checkboxLabel,
  position,
  isIconRequired,
  isCheckBoxRequired,
  handleClick,
}: IToolTip) {
  return (
    <>
      <div className="tooltip">
        <div className={position} data-testid="tooltip">
          <div className="tooltip--primary">
            {isIconRequired && (
              <span
                onClick={() => {
                  handleClick?.();
                }}
                className="tooltip--primary__icon"
                title="icon"
              >
                <FontAwesomeIcon icon={regular("xmark")} />
              </span>
            )}
            <span className="tooltip--primary__info">{contentHeading && <b>{`${contentHeading} :`}</b>} {content}</span>
            {isCheckBoxRequired && (
              <span>
                <Checkbox
                  label={checkboxLabel}
                  onChange={() => handleClick?.()}
                />
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ToolTip;

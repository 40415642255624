import "./round-badge.scss";
import classNames from "classnames";

export interface RoundBadgeProps {
  value?: string | number;
  variant?: "primary" | "secondary";
  size?: "large" | "small";
}

const RoundBadge = ({
  value,
  variant = "primary",
  size = "large",
}: RoundBadgeProps) => {
  const badgeClass = classNames("roundbadge", {
    "roundbadge--primary": variant === "primary",
    "roundbadge--secondary": variant === "secondary",
    "roundbadge--large": size === "large",
    "roundbadge--small": size === "small",
  });

  return (
    <div className={badgeClass}>
      <span className="roundbadge--value">{value}</span>
    </div>
  );
};

export default RoundBadge;

import React, { useState, useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import Modal from "components/modal/Modal";
import PasswordAndConfirmPassword from "components/password-and-confirm-password/PasswordAndConfirmPassword";
import { Button } from "components/ui/button/Button";
import Form from "components/ui/form/Form";
import { FormInput } from "components/ui/form/FormInput";
import { LoadingOverlay } from "components/ui/loading-spinners";
import { Select } from "components/ui/select/Select";
import {
  DecryptedUser,
  RegisterData,
  usePostRegistrationMutation,
} from "./registrationService";
import * as Constant from "utilities/Constant";
import {
  emailAddress,
  firstname,
  lastname,
  loanpurpose,
  phone,
  password_input,
  confirm_password,
} from "utilities/formSchemas";
import "./registration.scss";
import { useDispatch } from "react-redux";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { setCredentials, setInquiryId } from "features/authSlice";
import { ErrorType } from "utilities/error";
import nafhub from "assets/images/nafhub-white-background-logo.svg";
import { Consent } from "components/consent/Consent";

const formSchema = z.object({
  email: emailAddress,
  firstname,
  lastname,
  phone,
  loanpurpose,
  password_input,
  confirm_password,
});

export default function DefaultRegistration({
  decryptedUserDetails,
  showModal = false,
}: {
  readonly decryptedUserDetails?: DecryptedUser;
  readonly showModal?: boolean;
}) {
  const {
    handleSubmit,
    control,
    register,
    formState: { errors, dirtyFields },
    setValue,
    watch,
  } = useForm({
    resolver: zodResolver(formSchema),
  });

  useEffect(() => {
    if (decryptedUserDetails) {
      const { ex, fn, ln, em, ph, lp, los, id } = decryptedUserDetails;
      if (!ex) {
        if (los) {
          dispatch(setInquiryId(id));
        }
        setValue("firstname", fn);
        setValue("lastname", ln);
        setValue("email", em);
        setValue("phone", ph.replace(/-/g, ""));
        setValue("loanpurpose", lp);
      } else {
        navigate("/", { replace: true });
      }
    }
  }, [decryptedUserDetails]);

  const [error, setError] = useState(0);
  const navigate = useNavigate();
  const loanPurpose = watch("loanpurpose") as string | undefined;

  const renderMessage = () => {
    switch (loanPurpose) {
      case Constant.LoanPurposeNames.Purchase:
        return Constant.puchasePathGreeting;
      case Constant.LoanPurposeNames.Refinance:
        return Constant.refinancePathGreeting;
      default:
        return Constant.unknownPurposeGreeting;
    }
  };
  const [hasPwdError, setHasPwdError] = useState(false);

  const [sendRequest, { isSuccess, data, isLoading: isRegistrationLoading }] =
    usePostRegistrationMutation();
  const dispatch = useDispatch();
  const onSubmit = (data: RegisterData) => {
    if (!hasErrors && !hasPwdError) {
      sendRequest(data)
        .unwrap()
        .catch((error: ErrorType) => {
          if (error.status == 409) {
            navigate("/", { replace: true });
          } else {
            setError(500);
          }
        });
    }
  };
  if (isSuccess && data) {
    const hasLosFile = data.hasLosLoanNumber;
    dispatch(
      setCredentials({
        ...data,
        applicationId: "",
        applicationStatus: "",
        hasLosFile,
      })
    );
    navigate("/post-auth", { replace: true });
  }
  const ispasswordFieldModified =
    (dirtyFields.password_input as boolean) || false;
  const hasErrors = !!Object.keys(errors).length;
  if (isRegistrationLoading) return <LoadingOverlay text="Registering..." />;
  const formatContent = (content: any[]) => {
    return content?.map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <>
      <div className="container registration">
        <div className="header__wrapper">
          <div className="header__title">
            <span className="header__title--firstline">Welcome to </span>
            <span className="header__title--secondline">
              <img className="nafhub-logo" src={nafhub} alt="logo" />
            </span>
          </div>
          {loanPurpose && (
            <p className="loanPurposeMessage"> {renderMessage()}</p>
          )}
          <p className="sub-header">{formatContent(Constant.createPasswordGreeting)}</p>
          {error == 500 && (
            <div className="error__container">
              {Constant.RegistrationError.map((error) => {
                return (
                  <p key={error} className="error__message">
                    {error}
                  </p>
                );
              })}
            </div>
          )}
        </div>

        {showModal && (
          <Modal
            defaultBody
            defaultBodyLabel="Something went wrong!"
            defaultBodyLabelIcon={regular("triangle-exclamation")}
            defaultBodySubContent={[
              "Please open the email we sent you and click on the link so that we can try again.",
              "We apologize for the inconvenience.",
            ]}
            footer={<></>}
          />
        )}

        <Form
          onSubmit={handleSubmit((data) => {
            onSubmit({
              emailaddress: data.email as string,
              password: data.password_input as string,
              firstname: data.firstname as string,
              lastname: data.lastname as string,
              loanPurpose: data.loanpurpose as string,
              phoneNumber: data.phone as string,
              referralSource: "",
              referredBy: "someone",
              inquiryId: decryptedUserDetails?.id,
              leadProvider: decryptedUserDetails?.lpn,
              nhDealApplicationId: decryptedUserDetails?.nhDealAppId
            });
          })}
        >
          <div className="row row--hidden">
            <div className="row--item">
              <FormInput
                formHandle={register("firstname")}
                inputProps={{
                  name: "firstname",
                  placeholder: "Enter Your First Name",
                  label: "First Name",
                  errorMessage: errors.firstname?.message as string,
                }}
              />
            </div>
            <div className="row--item">
              <FormInput
                formHandle={register("lastname")}
                inputProps={{
                  name: "lastname",
                  placeholder: "Enter Your Last Name",
                  label: "Last Name",
                  errorMessage: errors.lastname?.message as string,
                }}
              />
            </div>
          </div>
          <div className="row--item">
            <FormInput
              formHandle={register("email")}
              inputProps={{
                name: "email",
                placeholder: "Enter Your Email",
                label: "Email/Username",
                errorMessage: errors.email?.message as string,
                isReadOnly: true,
              }}
            />
          </div>
          <div className="row--item">
            <FormInput
              formHandle={register("phone")}
              inputProps={{
                name: "phone",
                placeholder: "Enter Your Mobile Number",
                label: "Mobile Number",
                errorMessage: errors.phone?.message as string,
                isReadOnly: true,
              }}
            />
          </div>
          <div className="row--hidden">
            <Controller
              name="loanpurpose"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  initialValue={watch("loanpurpose") as string}
                  options={["Purchase", "Refinance"]}
                  placeholder="Select a Loan Purpose"
                  errorMessage={errors.loanpurpose?.message as string}
                  label="Loan Purpose"
                />
              )}
            />
          </div>
          <Controller
            name="password_input"
            control={control}
            render={({ field: { onChange } }) => (
              <PasswordAndConfirmPassword
                onChange={onChange}
                checkErrors={ispasswordFieldModified}
                createLabel="Create Password"
                confirmLabel="Confirm Password"
                setHasPasswordError={setHasPwdError}
                errorMessage={
                  (errors.password_input?.message as string)
                    ? Constant.REQUIRED_PASSWORD
                    : ""
                }
                confirmPasswordErrorMessage={
                  (errors.confirm_password?.message as string)
                    ? Constant.REQUIRED_PASSWORD_CONFIRMATION
                    : ""
                }
                setValue={setValue}
                confirmPasswordInputName="confirm_password"
                createPasswordPlaceholderName="Create Password"
              />
            )}
          />
          <Button title="Continue" className="signup-btn" />
        </Form>
      </div>
      <Consent
        termsOfUseLink={Constant.TERMSOFUSELINK}
        privacyLink={Constant.PRIVACYLINK}
        partnersLink={Constant.PARTNERSLINK}
        agreementLink={Constant.AGREEMENTLINK}
      />
    </>
  );
}

import React, { MouseEventHandler } from "react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InPlaceError from "components/in-place-error/InPlaceError";
import "./input.scss";
import classNames from "classnames";

export const Label = ({ label }: { label: string }) => {
  return (
    <div className="input-label__wrapper">
      <label htmlFor="label" className="input-label">
        {label}
      </label>
    </div>
  );
};

export const InputSubText = ({ subText }: { subText: string }) => {
  return <label className="input__subtext">{subText}</label>;
};

const Icon = ({
  icon,
  isIconPointer,
  onIconClick,
}: {
  icon: IconDefinition;
  isIconPointer?: boolean;
  onIconClick?: MouseEventHandler<HTMLSpanElement>;
}) => {
  return (
    <span
      className={`input--icon ${isIconPointer ? "input--icon--pointer" : ""}`}
      onClick={onIconClick}
    >
      <FontAwesomeIcon icon={icon} />
    </span>
  );
};

export interface InputProps {
  dataType?: string;
  icon?: IconDefinition;
  label?: string;
  subText?: string;
  placeholder?: string;
  errorMessage?: string;
  type?: string;
  maxLength?: number;
  onIconClick?: MouseEventHandler<HTMLSpanElement>;
  isIconPointer?: boolean;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  inputBorderHighlightForError?: boolean;
  name: string;
  className?: string;
  containerClassName?: boolean;
  onChange?(e: React.FormEvent<HTMLInputElement>): void;
  onBlur?(e: React.FormEvent<HTMLInputElement>): void;
  onFocus?(e: React.FormEvent<HTMLInputElement>): void;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      dataType,
      icon,
      label,
      subText,
      placeholder,
      errorMessage,
      inputBorderHighlightForError,
      type = "text",
      maxLength,
      onIconClick,
      isIconPointer = false,
      isReadOnly = false,
      isDisabled = false,
      name,
      onChange,
      onBlur,
      className,
      containerClassName,
      onFocus,
    }: InputProps,
    ref
  ) => {
    const inputClass = classNames(
      {
        input: true,
        "input--read-only": isReadOnly,
        "input--error": errorMessage,
        "input-error-border": inputBorderHighlightForError,
      },
      className
    );
    const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
      if (maxLength && maxLength > 0) {
        const inputValue = e.currentTarget.value;
        if (inputValue.length > maxLength) {
          e.currentTarget.value = inputValue.slice(0, 3);
        }
      }
    };
    const containerClassNames = classNames(
      {
        "input-container": true,
        "input-container input-city": dataType === "city",
        "input-container input-zipCode": dataType === "zipCode",
        "input-container-custom": containerClassName,
      },
      className
    );
    return (
      <div className={containerClassNames}>
        {label && <Label label={label} />}
        <div className="input__wrapper">
          <input
            name={name}
            ref={ref}
            onChange={onChange}
            onBlur={onBlur}
            className={inputClass}
            disabled={isDisabled}
            type={type}
            placeholder={placeholder}
            maxLength={maxLength}
            readOnly={isReadOnly ? true : false}
            aria-label={label}
            onInput={handleInput}
            onFocus={onFocus}
          />
          {icon && (
            <Icon
              icon={icon}
              isIconPointer={isIconPointer}
              onIconClick={onIconClick}
            />
          )}
          {errorMessage && <InPlaceError errorPhrase={errorMessage} />}
          {subText && <InputSubText subText={subText} />}
        </div>
      </div>
    );
  }
);

Input.displayName = "Input";

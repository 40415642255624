import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { NumericFormat } from "react-number-format";
import "./property-info.scss";
import useDesktopChecker from "hooks/useDesktopChecker";
export interface IPropertyInfoProps {
  street?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  loanNumber?: string;
  loanAmount?: number;
  loanType?: string;
}

export default function PropertyInfo({
  street,
  city,
  state,
  zipCode,
  loanNumber,
  loanAmount,
  loanType,
}: IPropertyInfoProps) {
  const totalCharacters =
    (street ? street.length : 0) +
    (city ? city.length : 0) +
    (state ? state.length : 0) +
    (zipCode ? zipCode.length : 0);

  const isDesktop = useDesktopChecker();

  return (
    <div className="property">
      <div className={`property-address ${totalCharacters > 50 ? "twoLinesDisplay" : ""}`}>
        <div className="property-address--primary">
          {isDesktop ?
            (
              <p className="property-address__address"><FontAwesomeIcon icon={solid("location-dot")} />{street ?? "TBD"}</p>
            ) : (
              <>
                <div className="property-address__icon">
                  <FontAwesomeIcon icon={solid("location-dot")} />
                </div>
                <div className="property-address__address">{street ?? "TBD"}</div>
              </>
            )
          }
        </div>
        {(city || state || zipCode) && <div className="property-address--secondary">
          {city && <span>{city},</span>}
          <span>{state}</span>
          <span>{zipCode}</span>
        </div>}
      </div>
      <div className="property-loan">
        <div className="property-loan-number">
          <span className="property-loan__label">loan#:</span>
          <span className="property-loan__data">
            {loanNumber ? loanNumber : "TBD"}
          </span>
        </div>
        <div className="property-loan-amount">
          <span className="property-loan__label">loan amount:</span>
          <span className="property-loan__data">
            {loanAmount ? (
              <NumericFormat
                value={loanAmount}
                displayType={"text"}
                thousandSeparator
                prefix={"$"}
              />
            ) : (
              "TBD"
            )}
          </span>
        </div>
        <div className="property-loan-type">
          <span className="property-loan__label">loan type:</span>
          <span className="property-loan__data">
            {loanType ? loanType : "TBD"}
          </span>
        </div>
      </div>
    </div>
  );
}

import "./status-bar.scss";

const clamp = (value: number, min = 0, max = 100) =>
  Math.min(Math.max(value, min), max);

const StatusBar = ({
  progressPercentage,
}: {
  progressPercentage: number;
}) => {
  return (
    <div className="status-bar">
      <div
        className="status-bar__shaded-progress"
        style={{ width: `${clamp(progressPercentage)}%` }}
        data-testid="status-bar"
      />
    </div>
  );
};

export default StatusBar;

import LoanOfficerHighlight from "components/footer/loan-officer-highlight/LoanOfficerHightlight";
import "./footer.scss";

export default function Footer() {
  let footerClassName = "footer";

  if (location.pathname === "/dashboard") {
    footerClassName = "footerdashboard";
  } else if (location.pathname === "/dashboard/documents") {
    footerClassName = "footerdocument";
  }

  return (
    <footer className={footerClassName}>
      <LoanOfficerHighlight />
      <TechSupport />
    </footer>
  );
}

export const TechSupport = () => {
  return (
    <div className="tech-supportContainer">
      <section className="tech-support">
        <span className="tech-support__phone-number">
          Technical Support (877) 214-4431
        </span>
        <span className="tech-support__hours--weekdays">
          Hours: Mon-Fri: 7AM - 6PM,
        </span>
        <span className="tech-support__hours--weekend">
          Sat: 8AM - 5PM, Sun: 11AM - 3PM PST
        </span>
      </section>
    </div>
  );
};

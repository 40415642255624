import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { light, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { UploadableFile, uploadProgressStatus } from "components/file-uploader/FileUploader";
import "./document-upload.scss";
import useDesktopChecker from "hooks/useDesktopChecker";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { useEffect, useState } from "react";
import StatusBar from "components/status-bar/StatusBar";

const iconMappings: { [key: string]: IconDefinition } = {
  jpeg: light("file-image"),
  jpg: light("file-image"),
  jpe: light("file-image"),
  doc: light("file-lines"),
  docx: light("file-lines"),
  default: light("file-pdf"),
};

export interface FileHeaderProps {
  readonly uploadableFile: UploadableFile;
  readonly onDelete: (file: UploadableFile) => Promise<void>;
}
export enum fileErrorType {
  "file-too-large",
  "file-invalid-type"
}
export default function DocumentUpload({
  uploadableFile,
  onDelete
}: FileHeaderProps) {
  const { file, errors, uploadProgress }: UploadableFile = uploadableFile;
  const className = errors.code === "" ? "" : "failed";
  const size = file.size / (1024 * 1024);
  const isDesktop = useDesktopChecker();
  const fileExtension = file.name.split(".").pop() ?? "";
  const iconName =
    iconMappings[fileExtension.toLowerCase()] ?? iconMappings.default;
  const [progressPercentage, setprogressPercentage] = useState<number>(0);
  const fileNames = file.name.slice(0, 20)+"..."

  if (errors.code === fileErrorType[0]) {
    errors.code = "File too large. Try compressing file.";
    errors.message = "File too large. Try compressing your file.";
  } else if (errors.code === fileErrorType[1]) {
    errors.message = "File type invalid";
    errors.code = "File type invalid";
  }

  useEffect(() => {
    const startTime = Date.now();
    const startTimer = setInterval(() => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - startTime;
      const progress = Math.min((elapsedTime / 5000) * 100, 95);
      if (progressPercentage < progress && uploadProgress !== uploadProgressStatus.uploaded) {
        setprogressPercentage(progress);
      }
    }, 200);
    if (uploadProgress === uploadProgressStatus.uploaded) {
      setprogressPercentage(100);
      clearInterval(startTimer);
    }
    return () => {
      clearInterval(startTimer);
    };
  }, [uploadProgress]);

  return (
    <div
      className={
        "document-upload" +
        (className ? " document-upload__" + `${className}` : "")
      }
    >
      <div className="document-upload__container">
        <FontAwesomeIcon className="document-upload__container--icon" icon={iconName} />
        <p className="document-upload__container--file-name">{fileNames}</p>
        {errors.message != "" && (
          <FontAwesomeIcon
            className="document-upload__container--exclamation"
            icon={light("triangle-exclamation")}
          />
        )}
      </div>
      <div className="document-upload__file">
        <p className="document-upload__file--size">
          {size.toFixed(2)}
          <p>MB</p>
        </p>
        {isDesktop ? (
          <p className="document-upload__file--status">{errors.message}</p>
        ) : (
          <p className="document-upload__file--status">{errors.code}</p>
        )}
        {isDesktop ? errors.message != "" && (
          <p className="document-upload__file--message">File not attached</p>
        ) : ("")}
      </div>

      {uploadProgress !== uploadProgressStatus.uploaded && errors.code == "" && (
        <div className="document-upload__status-bar">
          <StatusBar progressPercentage={progressPercentage} />
        </div>
      )}

      <div className="document-upload__cancel">
        {uploadProgress === uploadProgressStatus.uploaded && (
          <div className="document-delete-container">
            <FontAwesomeIcon
              className="document-upload__cancel--check"
              icon={regular("check")}
            />
            <FontAwesomeIcon
              className="document-delete-icon"
              icon={regular("trash-can")}
              onClick={() => onDelete(uploadableFile)}
            />
          </div>
        )
      }
      </div>
    </div>
  );
}

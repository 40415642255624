import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "components/ui/button/Button";
import Form from "components/ui/form/Form";
import {
  confirm_password,
  emailAddress,
  password_input,
  recovery_code,
} from "utilities/formSchemas";
import { FormInput } from "components/ui/form/FormInput";
import PasswordAndConfirmPassword from "components/password-and-confirm-password/PasswordAndConfirmPassword";
import {
  RecoverPasswordData,
  usePostResetMutation,
} from "./recoverPasswordService";
import "./recover-password.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { LoadingOverlay } from "components/ui/loading-spinners";
import { ResetPasswordError, ResetPasswordCodeError } from "utilities/Constant";
import { ErrorType } from "utilities/error";
import * as Constant from "utilities/Constant";

const formSchema = z.object({
  email: emailAddress,
  recoverycode: recovery_code,
  password_input: password_input,
  confirm_password
});

export default function RecoverPassword() {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError: setErrorInForm,
    resetField,
    formState: { errors, dirtyFields },
  } = useForm({ resolver: zodResolver(formSchema) });
  const [error, setError] = useState(0);
  const [searchParams] = useSearchParams();
  const [hasPwdError, setHasPwdError] = useState(false);
  const encryptedUser = searchParams.get("t")!;
  const ispasswordFieldModified =
    (dirtyFields.password_input as boolean) || false;
  const hasErrors = !!Object.keys(errors).length;
  const [sendRequest, { data, isLoading, isSuccess }] = usePostResetMutation();
  const navigate = useNavigate();
  const onSubmit = (data: RecoverPasswordData) => {
    if (!hasErrors && !hasPwdError) {
      sendRequest(data)
        .unwrap()
        .catch((error: ErrorType) => {
          if (
            error.status == 400 ||
            error.status == 404 ||
            error.status == 500
          ) {
            navigate("/", { replace: true });
          } else {
            setError(error.status);
          }
        });
    }
  };
  if (isSuccess && data === true) {
    navigate("/", { replace: true });
  }
  setValue("email", encryptedUser?.replace(' ','+'));
  useEffect(() => {
    if (error == 406) {
      resetField("recoverycode");
      setErrorInForm("recoverycode", {
        type: "custom",
        message: "Invalid Code",
      });
    }
  }, [error == 406]);
  if (isLoading) return <LoadingOverlay text="Loading..." />;
  return (
    <div className="recover-password">
      <div className="recover-password-header">
        <span className="recover-password-header_title">Reset Password</span>
        <p className="recover-password-header_para">
          You have requested to reset your password
        </p>
        {error == 409 && (
          <p className="recover-password-header_error">{ResetPasswordError}</p>
        )}
        {error == 406 && (
          <p className="recover-password-header_error">
            {ResetPasswordCodeError}
          </p>
        )}
      </div>{" "}
      <div className="recover-password-form">
        <Form
          onSubmit={handleSubmit((data) => {
             onSubmit({
              emailAddress: data.email as string,
              newPassword: data.password_input as string,
              recoveryCode: data.recoverycode as string,
            });
          })}
        >
          <FormInput
            formHandle={register("email")}
            inputProps={{
              name: "email",
              placeholder: encryptedUser?.replace(' ','+'),
              label: "Email",
              errorMessage: errors.email?.message as string,
              isReadOnly: true,
            }}
          />
          <FormInput
            formHandle={register("recoverycode")}
            inputProps={{
              name: "recoverycode",
              placeholder: "Password Reset Code",
              label: "Password Reset Code",
              errorMessage: errors.recoverycode?.message as string,
            }}
          />
          <Controller
            name="password_input"
            control={control}
            render={({ field: { onChange } }) => (
              <PasswordAndConfirmPassword
                onChange={onChange}
                setHasPasswordError={setHasPwdError}
                checkErrors={ispasswordFieldModified}
                createLabel="Create New Password"
                confirmLabel="Confirm New Password"
                errorMessage={errors.password_input?.message as string ? Constant.REQUIRED_PASSWORD : ""}
                confirmPasswordErrorMessage={errors.confirm_password?.message as string ? Constant.REQUIRED_PASSWORD_CONFIRMATION : ""}
                setValue={setValue}
                confirmPasswordInputName="confirm_password"
              />
            )}
          />
          <Button title="Reset Password" />
        </Form>
      </div>
    </div>
  );
}

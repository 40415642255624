import "./text-area.scss";

export interface MaxCharacterProps {
  contentLength: number;
  limit: number;
}

const MaxCharacter = ({ contentLength, limit }: MaxCharacterProps) => {
  return (
    <div>
      <span>
        {contentLength}/{limit} max characters
      </span>
    </div>
  );
};

export default MaxCharacter;

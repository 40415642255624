import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "services/common/rtkQueryConfig";

export const welcomePathApi = createApi({
  reducerPath: "welcomePathApi",
  baseQuery: baseQueryWithAuth,
  endpoints: (build) => ({
    createApplication: build.mutation<string, CreateApplicationTemplateRequest>(
      {
        query: (body: CreateApplicationTemplateRequest) => ({
          url: "/Application/createTemplate",
          method: "POST",
          body,
        }),
      }
    ),
    getWelcomePaths: build.query<Paths[], void>({
      query: () => `/Application/paths`,
    }),
  }),
});
export const { useCreateApplicationMutation, useGetWelcomePathsQuery } =
  welcomePathApi;

export interface CreateApplicationTemplateRequest {
  userId: string;
  pathType: string;
}

interface Paths {
  id: number;
  title: string;
  image: string;
  description: string;
  timeDescription: string;
  path: string;
}

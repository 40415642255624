import {
  StepResponse,
  BlockType,
  useGetApplicationByIdQuery,
} from "pages/application/applicationService";
import { AddressModel } from "pages/application/templates/Summary/Profile";
import { useSelector } from "react-redux";
import { getCurrentUserApplicationId } from "features/authSlice";

export const useGetApplicantQuery = () => {
  const applicationId = useSelector(getCurrentUserApplicationId);
  return useGetApplicationByIdQuery<ApplicantModel>(applicationId, {
    selectFromResult: ({ data }) =>
      getBaseApplicantData(data, "profileApplicant", "primaryBorrower"),
  });
};

export const useGetSpouseCoApplicantQuery = () => {
  const applicationId = useSelector(getCurrentUserApplicationId);
  return useGetApplicationByIdQuery(applicationId, {
    selectFromResult: ({ data }) => {
      const {
        applicantLabel: spouseCoApplicantLabel,
        applicantName: spouseCoApplicantName,
        primaryAddress,
        mailingAddress,
      } = getBaseApplicantData(
        data,
        "profileSpouseCoApplicant",
        "profileSpouse"
      );

      const spouseCoApplicantBlock = data!.blocks!.find(
        ({ blockType }) => blockType === "profileSpouseCoApplicant"
      )!;

      const spouseCoApplicantEmail = spouseCoApplicantBlock.dataItems.find(
        ({ dataItemType }) => dataItemType === "profileSpouse"
      );

      const addSpouseAction = spouseCoApplicantBlock.actions.find(
        ({ actionType }) => actionType === "add"
      );

      return {
        spouseCoApplicantLabel,
        spouseCoApplicantName,
        spouseCoApplicantEmail:
          spouseCoApplicantEmail?.fields[
            spouseCoApplicantEmail.fields.length - 1
          ].value ?? "",
        primaryAddress,
        mailingAddress,
        editDetails: spouseCoApplicantEmail?.actions.find(
          ({ actionType }) => actionType === "edit"
        ),
        remove: spouseCoApplicantEmail?.actions.find(
          ({ actionType }) => actionType === "remove"
        ),
        addSpouseAction,
      };
    },
  });
};

interface CoApplicantModel {
  name: string;
  email: string;
  editAction: {
    stepActionId: string;
    label: string;
  };
  removeAction: {
    stepActionId: string;
    label: string;
  };
}

export const useGetNonSpouseCoApplicantQuery = () => {
  const applicationId = useSelector(getCurrentUserApplicationId);
  return useGetApplicationByIdQuery(applicationId, {
    selectFromResult: ({ data }) => {
      if (!data?.blocks) throw new Error("No data returned from query");

      const { label, dataItems, actions } = data.blocks.find(
        ({ blockType }) => blockType === "profileNonSpouseCoApplicant"
      )!;
      let coApplicants: CoApplicantModel[] = [];
      if (!dataItems?.length) {
        const addCoApplicantAction = actions.find(
          ({ actionType }) => actionType === "add"
        );
        return {
          coApplicantLabel: label,
          coApplicants,
          addCoApplicantAction,
        };
      }

      coApplicants = dataItems.reduce<CoApplicantModel[]>((acc, dataItem) => {
        if (dataItem.dataItemType === "coBorrower") {
          const editAction = dataItem.actions.find(
            ({ actionType }) => actionType === "edit"
          )!;
          const removeAction = dataItem.actions.find(
            ({ actionType }) => actionType === "remove"
          )!;

          acc.push({
            name: dataItem.fields
              .slice(0, 2)
              .map(({ value }) => value)
              .join(" "),
            email: dataItem.fields[dataItem.fields.length - 1].value,
            editAction,
            removeAction,
          });
        }
        return acc;
      }, []);

      const addCoApplicantAction = actions.find(
        ({ actionType }) => actionType === "add"
      );

      return {
        coApplicantLabel: label,
        coApplicants,
        addCoApplicantAction,
      };
    },
  });
};

interface ApplicantModel {
  applicantLabel: string;
  applicantName: string;
  primaryAddress: AddressModel;
  mailingAddress: AddressModel;
}

const getBaseApplicantData = (
  data: StepResponse | undefined,
  applicantType: BlockType,
  applicantNameDataItemType: "primaryBorrower" | "profileSpouse" | "coBorrower"
): ApplicantModel => {
  if (!data?.blocks) throw new Error("No data returned from query");

  const { label, dataItems } = data.blocks.find(
    ({ blockType }) => blockType === applicantType
  )!;

  const applicantNameDataItem = dataItems.find(
    ({ dataItemType }) => dataItemType === applicantNameDataItemType
  );
  const applicantName =
    applicantNameDataItem?.fields
      .slice(0, 2)
      .map(({ value }) => value)
      .join(" ") ?? "";

  const primaryAddressDataItem = dataItems.find(
    ({ dataItemType }) => dataItemType === "primaryAddress"
  );
  let street = primaryAddressDataItem?.fields[0]?.value ?? "";
  let city = primaryAddressDataItem?.fields[1]?.value ?? "";
  let state = primaryAddressDataItem?.fields[2]?.value ?? "";
  let zip = primaryAddressDataItem?.fields[3]?.value ?? "";

  const primaryAddress: AddressModel = {
    addressLabel: primaryAddressDataItem?.label ?? "",
    addressLine1: street,
    addressLine2: `${city}, ${state} ${zip}`,
    ...(primaryAddressDataItem?.actions[0] ?? {
      stepActionId: "",
      label: "",
      actionType: "",
      presentationOrder: 0,
    }),
  };

  const mailingAddressDataItem = dataItems.find(
    ({ dataItemType }) => dataItemType === "mailingAddress"
  );

  street = mailingAddressDataItem?.fields[0]?.value ?? "";
  city = mailingAddressDataItem?.fields[1]?.value ?? "";
  state = mailingAddressDataItem?.fields[2]?.value ?? "";
  zip = mailingAddressDataItem?.fields[3]?.value ?? "";

  const mailingAddress: AddressModel = {
    addressLabel: mailingAddressDataItem?.label ?? "",
    addressLine1: street,
    addressLine2: `${city}, ${state} ${zip}`,
    ...(mailingAddressDataItem?.actions[0] ?? {
      stepActionId: "",
      label: "",
      actionType: "",
      presentationOrder: 0,
    }),
  };

  return {
    applicantLabel: label,
    applicantName,
    primaryAddress,
    mailingAddress,
  };
};

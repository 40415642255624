import { useCallback, useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import "./cash-input.scss";
import InPlaceError from "components/in-place-error/InPlaceError";
import { Label } from "../input/Input";

export interface CashInputProps {
  label?: string;
  errorMessage?: string;
  onChange?(amount: number): void;
  initialValue?: number | string;
}

const CashInput = ({
  label,
  errorMessage,
  onChange,
  initialValue,
}: CashInputProps) => {
  const [amount, setAmount] = useState<number | string>(0);

  useEffect(() => {
    if (initialValue !== undefined) {
      setAmount(initialValue);
    }
  }, [initialValue]);

  const handleChange = (amount: number) => {
    handleAmountChange(amount);
    if (onChange) {
      onChange(amount);
    }
  };
  const handleAmountChange = useCallback((amount: number) => {
      setAmount(amount);
  }, []);

  return (
    <div className="currency-container">
      {label && <Label label={label} />}
      <div className="currency__wrapper">
        <CurrencyInput
          className={errorMessage ? "currency currency--error" : "currency"}
          id="input-amount"
          name="input-amount"
          prefix="$"
          placeholder="$"
          decimalsLimit={2}
          maxLength={20}
          value={amount}
          aria-label={label}
          onValueChange={(amount) => handleChange(Number(amount) || 0)}
        />
        {errorMessage && <InPlaceError errorPhrase={errorMessage} />}
      </div>
    </div>
  );
};

export default CashInput;

import { z } from "zod";
import { currentDate, getYearsBeforeCurrentYear } from "utilities/date";

import * as Constant from "./Constant";

export const emailAddress = z
  .string()
  .min(1, { message: Constant.REQUIRED_EMAIL })
  .email({ message: Constant.INVALID_EMAIL_ERROR });

export const spouse_emailAddress = z
  .string()
  .min(1, { message: "Please enter your spouse's email address" })
  .email({ message: Constant.INVALID_EMAIL_ERROR });

export const coBorrower_emailAddress = z
  .string()
  .min(1, { message: "Please enter your co-borrower's email address" })
  .email({ message: Constant.INVALID_EMAIL_ERROR });

export const password_input = z
  .string()
  .min(1, { message: Constant.REQUIRED_PASSWORD });

export const confirm_password = z
  .string()
  .min(1, { message: Constant.REQUIRED_PASSWORD_CONFIRMATION });

export const firstname = z
  .string()
  .min(1, { message: "Please enter your first name" });

export const lastname = z
  .string()
  .min(1, { message: "Please enter your last name" });

const phoneTransform = (val: string) => {
  let output: string | undefined;

  if (!Constant.REGEX_PHONE_PROHIBITED_CHARACTERS.test(val)) {
    const okCharsRegex = new RegExp(
      Constant.REGEX_PHONE_PERMITTED_CHARACTERS,
      "g"
    );
    output = val.replace(okCharsRegex, "");

    if (output) {
      const lengthRegex = new RegExp(Constant.REGEX_PHONE_PERMITTED_LENGTH);
      output = lengthRegex.exec(output)?.[1];
    }
  }

  return output;
};

export const phone = z
  .string()
  .min(1, { message: Constant.REQUIRED_PHONE_ERROR })
  .transform(phoneTransform)
  .refine((output) => !!output, {
    message: Constant.INVALID_PHONE_ERROR,
  });

export const spouse_phone = z
  .string()
  .min(1, { message: Constant.REQUIRED_SPOUSE_PHONE_ERROR })
  .transform(phoneTransform)
  .refine((output) => !!output, {
    message: Constant.INVALID_PHONE_ERROR,
  });

export const coBorrower_phone = z
  .string()
  .min(1, { message: Constant.REQUIRED_COBORROWER_PHONE_ERROR })
  .transform(phoneTransform)
  .refine((output) => !!output, {
    message: Constant.INVALID_PHONE_ERROR,
  });

export const loanpurpose = z.string({
  required_error: "Please select a loan purpose",
});

export const otp = z
  .string({ required_error: "Verification code is required" })
  .min(6, "Invalid Code");

export const monthandyear = z.object({
  month: z.string().min(1, "Please select a month"),
  year: z.string().min(1, "Please select a year"),
});

export const ssn = z
  .string()
  .min(1, `Please enter your social security number`)
  .regex(
    /^((\d{3}-\d{2}-\d{4})|(X{3}-X{2}-X{4}))$/,
    "Please enter a valid social security number "
  );

export const spouse_ssn = z
  .string()
  .min(1, `Please enter your spouse's social security number`)
  .regex(
    /^((\d{3}-\d{2}-\d{4})|(X{3}-X{2}-X{4}))$/,
    "Please enter a valid social security number "
  );

export const zipCode = z
  .string()
  .min(1, `Please enter your zip code`)
  .regex(/^(\d{5}([- /]?\d{4})?$)/, "Please enter a valid zip code");

export const spouse_zipCode = z
  .string()
  .min(1, `Please enter your spouse's zip code`)
  .regex(/^(\d{5}([- /]?\d{4})?$)/, "Please enter a valid zip code");

export const recovery_code = z
  .string()
  .min(1, { message: Constant.REQUIRED_CODE });

export const dateInput = z
  .string()
  .regex(/^(0[1-9]|1[0-2])\/\d{4}$/, "Please enter your valid month and Year");

export type Schema = Record<string, z.ZodTypeAny>;

export const date = z
  .string()
  .refine((value) => value.trim() !== "", {
    message: "Please enter your date of birth",
  })
  .refine(
    (value) => {
      const parsedDate = new Date(value);
      const minDate = getYearsBeforeCurrentYear(120);
      return (
        parsedDate >= minDate &&
        parsedDate <= currentDate &&
        !isNaN(parsedDate.getTime())
      );
    },
    {
      message: "Please enter a valid date of birth",
    }
  );

export const spouse_date = z
  .string()
  .refine((value) => value.trim() !== "", {
    message: "Please enter your spouse's date of birth",
  })
  .refine(
    (value) => {
      const parsedDate = new Date(value);
      const minDate = getYearsBeforeCurrentYear(120);
      return (
        parsedDate >= minDate &&
        parsedDate <= currentDate &&
        !isNaN(parsedDate.getTime())
      );
    },
    {
      message: "Please enter a valid date of birth",
    }
  );

export const termSelectGroup = z
  .string()
  .min(1, `Please choose monthly or annual`);

export const currency = z.preprocess(
  (a) => Number(a) || 0,
  z.number().gt(0)
);

export const schemas: Schema = {
  emailAddress,
  spouse_emailAddress,
  coBorrower_emailAddress,
  phone,
  spouse_phone,
  coBorrower_phone,
  monthandyear,
  ssn,
  spouse_ssn,
  date,
  spouse_date,
  dateInput,
  recovery_code,
  zipCode,
  spouse_zipCode,
  termSelectGroup,
  currency,
};

import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import InPlaceError from "components/in-place-error/InPlaceError";
import { Label } from "components/ui/input/Input";
import { useDebounce } from "hooks/useDebounce";
import { applicationApi } from "pages/application/applicationService";
import { AppDispatch } from "store";
import ToolTip from "components/ui/tooltip/ToolTip";
import "./auto-suggestion.scss";

export interface IOptions {
  placeholder?: string;
  errorMessage?: string;
  label?: string;
  preFilledValue?: string;
  setOptionData?: (value: string) => void;
  onChange?: (e: string) => void;
  isSameAsMineChecked?: boolean;
}

export const AutoSuggestion = React.forwardRef<HTMLInputElement, IOptions>(
  (
    {
      placeholder,
      errorMessage,
      label,
      onChange,
      setOptionData,
      preFilledValue = "",
      isSameAsMineChecked,
    }: IOptions,
    ref
  ) => {
    const dispatch = useDispatch<AppDispatch>();
    const [selectedItem, setSelectedItem] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [isOverflow, setIsOverflow] = useState(false); // State to check for overflow
    const { data: options } =
      applicationApi.endpoints.getAddressSuggestions.useQueryState(searchValue);
    const showError = errorMessage ? true : false;
    const searchQuery = useDebounce(searchValue, 500);

    const dropdownRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null); // Ref for the input element

    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
      const handleDocumentClick = (event: MouseEvent) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target as Node)
        ) {
          setIsDropdownVisible(false);
        }
      };

      document.addEventListener("click", handleDocumentClick);

      return () => {
        document.removeEventListener("click", handleDocumentClick);
      };
    }, []);
    useEffect(() => {
      if (searchQuery && searchQuery.length > 3) {
        void dispatch(
          applicationApi.endpoints.getAddressSuggestions.initiate(searchQuery)
        );
        setIsDropdownVisible(true);
      } else {
        setIsDropdownVisible(false);
      }
      if (isSameAsMineChecked) {
        setSearchValue("");
        setSelectedItem("");
      }
    }, [searchQuery, isSameAsMineChecked]);

    const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
      setShowTooltip(false);
      const value = e.currentTarget.value;
      onChange?.(value);
      setSearchValue(value);

      // Check if the input text overflows
      const inputElement = inputRef.current;
      if (inputElement) {
        const overflow = inputElement.scrollWidth > inputElement.clientWidth
        setIsOverflow(overflow);
        if (overflow) {
          setShowTooltip (true);
        }
      }
    };

    const setInputValue = () => {
      if (preFilledValue === searchValue) {
        return preFilledValue;
      } else if (preFilledValue) {
        return preFilledValue;
      } else if (selectedItem) {
        return selectedItem;
      } else {
        return searchValue;
      }
    };

    useEffect(() => {
      // Check for overflow initially if pre-filled values are present
      const inputElement = inputRef.current;
      if (inputElement) {
        setIsOverflow(inputElement.scrollWidth > inputElement.clientWidth);
      }
    }, [preFilledValue, selectedItem]);

    return (
      <div className="auto-suggestion">
        {label && <Label label={label} />}
        <div className="dropdown__wrapper" ref={dropdownRef}>
          <input
            ref={inputRef}
            className={showError ? "dropdown dropdown--error" : "dropdown"}
            tabIndex={0}
            placeholder={placeholder}
            onChange={handleInputChange}
            value={setInputValue()}
            aria-label={label}
            onMouseEnter={()=>setShowTooltip(true)}
            onMouseLeave={()=>setShowTooltip(false)}
          />
          {showTooltip && isOverflow && setInputValue() && (
            <ToolTip
              content={setInputValue()}
              position="up"
              isIconRequired={false}
            />
          )}

          {isDropdownVisible && (
            <div className="dropdown-items">
              {options?.map((item, index) => (
                <div
                  key={index}
                  className="select-item"
                  onClick={() => {
                    onChange?.(item.split(",")[0]);
                    setOptionData?.(item);
                    setSelectedItem(item.split(",")[0]);
                    setIsDropdownVisible(false);
                    setSearchValue("");
                  }}
                >
                  {item}
                </div>
              ))}
            </div>
          )}

          {showError && <InPlaceError errorPhrase={errorMessage} />}
        </div>
      </div>
    );
  }
);

AutoSuggestion.displayName = "AutoSuggestion";

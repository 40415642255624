import "./toast-message.scss";
import { ToastContainer, toast, Zoom } from "react-toastify";
interface ToastMessageProps {
  message: string;
  timeout?: number;
  state: "success" | "error" | "info" | "warning";
  position?:
    | "top-left"
    | "top-right"
    | "top-center"
    | "bottom-left"
    | "bottom-right"
    | "bottom-center";
}

const ToastMessage = ({
  message,
  timeout = 1000,
  state,
  position = "top-left",
}: ToastMessageProps) => {
  const showToast = () => {
    toast(message, {
      theme: "colored",
      type: state,
      autoClose: timeout,
      closeButton: false,
      hideProgressBar: true,
      transition: Zoom,
      position: position,
      toastId: Date.now(),
    });
  };
  showToast();
  return <ToastContainer />;
};

export default ToastMessage;

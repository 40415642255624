import "./funded-state.scss";
import { Button } from "components/ui/button/Button";
import * as Constants from "utilities/Constant";
export interface IFundedState {
  userName: string;
}
import useDesktopChecker from "hooks/useDesktopChecker";

export default function FundedState({
  userName,
}: IFundedState) {
  const isDesktop = useDesktopChecker();
  const manageLoanMessageDesktop = "You can now go to Loan Servicing by clicking Manage My Loans below.";
  const manageLoanMessageMobile = "You can now go to Loan Servicing by clicking Manage My Loan below.";
  return (
    <div className="FundedState">
      <div className="FundedState__congratulations">CONGRATULATIONS {userName}</div>
      <div className="FundedState__info1">Your loan has been funded.</div>
      <div className="FundedState__info2">{isDesktop ? manageLoanMessageDesktop : manageLoanMessageMobile}</div>
      <a href={Constants.MortgageButton}>
        <Button title="Manage My Loans" />
      </a>
    </div>
  );
}
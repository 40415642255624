import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { BASE_BFF_WEB_URL } from "utilities/Constant";

export interface RecoverPasswordData {
  emailAddress: string;
  newPassword: string;
  recoveryCode: string;
}

export const recoverApi = createApi({
  reducerPath: "recoverApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_BFF_WEB_URL }),
  endpoints: (build) => ({
    postReset: build.mutation<boolean, RecoverPasswordData>({
      query: (body: RecoverPasswordData) => ({
        url: "/Account/recoverpassword",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { usePostResetMutation } = recoverApi;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { getMonthName } from "utilities/date";
import { data } from "./data";
import ToolTip from "../tooltip/ToolTip";
import "./loan-progress-bar.scss";
import {
  LoanStatusType,
  LoanSubStatusType,
} from "pages/dashboard/home/dashboardService";
import GuideToolTip from "../guideToolTip/GuideToolTip";
import { tooltipIndexValues } from "pages/dashboard/home/Dashboard";

interface LoanProgressBarProps {
  status: LoanStatusType;
  currentStatus?: LoanSubStatusType;
  closingDate?: Date | string;
  showTooltip?: boolean;
  setTooltipIndex?: (index: number) => void;
}

const LoanProgessBar = ({
  status,
  currentStatus,
  closingDate,
  showTooltip,
  setTooltipIndex
}: LoanProgressBarProps) => {
  const ProgressBarStates: Record<LoanStatusType, number> = {
    Unknown: 0,
    Started: 1,
    Processing: 2,
    Underwriting: 3,
    Approval: 4,
    FinalReview: 5,
    Close: 6,
    Funded: 7,
  };

  const STATUS_UNKNOWN = "Unknown";
  const closingDateAsDate =
    typeof closingDate === "string" ? new Date(closingDate) : closingDate;
  const date = closingDateAsDate
    ? `${closingDateAsDate.getDate()} ${getMonthName(
        closingDateAsDate
      )}, ${closingDateAsDate.getFullYear()}`
    : "TBD";

  const statusValue = ProgressBarStates[status];
  for (const state in data) {
    const activeState = state as LoanStatusType;
    data[activeState].isActive = ProgressBarStates[activeState] <= statusValue;
  }
  const getActiveStateLabel = () => {
    if (currentStatus && data[status].subStatus) {
      return data[status].subStatus![currentStatus].label;
    }
    return data[status].label;
  };
  const width = data[status].width ?? 0;
  const { title: activeStateTitle, description: activeStateDescription } =
    getActiveStateLabel()!;
  return (
    <>
      <div className="close_date_details">
        Projected Close Date{" "}
        <span>
          {" "}
          <b>{date}</b>{" "}
        </span>
      </div>
      <div className={`progress_bar_container ${showTooltip ? "highlight" : ""}`}>
        <div className="progress_bar">
          <div className="progress_bar_icon_group">
            {Object.entries(data).map(
              ([key, { label, isActive, icon }]) =>
                key != "Unknown" && (
                  <IconDoubleCircle
                    key={key}
                    label={key == status ? getActiveStateLabel() : label}
                    icon={icon}
                    iconStatus={isActive}
                  />
                )
            )}
          </div>
          <div className="progress_bar_bg" data-testid="progress-bar">
            <div
              className="progress_bar_bg_active"
              style={{ width: `${width}%` }}
              data-testid="progress-bar-active"
            ></div>
          </div>
          {status != STATUS_UNKNOWN && (
            <div className="icon_label_sm">
              <FontAwesomeIcon
                className="icon_label_sm_icon"
                icon={solid("angle-right")}
              />
              <label>
                <b>{activeStateTitle} : </b>
                {activeStateDescription}
              </label>
            </div>
          )}
        </div>
        {showTooltip && <div className="tooltip-popup">
          <GuideToolTip
            contentHeading="Milestones"
            tooltipNo="2 of 2"
            content="Stay updated on each milestone and check the status of your loan, anytime."
            cancelClick={() => { setTooltipIndex?.(tooltipIndexValues.completed) }}
            buttonOnClick={() => { setTooltipIndex?.(tooltipIndexValues.completed) }}
            buttonName="Explore your dashboard"
          />
        </div>}
      </div>
      {showTooltip && (
        <div className="transparent-overlay">
        </div>
      )}
    </>
  );
};

interface IconDoubleCircleProps {
  icon: IconDefinition;
  iconStatus?: boolean;
  label?: {
    title: string;
    description: string;
  };
}

const IconDoubleCircle = ({
  iconStatus = false,
  icon,
  label,
}: IconDoubleCircleProps) => {
  return (
    <div className="icon_wrapper">
      <ToolTip
        contentHeading={label?.title}
        content={label?.description}
        position="up"
      />
      <div className={iconStatus ? "outer-circle active" : "outer-circle"}>
        <div
          className={iconStatus ? "inner-circle active" : "inner-circle"}
          data-testid="icon-double-circle"
        >
          <FontAwesomeIcon className="icon" icon={icon} />
        </div>
      </div>
      <label className="icon_label">{label?.title}</label>
    </div>
  );
};

export default LoanProgessBar;

import { useState } from "react";
import { getFSQuestionCustomEvent } from "utilities/full-story";
import { Button } from "components/ui/button/Button";
import OtherButtonAndText from "./OtherButtonAndText";
import "./single-choice.scss";
import {
  NextStepActionResponse,
  useSetApplicationStepAnswerMutation,
} from "pages/application/applicationService";
import { Select } from "components/ui/select/Select";
import { getStepData } from "components/question/questionSlice";
import { useSelector } from "react-redux";
import { getCurrentUserApplicationId } from "features/authSlice";
import DisclaimerText from "components/ui/disclaimer-text/DisclaimerText";
import { SkipButton } from "pages/application/question/Question";

interface Accumulator {
  normalStepActions: NextStepActionResponse[];
  otherButton: NextStepActionResponse | null;
  otherText: NextStepActionResponse | null;
}

export const splitNormalAndOtherStepActions = (
  stepActions: NextStepActionResponse[]
) => {
  return stepActions.reduce(
    (acc: Accumulator, stepAction: NextStepActionResponse) => {
      if (stepAction.actionType === "otherButton") {
        acc.otherButton = stepAction;
      } else if (stepAction.actionType === "otherText") {
        acc.otherText = stepAction;
      } else {
        acc.normalStepActions.push(stepAction);
      }
      return acc;
    },
    { normalStepActions: [], otherButton: null, otherText: null }
  );
};

const optionsAsButtonsMaximun = 7;
export const shouldShowOptionsAsDropdown = (
  stepActions: NextStepActionResponse[]
) => stepActions.length >= optionsAsButtonsMaximun;

const SingleChoice = () => {
  const stepData = useSelector(getStepData);
  const disclaimer = stepData.disclaimer;
  const skipAction = stepData.skipAction;

  const { normalStepActions, otherButton, otherText } =
    splitNormalAndOtherStepActions(
      [...stepData.nextStepActions].sort(
        (a, b) => a.presentationOrder - b.presentationOrder
      )
    );
  const showOptionsAsDropdown = shouldShowOptionsAsDropdown(normalStepActions);

  return (
      <div
        className={`single-choice ${
          showOptionsAsDropdown ? "single-choice-dropdown" : ""
        }`}
      >
        {showOptionsAsDropdown ? (
          <StepActionsDropdown stepActions={normalStepActions} />
        ) : (
          <StepActionsButtons
            stepActions={normalStepActions}
            otherButton={otherButton}
            otherText={otherText}
          />
        )}
        {skipAction && <SkipButton skipAction={skipAction} />}
        {disclaimer && (
          <DisclaimerText Header={disclaimer.header} Text={disclaimer.text} />
        )}
      </div>
  );
};

const StepActionsDropdown = ({
  stepActions,
}: {
  stepActions: NextStepActionResponse[];
}) => {
  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    stepActions.find(
      ({ hasPreviouslySelected }) => hasPreviouslySelected === true
    )?.label
  );

  const [errorMessage, setErrorMessage] =
    useState<string | undefined>(undefined);

  const [updateApplication] = useSetApplicationStepAnswerMutation({
    fixedCacheKey: "updateApplication",
  });
  const applicationId = useSelector(getCurrentUserApplicationId);
  const handleSubmit = async () => {
    if (!selectedOption) {
      setErrorMessage("Please select an option");
      return;
    }

    const selectedStepAction = stepActions.find(
      ({ label }) => label === selectedOption
    );
    if (!selectedStepAction) return;

    await updateApplication({
      id: applicationId,
      stepActionId: selectedStepAction.stepActionId,
    });
    getFSQuestionCustomEvent(applicationId, selectedStepAction.label);
  };

  return (
    <div className="select">
      <Select
        options={stepActions.map(({ label }) => label)}
        onChange={(option) => {
          setSelectedOption(option);
          setErrorMessage(undefined);
        }}
        initialValue={selectedOption}
        errorMessage={errorMessage}
      />
      <Button title="Continue" onClick={() => handleSubmit()} />
    </div>
  );
};

const StepActionsButtons = ({
  stepActions,
  otherButton,
  otherText,
}: {
  stepActions: NextStepActionResponse[];
  otherButton: NextStepActionResponse | null;
  otherText: NextStepActionResponse | null;
}) => {
  const [showOtherTextInput, setShowOtherTextInput] = useState(false);
  const [updateApplication] = useSetApplicationStepAnswerMutation({
    fixedCacheKey: "updateApplication",
  });
  const applicationId = useSelector(getCurrentUserApplicationId);
  return (
    <>
      {stepActions.map(({ stepActionId, label, hasPreviouslySelected }) => {
        const handleSubmit = async () => {
          await updateApplication({
            id: applicationId,
            stepActionId,
          });
        };

        return (
          <Button
            key={stepActionId}
            title={label}
            onClick={() => void handleSubmit()}
            className={`single-choice-button ${
              hasPreviouslySelected ? "active" : ""
            }`}
          />
        );
      })}
      {otherButton && otherText && (
        <OtherButtonAndText
          {...otherButton}
          showOtherTextInput={showOtherTextInput}
          setShowOtherTextInput={setShowOtherTextInput}
          otherText={otherText}
        />
      )}
    </>
  );
};

export default SingleChoice;

import { NextStepActionResponse } from "pages/application/applicationService";
import { ActionButton, RemoveModal } from "..";
import "./finances.scss";
import { useGetBlocksQuery } from "../queries";
import { useGetAssetsQuery, useGetIncomeQuery } from "./queries";
import React, { useState } from "react";
import { formatDollarAmount } from "utilities/string";

const Finances = () => {
  const { hasSpouseCoApplicant, hasAssets, next } = useGetBlocksQuery({
    spouseCoApplicantBlockName: "financesSpouseCoApplicantIncome",
  });

  return (
    <div className="finances-section">
      <ApplicantIncome />
      {hasSpouseCoApplicant && <SpouseCoApplicantIncome />}
      {hasAssets && <Assets />}
      <ActionButton action="next" {...next} />
    </div>
  );
};

const ApplicantIncome = () => <Income blockType="financesApplicantIncome" />;
const SpouseCoApplicantIncome = () => (
  <Income blockType="financesSpouseCoApplicantIncome" />
);
export const Income = ({
  blockType,
}: {
  blockType: "financesApplicantIncome" | "financesSpouseCoApplicantIncome";
}) => {
  const { incomeLabel, name, incomes, addIncomeAction } =
    useGetIncomeQuery(blockType);
  const [showModal, setShowModal] = useState<boolean[]>([]);
  const toggleModal = (index: number) => {
    setShowModal((prevState) => {
      const updatedState = [...prevState];
      updatedState[index] = !prevState[index];
      return updatedState;
    });
  };
  return (
    <div className="income section-border">
      {blockType === "financesApplicantIncome" && (
        <h3 className="section-subheader">{incomeLabel}</h3>
      )}
      <p className="name">{name}</p>
      <div className="income-details-wrapper">
        {incomes.length > 0 ? (
          <>
            {incomes.map((income, index) => (
              <React.Fragment key={index}>
                <IncomeDetails
                  {...income}
                  toggleModal={() => toggleModal(index)}
                />
                {showModal[index] && (
                  <RemoveModal
                    stepActionId={income.removeAction.stepActionId}
                    toggleModal={() => toggleModal(index)}
                    subContents={[
                      income.source,
                      `${formatDollarAmount(income.amount) ?? ""}`,
                    ]}
                    label="Are you sure you want to remove income?"
                  />
                )}
              </React.Fragment>
            ))}
          </>
        ) : (
          <div className="no-income">No Income Provided</div>
        )}
      </div>

      {addIncomeAction && (
        <div className="add-income-container">
          <ActionButton action="add" {...addIncomeAction} />
        </div>
      )}
    </div>
  );
};

export const Assets = () => {
  const {
    assetsLabel,
    electronicallyVerifiedAssets,
    manuallyListedAssets,
    addAssetAction,
  } = useGetAssetsQuery();
  const [showModal, setShowModal] = useState<boolean[]>([]);
  const toggleModal = (index: number) => {
    setShowModal((prevState) => {
      const updatedState = [...prevState];
      updatedState[index] = !prevState[index];
      return updatedState;
    });
  };
  return (
    <div className="assets section-border">
      <h3 className="section-subheader">{assetsLabel}</h3>
      {electronicallyVerifiedAssets.length || manuallyListedAssets.length ? (
        <>
          <div className="electronically-verified-assets-details-wrapper">
            {electronicallyVerifiedAssets.map((asset, index) => (
              <AssetDetails
                key={asset.source}
                {...asset}
                category={index === 0 ? asset.category : ""}
                showActions={false}
              />
            ))}
          </div>
          <div className="manually-listed-assets-details-wrapper">
            {manuallyListedAssets.map((asset, index) => (
              <React.Fragment key={index}>
                <AssetDetails
                  {...asset}
                  category={index === 0 ? asset.category : ""}
                  showActions
                  toggleModal={() => toggleModal(index)}
                />
                {showModal[index] && (
                  <RemoveModal
                    stepActionId={asset.removeAction.stepActionId}
                    toggleModal={() => toggleModal(index)}
                    subContents={[
                      asset.source,
                      `${formatDollarAmount(asset.amount) ?? ""}`,
                    ]}
                    label="Are you sure you want to remove asset?"
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        </>
      ) : (
        <div className="no-assets">No Assets Provided</div>
      )}

      {addAssetAction && (
        <div className="add-assets-container">
          <ActionButton action="add" {...addAssetAction} />
        </div>
      )}
    </div>
  );
};

export const IncomeDetails = ({
  source,
  amount,
  editAction,
  removeAction,
  toggleModal,
}: {
  source: string;
  amount: string;
  editAction: NextStepActionResponse;
  removeAction: NextStepActionResponse;
  toggleModal?: () => void;
}) => {
  return (
    <div className="income-details">
      <div className="income-details__source table-item">{source}</div>
      <div className="income-details__amount table-item">
        {formatDollarAmount(amount)}
      </div>
      <div className="income-details__actions">
        <ActionButton action="edit" {...editAction} />
        <ActionButton
          action="remove"
          {...removeAction}
          toggleModal={toggleModal}
        />
      </div>
    </div>
  );
};

export const AssetDetails = ({
  category,
  source,
  amount,
  editAction,
  removeAction,
  showActions,
  toggleModal,
}: {
  category: string;
  source: string;
  amount: string;
  editAction?: {
    label: string;
    stepActionId: string;
  };
  removeAction?: {
    label: string;
    stepActionId: string;
  };
  showActions: boolean;
  toggleModal?: () => void;
}) => {
  return (
    <div className="assets-details">
      {category && (
        <p className="assets-details__category table-item">{category}</p>
      )}
      <p className="assets-details__source table-item">{source}</p>
      <p className="assets-details__amount table-item">
        {formatDollarAmount(amount)}
      </p>
      <div className="assets-details__actions">
        {showActions && <ActionButton action="edit" {...editAction!} />}
        {showActions && (
          <ActionButton
            action="remove"
            {...removeAction!}
            toggleModal={toggleModal}
          />
        )}
      </div>
    </div>
  );
};

export default Finances;

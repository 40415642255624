import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSetApplicationPreviousStepMutation } from "pages/application/applicationService";
import { getStepData } from "components/question/questionSlice";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentUserApplicationId, setLogout } from "features/authSlice";
import { useNavigate } from "react-router-dom";
import SectionBar from "./section-bar/SectionBar";
import useDesktopChecker from "hooks/useDesktopChecker";

const PreviousQuestion = ({
  hasPreviousStep,
}: {
  hasPreviousStep: boolean;
}) => {
  const [setPreviousQuestion, { isError, error }] =
    useSetApplicationPreviousStepMutation({
      fixedCacheKey: "updateApplication",
    });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const applicationId = useSelector(getCurrentUserApplicationId);
  if (
    isError &&
    typeof error === "object" &&
    "status" in error &&
    error.status === 401
  ) {
    dispatch(setLogout());
    navigate("/", { replace: true, state: { previousPath: "/application" } });
  }
  return (
    <button
      className={`previous-question-button ${!hasPreviousStep ? "hidden" : ""}`}
      onClick={async () => {
        await setPreviousQuestion({
          id: applicationId,
        });
      }}
    >
      <FontAwesomeIcon icon={regular("arrow-left")} className="arrow-left" />{" "}
      Previous
    </button>
  );
};

const PreviousQuestionButton = () => {
  const { hasPreviousStep } = useSelector(getStepData);

  const { section: activeSection, sections } = useSelector(getStepData);
  const isDesktop = useDesktopChecker();
  return (
    <header className="application-header">
      <div>
        {!isDesktop && <SectionBar activeSection={activeSection} sections={sections} />}
      </div>
      <div className="clickablesContainer">
        <section className="application-header__clickables">
          <PreviousQuestion hasPreviousStep={hasPreviousStep} />
        </section>
      </div>
    </header>
  );
};

export default PreviousQuestionButton;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_BFF_WEB_URL } from "utilities/Constant";


export const versionApi = createApi({
  reducerPath: "versionApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_BFF_WEB_URL }),
  endpoints: (build) => ({
    getAllVersions: build.query<GetAllVersions[], void>({
      query: () => `/bff/applicationVersions`,
    })
  }),
});

export const { useGetAllVersionsQuery } = versionApi;

export interface GetAllVersions {
  applicationName: string;
  version: string;
  informationalVersion: string;
}
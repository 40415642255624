import React from "react";
import "./card.scss";
import { getCardWidth } from "utilities/carddata";

export interface Cardprops {
  header?: string;
  body?: React.ReactNode;
  footer?: React.ReactNode;
  width?: "small" | "medium" | "large" | "full";
}

export default function Card({
  header,
  body,
  footer,
  width = "full",
}: Cardprops) {
  return (
    <div
      className="card"
      data-testid="card"
      style={{
        width: getCardWidth(width),
      }}
    >
      <div className="card__content">
        <div className="card__header">
          <p>{header}</p>          
        </div>

        <div className="card__body">{body}</div>
        {footer && <div className="card__footer">{footer}</div>}
      </div>
    </div>
  );
}

import { Button } from "components/ui/button/Button";
import { useEffect, useState } from "react";
import {
  useCreateAccountChekOrderMutation,
  useGetSsoUrlQuery,
  CreateAccountChekOrder,
} from "pages/application/assetVerificationService";
import { LoadingOverlay } from "components/ui/loading-spinners";
import Modal from "components/modal/Modal";

const testData: CreateAccountChekOrder = {
  email: "test@example.com",
  last4SSN: "1234",
  referenceNumber: "3456",
  firstName: "mani",
  lastName: "g",
};

export default function AccountChek() {
  const [CreateAccountChekOrder, { isLoading, isSuccess, isError, data }] =
    useCreateAccountChekOrderMutation();
  let orderId = "";
  const [show, setShow] = useState<boolean>(false);
  const toggleModal = () => setShow(!show);
  if (isSuccess && data) orderId = data.id;
  const onCreateOrder = async () => {
    await CreateAccountChekOrder(testData);
  };
  if (isError) return null;
  if (isLoading) return <LoadingOverlay />;
  if (isSuccess && orderId && show)
    return (
      <SsoIframe orderId={orderId} showModal={show} toggleModal={toggleModal} />
    );
  return (
    <>
      <h1>H14 Question Placeholder</h1>
      <Button title="Enter Manually" variant="secondary" />
      <Button title="Use AccountChek" onClick={toggleModal} />
      {show && (
        <Modal
          customBody={
            <div>
              <h1>AccountChek</h1>
              <h3>Terms of service Placeholder</h3>
              <Button
                title="Cancel"
                onClick={toggleModal}
                variant="secondary"
              />
              <Button title="Next" onClick={() => void onCreateOrder()} />
            </div>
          }
        />
      )}
    </>
  );
}

export const SsoIframe = ({
  orderId,
  showModal,
  toggleModal,
}: {
  orderId: string;
  showModal: boolean;
  toggleModal: () => void;
}) => {
  const { data, isSuccess, isLoading } = useGetSsoUrlQuery(orderId);
  useEffect(() => {
    const handleCloseFrame = (event: MessageEvent) => {
      if (event.data == "closeFrameTimeout") {
        toggleModal();
        window.removeEventListener("message", handleCloseFrame);
      } else if (event.data == "closeFrame") {
        toggleModal();
        //Call application backend to notify the completion of accountChek
      }
    };
    window.addEventListener("message", handleCloseFrame);
    return () => window.removeEventListener("message", handleCloseFrame);
  }, []);
  return (
    <>
      {showModal && (
        <Modal
          customBody={
            <div>
              {isLoading && <LoadingOverlay />}
              {isSuccess && (
                <iframe src={data.url} height="650px" width="60%"></iframe>
              )}
            </div>
          }
        />
      )}
    </>
  );
};

import { useState } from "react";
import "./radioButton.scss";
import RadioButton, { RadioOption } from "./RadioButton";
import InPlaceError from "components/in-place-error/InPlaceError";

interface RadioButtonGroupProps {
  options: RadioOption[];
  onChange?: (selectedValue: string) => void;
  initialValue?: string;
  errorMessage?: string;
}

const RadioButtonGroup = ({
  options,
  onChange,
  initialValue,
  errorMessage,
}: RadioButtonGroupProps) => {
  const [selectedValue, setSelectedValue] = useState<string>(
    initialValue ?? ""
  );

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div className="radio-button-group-container">
      <div className="radioButtonGroup">
        {options.map((option) => (
          <RadioButton
            key={option.value}
            option={option}
            selectedValue={selectedValue}
            handleOptionChange={handleOptionChange}
          />
        ))}
      </div>
      {errorMessage && (
        <InPlaceError errorPhrase={errorMessage} hasErrorIcon={false} />
      )}
    </div>
  );
};
export default RadioButtonGroup;

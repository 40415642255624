import { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { LeadProvider, useGetDecryptUserQuery } from "./registrationService";
import "./registration.scss";
import { setInquiryId, setLeadProvider } from "features/authSlice";
import { setFSUserProperties } from "utilities/full-story";
import { useDispatch } from "react-redux";
import DefaultRegistration from "./DefaultRegistration";
import { LoadingOverlay } from "components/ui/loading-spinners";
import DigitalLeadRegistration from "./digital-lead/DigitalLeadRegistration";

export default function Registration() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const encryptedUser = searchParams.get("t")!;
  const {
    data: decryptedUserDetails,
    isLoading,
    isFetching,
  } = useGetDecryptUserQuery(encryptedUser, {
    skip: !encryptedUser,
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (decryptedUserDetails) {
      const { ex, los, id, lpn } = decryptedUserDetails;
      if (!ex) {
        if (los) {
          dispatch(setInquiryId(id));
        }
        if (lpn) {
          dispatch(setLeadProvider(lpn));
        }
      } else {
        navigate("/", { replace: true });
      }
    }
  }, [decryptedUserDetails]);

  let showModal = false;
  if (!isLoading && !isFetching && !decryptedUserDetails) {
    showModal = true;
  } else {
    setFSUserProperties(
      decryptedUserDetails?.em ?? "",
      decryptedUserDetails?.ln.toLowerCase() ?? ""
    );
  }
  const isDigitalLead =
    decryptedUserDetails?.lpn &&
    decryptedUserDetails?.lpn.toLowerCase() ==
      LeadProvider.DigitalLeads.toLowerCase();
  return (
    <>
      {(isLoading || isFetching) && (
        <LoadingOverlay text="Loading user details..." />
      )}
      {isDigitalLead ? (
        <DigitalLeadRegistration
          decryptedUserDetails={decryptedUserDetails}
          showModal={showModal}
        />
      ) : (
        <DefaultRegistration
          decryptedUserDetails={decryptedUserDetails}
          showModal={showModal}
        />
      )}
    </>
  );
}

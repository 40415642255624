import { useSelector } from "react-redux";
import { getCurrentAccessToken } from "features/authSlice";
import { Outlet, Navigate, useLocation } from "react-router";

export default function RequireAuth() {
  const accessToken = useSelector(getCurrentAccessToken);
  const location = useLocation();
  return accessToken ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ previousPath: location.pathname }} replace />
  );
}
